import { useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { KTIcon } from '../../../../../../../_metronic/helpers'
import { deleteUserManagement } from '../../core/_requests'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

const UserActionsCell = ({ id }) => {
  const intl = useIntl();
  const userPermissions = useSelector(state => state.persistedReducer.user.user?.permissions)
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])



  const deleteItem = useMutation(() => deleteUserManagement(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["users-management"])
    },
  })

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        {intl.formatMessage({ id: 'ACTIONS' })}
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        {userPermissions.length == 0 ? <div className='menu-item px-3'>
          <Link to={`/employees/employees-management/employee/${id}`} className='menu-link px-3' >
            {intl.formatMessage({ id: "EDIT" })}
          </Link>
        </div> : [...userPermissions].find(userPermission => userPermission?.name == 'employees update') && <div className='menu-item px-3'>
          <Link to={`/employees/employees-management/employee/${id}`} className='menu-link px-3' >
            {intl.formatMessage({ id: "EDIT" })}
          </Link>
        </div>}
        {/* end::Menu item */}
        {userPermissions.length == 0 ? <div className='menu-item px-3'>
          <Link to={`/employees/employees-management/employee/view/${id}`} className='menu-link px-3' >
            {intl.formatMessage({ id: "VIEW" })}
          </Link>
        </div> : [...userPermissions].find(userPermission => userPermission?.name == 'employees index') && <div className='menu-item px-3'>
          <Link to={`/employees/employees-management/employee/view/${id}`} className='menu-link px-3' >
            {intl.formatMessage({ id: "VIEW" })}
          </Link>
        </div>}
        {/* begin::Menu item */}
        {userPermissions.length == 0 ? <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            onClick={async () => await deleteItem.mutateAsync()}
          >
            {intl.formatMessage({ id: "DELETE" })}
          </a>
        </div> : [...userPermissions].find(userPermission => userPermission?.name == 'employees destroy') && <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            onClick={async () => await deleteItem.mutateAsync()}
          >
            {intl.formatMessage({ id: "DELETE" })}
          </a>
        </div>}
      </div>
    </>
  )
}

export { UserActionsCell }
