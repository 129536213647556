import { useEffect } from 'react'
import { Link, Outlet } from 'react-router-dom'
import Logo from "../../../_metronic/assets/images/logo.png";
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { useIntl } from 'react-intl';

const AuthLayout = () => {
  const intl = useIntl();

  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div style={{ backgroundImage: `url(${toAbsoluteUrl('/media/auth/bg4.jpg')})` }} className="d-flex flex-column flex-column-fluid flex-lg-row">
      <div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
        <div className="d-flex flex-center flex-lg-start flex-column">
          <Link to={`/`} style={{ width: '226px' }} className="mb-7 d-flex">
            <img
              className='w-100 h-100'
              alt="Logo"
              src={Logo}
            />
          </Link>
          <h2 className="text-white fw-normal m-0">
            {intl.formatMessage({ id: "WE.AUTOMATE" })}
          </h2>
        </div>
      </div>
      <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-10">
        <div className="bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20">
          <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export { AuthLayout }
