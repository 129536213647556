import axios, { AxiosResponse } from "axios";
import { ID, Response } from "../../../../../../_metronic/helpers";
import {
  UserPreviousJobsInformation,
  UserPreviousJobsInformationQueryResponse,
} from "./_models";

const API_URL = process.env.REACT_APP_BASE_URL;

const USERS_PREVIOUS_JOBS_INFORMATION_URL = `${API_URL}/api/employee-prev-positions`;
const GET_PREVIOUS_JOBS_INFORMATION_URL = `${API_URL}/api/employee-prev-positions`;

const getUsersPreviousJobsInformations = (
  query: string
): Promise<UserPreviousJobsInformationQueryResponse> => {
  return axios
    .get(`${GET_PREVIOUS_JOBS_INFORMATION_URL}?${query}`)
    .then((d: AxiosResponse<UserPreviousJobsInformationQueryResponse>) => {
      return d;
    })
    .catch(err => err)
};

const getUserPreviousJobsInformationById = (
  id: ID
): Promise<UserPreviousJobsInformation | undefined> => {
  return axios
    .get(`${USERS_PREVIOUS_JOBS_INFORMATION_URL}/${id}`)
    .then((response) => {
      return response.data;
    });
};

const createUserPreviousJobsInformation = (
  user: UserPreviousJobsInformation
): Promise<UserPreviousJobsInformation | undefined> => {
  return axios
    .post(USERS_PREVIOUS_JOBS_INFORMATION_URL, user)
    .then(
      (response: AxiosResponse<Response<UserPreviousJobsInformation>>) =>
        response.data
    )
    .catch(err => {
      return err?.response?.data?.message
    })
};

const updateUserPreviousJobsInformation = (
  user: UserPreviousJobsInformation
): Promise<UserPreviousJobsInformation | undefined> => {
  return axios
    .put(`${USERS_PREVIOUS_JOBS_INFORMATION_URL}/${user.id}`, user)
    .then(
      (response: AxiosResponse<Response<UserPreviousJobsInformation>>) => {
         return {}
      }
        
    )
    .catch(err => {
      return err?.response?.data?.message
    })
};

const deleteUserPreviousJobsInformation = (userId: ID): Promise<void> => {
  return axios
    .delete(`${USERS_PREVIOUS_JOBS_INFORMATION_URL}/${userId}`)
    .then(() => { });
};

const deleteUsersPreviousJobsInformations = (
  userIds: Array<ID>
): Promise<void> => {
  const requests = userIds.map((id) =>
    axios.delete(`${USERS_PREVIOUS_JOBS_INFORMATION_URL}/${id}`)
  );
  return axios.all(requests).then(() => { });
};

export {
  getUsersPreviousJobsInformations,
  getUserPreviousJobsInformationById,
  createUserPreviousJobsInformation,
  updateUserPreviousJobsInformation,
  deleteUserPreviousJobsInformation,
  deleteUsersPreviousJobsInformations,
};
