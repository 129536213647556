import clsx from 'clsx'
import { useIntl } from 'react-intl';

const UserCustomHeader = ({ className, title, tableProps }) => {

  const intl = useIntl();

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className
      )}
      style={{ cursor: 'pointer' }}
    >
      {title == "Actions" ? intl.formatMessage({ id: 'ACTIONS' }) :
        title == "ASA" ? intl.formatMessage({ id: "NSF" }) :
          title == "Vəsiqənin seriya və nömrəsi" ? intl.formatMessage({ id: 'VOUCHER.NUMBER.SERIES' }) :
            title == "Kateqoriya" ? intl.formatMessage({ id: "CATEGORY" }) :
              title == "Vəsiqəni verən orqan" ? intl.formatMessage({ id: 'ISSUING.AUTHORITY' }) :
                title == "Vəsiqənin verildiyi tarix" ? intl.formatMessage({ id: 'DATE.OF.ISSUEE' }) :
                  title == "Etibarlılıq müddəti" ? intl.formatMessage({ id: "PERIOD.VALIDITY" }) :
                    title}
    </th>
  )
}

export { UserCustomHeader }
