import axios, { AxiosResponse } from "axios";
import { ID, Response } from "../../../../../../_metronic/helpers";
import { UserEducationInfo, UserEducationInfoQueryResponse } from "./_models";

const API_URL = process.env.REACT_APP_BASE_URL
const USERS_EDUCATION_INFO_URL = `${API_URL}/api/education-infos`;
const GET_USERS_EDUCATION_INFO_URL = `${API_URL}/api/education-infos`;

const getUsersEducationInfos = (
  query: string
): Promise<UserEducationInfoQueryResponse> => {
  return axios
    .get(`${GET_USERS_EDUCATION_INFO_URL}?${query}`)
    .then((d: AxiosResponse<UserEducationInfoQueryResponse>) => {
      return d;
    })
    .catch(err => err)
};

const getUserEducationInfoById = (
  id: ID
): Promise<UserEducationInfo | undefined> => {
  return axios.get(`${USERS_EDUCATION_INFO_URL}/${id}`).then((response) => {
    return response.data;
  });
};

const createUserEducationInfo = (
  user: UserEducationInfo
): Promise<UserEducationInfo | undefined> => {
  return axios
    .post(USERS_EDUCATION_INFO_URL, user)
    .then(
      (response: AxiosResponse<Response<UserEducationInfo>>) => response.data
    )
    .catch(err => err?.response?.data?.message)
};

const updateUserEducationInfo = (
  user: UserEducationInfo
): Promise<UserEducationInfo | undefined> => {
  return axios
    .put(`${USERS_EDUCATION_INFO_URL}/${user.id}`, user)
    .then(
      (response: AxiosResponse<Response<UserEducationInfo>>) => {}
    )
    .catch(err => err?.response?.data?.message)
};

const deleteUserEducationInfo = (userId: ID): Promise<void> => {
  return axios.delete(`${USERS_EDUCATION_INFO_URL}/${userId}`).then(() => {});
};

const deleteUsersEducationInfos = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) =>
    axios.delete(`${USERS_EDUCATION_INFO_URL}/${id}`)
  );
  return axios.all(requests).then(() => {});
};

export {
  getUsersEducationInfos,
  getUserEducationInfoById,
  createUserEducationInfo,
  updateUserEducationInfo,
  deleteUserEducationInfo,
  deleteUsersEducationInfos,
};
