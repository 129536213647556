import { ID, Response } from "../../../../../../_metronic/helpers";
export type Employeement = {
  id?: ID;
  employee_id?: string | null;
  indefinite?: string | null;
  probation?: string | number;
  trial_expiration_date?: string | null;
  employee_start_date?: string | null;
  date_contract?: string | null;
  regulation_property_relations?: string;
  termination_cases?: string,
  other_condition_wages?: string,
  workplace_status?: string | null,
  working_conditions?: string | null,
  job_description?: string,
  quota?: string,
  reasons_temporary_clause?:string | null,
  date_conclusion_contract?:string | null,
  probation_dates?:string | null,
};

export type EmployeementQueryResponse = Response<Array<Employeement>>;

export const initialEmployeement: Employeement = {
  employee_id: null,
  indefinite: null,
  probation: "",
  trial_expiration_date: null,
  employee_start_date: null,
  date_contract: null,
  regulation_property_relations: "",
  termination_cases: "",
  other_condition_wages: "",
  workplace_status: null,
  working_conditions: null,
  job_description: "",
  probation_dates: null,
  reasons_temporary_clause: null,
  date_conclusion_contract: null,
  quota: ''
};
