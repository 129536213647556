import { useState, useEffect, forwardRef } from "react";
import { KTCard } from "../../../../../_metronic/helpers";
import { useFormik } from "formik";
import * as Yup from "yup";
import { initialInOut } from './core/_models';
import { createInOut, updateInOut } from "./core/_requests";
import clsx from "clsx";
import { isNotEmpty } from '../../../../../_metronic/helpers';
import { InOutLoading } from "./components/loading/InOutLoading";
import { useIntl } from 'react-intl';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { getInOutById } from './core/_requests';
import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker';
import axios from 'axios';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";
import moment from "moment";

const InOutCreateAndUpdatePage = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const MaskedInput = forwardRef(({ value, onChange, onClick }, ref) => (
        <div className="ui input" onClick={onClick}>
            <InputMask ref={ref} mask="99/99/9999" placeholder="dd/mm/yyyy" value={value ? value : 'dd/mm/yyyy'} onChange={onChange} />
        </div>
    ));

    const { id } = useParams();
    const user = useSelector(state => state.persistedReducer.user.user);

    const editPermissionSchema = Yup.object().shape({
        employee_id: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        action_type_id: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        action_date: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        action_time: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

    });



    const [permissionForEdit] = useState({
        employee_id: initialInOut.employee_id,
        action_type_id: initialInOut.action_type_id,
        action_date: initialInOut.action_date,
        action_time: initialInOut.action_time,
    });

    const [employees, setEmployees] = useState([]);
    const [types, setTypes] = useState([]);


    const getAllEmployees = async () => {
        try {
            const request = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/employees`);

            if (request.status === 200) {
                setEmployees(request.data)
            } else {
                throw new Error(request.data)
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')

            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }


    const getAllTypes = async () => {
        try {
            const request = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/actionTypes`);

            if (request.status === 200) {
                setTypes(request.data)
            } else {
                throw new Error(request.data)
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')

            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }
    const formik = useFormik({
        initialValues: permissionForEdit,
        validationSchema: editPermissionSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            try {
                if (isNotEmpty(values.id)) {
                    const result = await updateInOut(
                        { ...values, update_user_id: user?.id, status: 1, action_date: moment(values.action_date).format("YYYY-MM-DD") },
                        "employeeInOuts"
                    );
                    if (typeof result === "string") {
                        throw new Error(result)
                    } else {
                        toast.success(`${intl.formatMessage({ id: 'SUCCESS.MESSAGE.UPDATE' })}`);
                        navigate(-1);
                    }
                } else {
                    const result = await createInOut(
                        { ...values, insert_user_id: user?.id, update_user_id: user?.id, status: 1, action_date: moment(values.action_date).format("YYYY-MM-DD") },
                        "employeeInOuts"
                    );

                    if (typeof result === "string") {
                        throw new Error(result)
                    } else {
                        toast.success(`${intl.formatMessage({ id: 'SUCCESS.MESSAGE.ADD' })}`);
                        navigate(-1);
                    }
                }
            } catch (error) {
                toast.error(error?.message, {
                    autoClose: 10000
                })
            }
            finally {
                setSubmitting(false)
            }
        },
    });


    const getPermission = async () => {
        const result = await getInOutById("employeeInOuts", id);
        formik.setFieldValue('id', result?.id);
        formik.setFieldValue('action_type_id', result.action_type_id?.id);
        formik.setFieldValue('action_date', new Date(result.action_date));
        formik.setFieldValue('action_time', result.action_time);
        formik.setFieldValue('employee_id', result.employee_id?.id);
    }


    useEffect(() => {
        if (id) {
            getPermission();
        }
        getAllEmployees();
        getAllTypes();
    }, []);


    return (
        <KTCard>
            <>
                <div className="mb-5 mb-xl-10">
                    <div
                        className="card-header border-0 cursor-pointer"
                        role="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_account_profile_details"
                        aria-expanded="true"
                        aria-controls="kt_account_profile_details"
                    >
                        <div className="card-title m-0">
                            <h3 className="fw-bold m-0">{(id && pathname.includes('view')) ? intl.formatMessage({ id: "VIEW_CHECKIN_CHECKOUT_TIMES" }) :
                                (id && !pathname.includes('view')) ? intl.formatMessage({ id: "UPDATE_CHECKIN_CHECKOUT_TIMES" }) :
                                    intl.formatMessage({ id: "CREATE_CHECKIN_CHECKOUT_TIMES" })}</h3>
                        </div>
                    </div>
                    <div id="kt_account_settings_profile_details" className="collapse show">
                        <form
                            id="kt_account_profile_details_form"
                            className="form fv-plugins-bootstrap5 fv-plugins-framework"
                            onSubmit={formik.handleSubmit}
                            noValidate
                        >
                            <div className="card-body border-top p-9">
                                <div className="d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch">
                                    <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                        <label className={`col-form-label fw-semibold fs-6 ${pathname.includes('view') == false && 'required'}`}>
                                            {intl.formatMessage({ id: "EMPLOYEE" })}
                                        </label>
                                        <div className="fv-row fv-plugins-icon-container">
                                            {pathname.includes('view') ? <p>{employees?.find(em => em?.id == formik.values.employee_id)?.firstname} {employees?.find(em => em?.id == formik.values.employee_id)?.firstname}</p> : <select
                                                {...formik.getFieldProps("employee_id")}
                                                className={clsx(
                                                    "form-select form-select-solid mb-3 mb-lg-0",
                                                    {
                                                        "is-invalid":
                                                            formik.touched.employee_id &&
                                                            formik.errors.employee_id,
                                                    },
                                                    {
                                                        "is-valid":
                                                            formik.touched.employee_id &&
                                                            !formik.errors.employee_id,
                                                    }
                                                )}
                                                data-kt-select2="true"
                                                data-placeholder="Select "
                                                data-allow-clear="true"
                                                disabled={formik.isSubmitting}
                                            >
                                                <option value="">{intl.formatMessage({ id: "SELECT" })}</option>
                                                {employees.length > 0 && employees.map(emp => (
                                                    <option value={emp?.id} key={emp?.id}>{emp?.firstname} {emp?.lastname}</option>
                                                ))}
                                            </select>}
                                            {formik.touched.employee_id && formik.errors.employee_id && (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        <span role="alert">{formik.errors.employee_id}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                        <label className={`col-form-label fw-semibold fs-6 ${pathname.includes('view') == false && 'required'}`}>
                                            {intl.formatMessage({ id: 'TYPE' })}
                                        </label>
                                        <div className="fv-row fv-plugins-icon-container">
                                            {pathname.includes('view') ? <p>{types?.find(ty => ty?.id == formik.values.action_type_id)?.label}</p> : <select
                                                {...formik.getFieldProps("action_type_id")}
                                                className={clsx(
                                                    "form-select form-select-solid mb-3 mb-lg-0",
                                                    {
                                                        "is-invalid":
                                                            formik.touched.action_type_id &&
                                                            formik.errors.action_type_id,
                                                    },
                                                    {
                                                        "is-valid":
                                                            formik.touched.action_type_id &&
                                                            !formik.errors.action_type_id,
                                                    }
                                                )}
                                                data-kt-select2="true"
                                                data-placeholder="Select "
                                                data-allow-clear="true"
                                                disabled={formik.isSubmitting}
                                            >
                                                <option value="">{intl.formatMessage({ id: "SELECT" })}</option>
                                                {types.length > 0 && types.map(ty => (
                                                    <option value={ty?.id} key={ty?.id}>{ty?.label}</option>
                                                ))}
                                            </select>}
                                            {formik.touched.action_type_id && formik.errors.action_type_id && (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        <span role="alert">{formik.errors.action_type_id}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                        <label className={`col-form-label fw-semibold fs-6 ${pathname.includes('view') == false && 'required'}`}>
                                            {intl.formatMessage({ id: "DATE" })}
                                        </label>
                                        <div className="fv-row fv-plugins-icon-container">
                                            {pathname.includes('view') ? <p>{moment(formik.values.action_date).format('DD-MM-YYYY')}</p> : <DatePicker
                                                {...formik.getFieldProps("action_date")}
                                                className={clsx(
                                                    "form-select form-select-solid mb-3 mb-lg-0",
                                                    {
                                                        "is-invalid":
                                                            formik.touched.action_date && formik.errors.action_date,
                                                    },
                                                    {
                                                        "is-valid":
                                                            formik.touched.action_date && !formik.errors.action_date,
                                                    }
                                                )}
                                                id="action_date"
                                                name="action_date"
                                                wrapperClassName="datePicker"
                                                dateFormat="dd/MM/yyyy"
                                                placeholder="-- -- --"
                                                selected={formik.values.action_date && new Date(formik.values.action_date)}
                                                onChange={(opt) => formik.setFieldValue("action_date", opt)}
                                                showYearDropdown
                                                customInput={<MaskedInput />}
                                                yearDropdownItemNumber={50}
                                                calendarStartDay={1}
                                                scrollableYearDropdown
                                                shouldCloseOnSelect={true}
                                            />}
                                            {formik.touched.action_date && formik.errors.action_date && (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        <span role="alert">{formik.errors.action_date}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                        <label className={`col-form-label fw-semibold fs-6 ${pathname.includes('view') == false && 'required'}`}>
                                            {intl.formatMessage({ id: "TIME" })}
                                        </label>
                                        <div className="fv-row fv-plugins-icon-container">
                                            {pathname.includes('view') ? <p>{formik.values.action_time}</p> : <TimePicker
                                                {...formik.getFieldProps("action_time")}
                                                className={clsx(
                                                    "form-control form-control-solid mb-3 mb-lg-0",
                                                    {
                                                        "is-invalid":
                                                            formik.touched.action_time && formik.errors.action_time,
                                                    },
                                                    {
                                                        "is-valid":
                                                            formik.touched.action_time && !formik.errors.action_time,
                                                    }
                                                )}
                                                id="action_time"
                                                name="action_time"
                                                hourPlaceholder="hh"
                                                minutePlaceholder="mm"
                                                secondPlaceholder="ss"
                                                locale="az-AZ"
                                                maxDetail="second"
                                                format="H:m:s"
                                                value={formik.values.action_time}
                                                onChange={(opt) => formik.setFieldValue("action_time", opt)}
                                            />}
                                            {formik.touched.action_time && formik.errors.action_time && (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        <span role="alert">{formik.errors.action_time}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="card-footer d-flex justify-content-end py-6 px-9">
                                <Link to=""
                                    type="reset"
                                    onClick={() => navigate(-1)}
                                    disabled={formik.isSubmitting}
                                    className="btn btn-light btn-active-light-primary me-2"
                                >
                                    {intl.formatMessage({ id: 'DISCARD' })}
                                </Link>
                                {!pathname.includes('view') && <button
                                    type="submit"
                                    disabled={
                                        formik.isSubmitting ||
                                        !formik.isValid ||
                                        !formik.touched
                                    }
                                    className="btn btn-primary"
                                    id="kt_account_profile_details_submit"
                                >
                                    <span className="indicator-label">{formik.isSubmitting ? intl.formatMessage({ id: "PLEASE.WAIT" }) : intl.formatMessage({ id: "AUTH.GENERAL.SUBMIT_BUTTON" })}</span>
                                </button>}
                            </div>
                        </form>
                        {(formik.isSubmitting) && <InOutLoading />}
                    </div>
                </div>

            </>
        </KTCard>
    )
}

export default InOutCreateAndUpdatePage

