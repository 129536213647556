import axios, { AxiosResponse } from "axios";
import { ID, Response } from "../../../../../../_metronic/helpers";
import { Company, CompanyQueryResponse } from "./_models";

const baseUrl = process.env.REACT_APP_BASE_URL;
const COMPANY_URL = `${baseUrl}/api/companies`;
const GET_COMPANIES_URL = `${baseUrl}/api/companies`;

const getCompanies = (query: string): Promise<CompanyQueryResponse> => {
  return axios
    .get(`${GET_COMPANIES_URL}?${query}`)
    .then((d: AxiosResponse<CompanyQueryResponse>) => {
      return d;
    })
    .catch((err) => {
      return err;
    });
};

const getCompanyById = (id: ID): Promise<Company | undefined> => {
  return axios.get(`${COMPANY_URL}/${id}`).then((response) => {
    return response.data;
  });
};

const createCompany = (company: Company): Promise<Company | undefined> => {
  return axios
    .post(COMPANY_URL, company)
    .then((response: Response<Company>) => response.data)
    .catch((err) => {
      return err?.response?.data?.message;
    });
};

const updateCompany = (company: Company): Promise<Company | undefined> => {
  return axios
    .put(`${COMPANY_URL}/${company.id}`, company)
    .then((response: Response<Company>) => {})
    .catch((err) => {
      return err?.response?.data?.message;
    });
};

const deleteCompany = (companyId: ID): Promise<void> => {
  return axios.delete(`${COMPANY_URL}/${companyId}`).then(() => {});
};

const deleteSelectedCompanies = (companiesIds: Array<ID>): Promise<void> => {
  const requests = companiesIds.map((id) =>
    axios.delete(`${COMPANY_URL}/${id}`)
  );
  return axios.all(requests).then(() => {});
};

export {
  getCompanies,
  deleteCompany,
  deleteSelectedCompanies,
  getCompanyById,
  createCompany,
  updateCompany,
};
