import { useState, useEffect } from "react";
import clsx from "clsx";
import { toAbsoluteUrl } from "../../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { getAllLanguagess, setSelectedLanguage } from "../../../../redux-store/slices/selectedLanguage";
import { useIntl } from "react-intl";

const Languages = () => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const selectedLanguage = useSelector(state => state.persistedReducer.selectedLanguage.selectedLanguage);
  const languages = useSelector(state => state.persistedReducer.selectedLanguage.languages);
  const [shortLangNames, setShortLangNames] = useState([]);

  useEffect(() => {
    setShortLangNames([
      intl.formatMessage({ id: "LANGUAGE.AZ" }),
      intl.formatMessage({ id: "LANGUAGE.RU" }),
      intl.formatMessage({ id: "LANGUAGE.EN" }),
    ]);
  }, [selectedLanguage]);

  useEffect(() => {
    if (languages == null || languages.length == 0) {
      dispatch(getAllLanguagess());
    }
  }, []);

  return (
    <div
      className="menu-item px-5"
      data-kt-menu-trigger="hover"
      data-kt-menu-placement="left-start"
      data-kt-menu-flip="bottom">
      <a href="#" className="menu-link px-5">
        <span className="menu-title position-relative">
          {intl.formatMessage({ id: "LANGUAGE" })}
          <span className="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">
            {selectedLanguage ? selectedLanguage?.name.toUpperCase() : shortLangNames[0]}
            <img
              className="w-15px h-15px rounded-1 ms-2"
              src={
                selectedLanguage
                  ? selectedLanguage?.id == 1
                    ? toAbsoluteUrl("/media/flags/azerbaijan.svg")
                    : selectedLanguage?.id == 2
                    ? toAbsoluteUrl("/media/flags/russia.svg")
                    : selectedLanguage?.id == 3
                    ? toAbsoluteUrl("/media/flags/england.svg")
                    : toAbsoluteUrl("/media/flags/azerbaijan.svg")
                  : toAbsoluteUrl("/media/flags/azerbaijan.svg")
              }
              alt="flag"
            />
          </span>
        </span>
      </a>

      <div className="menu-sub menu-sub-dropdown py-4">
        {languages &&
          languages.length > 0 &&
          languages.map((l, idx) => (
            <div
              className="menu-item px-3"
              key={l.id}
              onClick={() => {
                dispatch(
                  setSelectedLanguage({
                    id: l?.id,
                    icon: l?.icon,
                    name: idx == 0 ? "az" : idx == 1 ? "ru" : "en",
                  })
                );
              }}>
              <a
                href="#"
                className={clsx("menu-link d-flex px-5", { active: l?.name === selectedLanguage?.name })}>
                <span className="symbol symbol-20px me-4">
                  <img
                    className="rounded-1"
                    src={
                      l?.id == 1
                        ? toAbsoluteUrl("/media/flags/azerbaijan.svg")
                        : l?.id == 2
                        ? toAbsoluteUrl("/media/flags/russia.svg")
                        : l?.id == 3
                        ? toAbsoluteUrl("/media/flags/england.svg")
                        : toAbsoluteUrl("/media/flags/azerbaijan.svg")
                    }
                    alt="flag"
                  />
                </span>
                {shortLangNames[idx]}
              </a>
            </div>
          ))}
      </div>
    </div>
  );
};

export { Languages };
