import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const initialState = {
    selectedLanguage: {
        name: 'az'
    },
    languages: []
}

export const getAllLanguagess = createAsyncThunk('languages/getAllLanguagess', async () => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/languages`);
    return response.data;
});

const SelectedLanguage = createSlice({
    name: "createSlice",
    initialState,
    reducers: {
        setSelectedLanguage: (state, action) => {
            return {
                ...state,
                selectedLanguage: action.payload
            }
        },
        removeSelectedLanguage: (state) => {
            return {
                ...state,
                selectedLanguage: null
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllLanguagess.fulfilled, (state, action) => {
            state.languages = action.payload;
        })
    }

});

export const { setSelectedLanguage, removeSelectedLanguage } = SelectedLanguage.actions;

export default SelectedLanguage.reducer