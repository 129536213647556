import { useState, useEffect, forwardRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { initialEmployeement } from './components/core/_models'
import { createEmployeement, updateEmployement } from "./components/core/_requests";
import clsx from "clsx";
import { isNotEmpty } from '../../../../_metronic/helpers';
import { UsersListLoading } from "./components/loading/UsersListLoading"
import { getEmployeementById } from './components/core/_requests';
import { useQueryResponse } from "./components/core/QueryResponseProvider";
import { useMutation, useQueryClient } from "react-query";
import InputMask from 'react-input-mask';
import moment from "moment";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useIntl } from "react-intl";

const CreateAndUpdatePage = ({ setShowPage, setShowView, showView }) => {

    const intl = useIntl();

    const { id } = useParams()
    const navigate = useNavigate();


    const MaskedInput = forwardRef(({ value, onChange, onClick }, ref) => (
        <div className="ui input" onClick={onClick}>
            <InputMask ref={ref} mask="99/99/9999" placeholder="dd/mm/yyyy" value={value ? value : 'dd/mm/yyyy'} onChange={onChange} />
        </div>
    ));

    const { selectId, setSelectId } = useQueryResponse();

    const queryClient = useQueryClient();

    const [checkFieldByOption, setCheckByFieldByOption] = useState(false);

    const editEmploymentSchema = Yup.object().shape({
        employee_id: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        indefinite: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        probation_dates: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        probation: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        trial_expiration_date: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        date_contract: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),
        reasons_temporary_clause: checkFieldByOption ? Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`) : Yup.string().nullable(),
        employee_start_date: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        date_conclusion_contract: checkFieldByOption ? Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`) : Yup.string().nullable(),

        regulation_property_relations: Yup.string().nullable(),

        termination_cases: Yup.string().nullable(),
        workplace_status: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),
        working_conditions: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),
        job_description: Yup.string().nullable(),
        quota: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        other_condition_wages: Yup.string().nullable(),

    });

    const [employmentForEdit] = useState({
        employee_id: id ? id : initialEmployeement.employee_id,
        reasons_temporary_clause: initialEmployeement.reasons_temporary_clause,
        date_contract: initialEmployeement.date_contract,
        indefinite: initialEmployeement.indefinite,
        probation: initialEmployeement.probation,
        trial_expiration_date: initialEmployeement.trial_expiration_date,
        employee_start_date: initialEmployeement.employee_start_date,
        date_conclusion_contract: initialEmployeement.date_conclusion_contract,
        regulation_property_relations: initialEmployeement.regulation_property_relations,
        termination_cases: initialEmployeement.termination_cases,
        workplace_status: initialEmployeement.workplace_status,
        working_conditions: initialEmployeement.working_conditions,
        job_description: initialEmployeement.job_description,
        quota: initialEmployeement.quota,
        probation_dates: initialEmployeement.probation_dates,
        other_condition_wages: initialEmployeement.other_condition_wages,
    });

    const [employees, setEmployees] = useState([])

    const [options, setOptions] = useState([]);
    const [optAlternatives, setOptAlternatives] = useState([])

    const [probationDates, setProbationDates] = useState([]);
    const [conditions, setConditions] = useState([]);

    const [workStatuses, setWorkStatuses] = useState([]);

    const baseUrl = process.env.REACT_APP_BASE_URL;

    const getEmployment = useMutation(() => { }, {
        onSuccess: () => queryClient.invalidateQueries(['employement'])
    })
    const formik = useFormik({
        initialValues: employmentForEdit,
        validationSchema: editEmploymentSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            try {
                if (isNotEmpty(values.id)) {
                    const result = await updateEmployement({ ...values, trial_expiration_date: moment(values.trial_expiration_date).format('YYYY-MM-DD'), date_contract: moment(values.date_contract).format('YYYY-MM-DD'), date_conclusion_contract: values.date_conclusion_contract ? moment(values.date_conclusion_contract).format('YYYY-MM-DD') : null });

                    if (typeof result == "string") {
                        throw new Error(result)
                    } else {
                        toast.success(`${intl.formatMessage({ id: 'SUCCESS.MESSAGE.UPDATE' })}`);
                        setShowPage(false);
                        getEmployment.mutateAsync();
                    }
                } else {
                    const result = await createEmployeement({ ...values, trial_expiration_date: moment(values.trial_expiration_date).format('YYYY-MM-DD'), date_contract: moment(values.date_contract).format('YYYY-MM-DD'), date_conclusion_contract: values.date_conclusion_contract ? moment(values.date_conclusion_contract).format('YYYY-MM-DD') : null });

                    if (typeof result == "string") {
                        throw new Error(result);

                    } else {
                        toast.success(`${intl.formatMessage({ id: 'SUCCESS.MESSAGE.ADD' })}`);
                        setShowPage(false);
                        getEmployment.mutateAsync();
                    }
                }
            } catch (ex) {
                toast.error(ex?.message, {
                    autoClose: 10000
                })
            } finally {
                setSubmitting(true);
                setSelectId(null);
            }
        },
    });

    const getEmplomentInfo = async () => {
        const result = await getEmployeementById(selectId);
        formik.setValues({ ...result, employee_id: result?.employee?.id })
    }

    const getAllEmployees = async () => {
        try {
            const request = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/employees`);

            if (request.status === 200) {
                setEmployees(request.data)
            } else {
                throw new Error(request.data)
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')

            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }


    const getAllOptions = async () => {
        try {
            const request = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/options`);

            if (request.status === 200) {
                setOptions(request.data)
            } else {
                throw new Error(request.data)
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')

            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }

    const getAllOptionsAlternatives = async () => {
        try {
            const request = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/optionAlternatives`);

            if (request.status === 200) {
                setOptAlternatives(request.data)
            } else {
                throw new Error(request.data)
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')

            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }


    const getAllOptionsDates = async () => {
        try {
            const request = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/probationDates`);

            if (request.status === 200) {
                setProbationDates(request.data)
            } else {
                throw new Error(request.data)
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')

            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }

    const getAllConditions = async () => {
        try {
            const request = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/working-conditions`);

            if (request.status === 200) {
                setConditions(request.data)
            } else {
                throw new Error(request.data)
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')

            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }

    const getAllWorkStatuses = async () => {
        try {
            const request = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/workplaceStatuses`);

            if (request.status === 200) {
                setWorkStatuses(request.data)
            } else {
                throw new Error(request.data)
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')

            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }

    const getEmployeeByIdEmployementInfos = async (id) => {
        try {
            const request = await axios.get(`${baseUrl}/api/employees/${id}/worker-contracts`);

            if (request.status == 200) {
                const info = request.data?.find(fI => fI?.id == selectId);

                if (info) {

                    formik.setValues({ ...info, employee_id: info?.employee?.id })
                }
            } else {
                throw new Error(request?.data)
            }
        } catch (error) {

            if (error?.response?.status == 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')
            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }
    useEffect(() => {

        if (id) {
            getEmployeeByIdEmployementInfos(id)
        }
        else if (selectId) {
            getEmplomentInfo();
        }
        getAllEmployees();
        getAllOptions();
        getAllOptionsAlternatives();
        getAllOptionsDates();
        getAllConditions();
        getAllWorkStatuses();
    }, [selectId, id])


    return (

        <div className="card mb-5 mb-xl-10">
            <div
                className="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_account_profile_details"
                aria-expanded="true"
                aria-controls="kt_account_profile_details"
            >
                <div className="card-title m-0">
                    <h3 className="fw-bold m-0">{showView ? intl.formatMessage({ id: "VIEW.EMPLOYEMENT" }) : selectId ? intl.formatMessage({ id: "UPDATE.EMPLOYEMENT" }) : intl.formatMessage({ id: 'CREATE.EMPLOYEMENT' })}</h3>
                </div>
            </div>
            <div id="kt_account_settings_profile_details" className="collapse show">
                <form
                    id="kt_account_profile_details_form"
                    className="form fv-plugins-bootstrap5 fv-plugins-framework"
                    onSubmit={formik.handleSubmit}
                    noValidate
                >
                    <div className="card-body border-top p-9">

                        <div className="d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch">
                            {id ? null : <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'EMPLOYEE' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.employee?.firstname + " " + formik.values?.employee?.lastname}</p> : <select
                                        {...formik.getFieldProps("employee_id")}
                                        className={clsx(
                                            "form-select form-select-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.employee_id && formik.errors.employee_id,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.employee_id && !formik.errors.employee_id,
                                            }
                                        )}
                                        data-kt-select2="true"
                                        data-placeholder="Select "
                                        data-allow-clear="true"
                                        disabled={formik.isSubmitting}
                                    >
                                        <option value="">{intl.formatMessage({ id: 'SELECT' })}</option>
                                        {employees.length > 0 && employees.map(employee => (
                                            <option key={employee?.id} value={employee?.id}>{employee?.firstname + " " + employee?.lastname}</option>
                                        ))}
                                    </select>}

                                    {formik.touched.employee_id && formik.errors.employee_id && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.employee_id}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>}
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'TEMPRORARY' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{[...optAlternatives]?.find(o => o?.id == formik.values?.indefinite)?.label}</p> : <select
                                        {...formik.getFieldProps("indefinite")}
                                        className={clsx(
                                            "form-select form-select-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.indefinite && formik.errors.indefinite,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.indefinite && !formik.errors.indefinite,
                                            }
                                        )}
                                        data-kt-select2="true"
                                        data-placeholder="Select "
                                        data-allow-clear="true"
                                        disabled={formik.isSubmitting}
                                        onChange={(e) => {
                                            formik.setFieldValue('indefinite', e.target.value)
                                            if (e.target.value == 2) {
                                                setCheckByFieldByOption(true)

                                            } else {
                                                setCheckByFieldByOption(false)

                                            }
                                        }}
                                    >
                                        <option value="">{intl.formatMessage({ id: 'SELECT' })}</option>
                                        {optAlternatives.length > 0 && optAlternatives.map(option => (
                                            <option key={option?.id} value={option?.id}>{option?.label}</option>
                                        ))}
                                    </select>}

                                    {formik.touched.indefinite && formik.errors.indefinite && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.indefinite}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="d-flex flex-column gap-2 mb-6 col-lg-4 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'PROBATION' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container d-flex gap-2">
                                    <div className="d-flex flex-column flex-grow-1">
                                        {showView ? <p>{formik.values?.probation}</p> : <input
                                            type="number"
                                            placeholder={intl.formatMessage({ id: 'PROBATION' })}
                                            {...formik.getFieldProps("probation")}
                                            className={clsx(
                                                "form-control form-control-solid mb-3 mb-lg-0",
                                                {
                                                    "is-invalid":
                                                        formik.touched.probation && formik.errors.probation,
                                                },
                                                {
                                                    "is-valid":
                                                        formik.touched.probation && !formik.errors.probation,
                                                }
                                            )}
                                        />}
                                        {formik.touched.probation && formik.errors.probation && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    <span role="alert">{formik.errors.probation}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="d-flex flex-column flex-grow-1">
                                        {showView ? <p>{[...probationDates]?.find(p => p?.id == formik.values?.probation_dates)?.label}</p> : <select
                                            {...formik.getFieldProps("probation_dates")}
                                            className={clsx(
                                                "form-select form-select-solid mb-3 mb-lg-0",
                                                {
                                                    "is-invalid":
                                                        formik.touched.probation_dates && formik.errors.probation_dates,
                                                },
                                                {
                                                    "is-valid":
                                                        formik.touched.probation_dates && !formik.errors.probation_dates,
                                                }
                                            )}
                                            data-kt-select2="true"
                                            data-placeholder="Select "
                                            data-allow-clear="true"
                                            disabled={formik.isSubmitting}
                                        >
                                            <option value="">{intl.formatMessage({ id: "SELECT" })}</option>
                                            {probationDates.length > 0 && probationDates.map(pro => (
                                                <option value={pro?.id} key={pro?.id}>{pro?.label}</option>
                                            ))}
                                        </select>}

                                        {formik.touched.probation_dates && formik.errors.probation_dates && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    <span role="alert">{formik.errors.probation_dates}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'TRIAL.END.DATE' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{moment(formik.values.trial_expiration_date).format('DD-MM-YYYY')}</p> : <div className="w-100">
                                        <DatePicker
                                            wrapperClassName="datePicker"
                                            dateFormat="dd/MM/yyyy"
                                            placeholder="-- -- --"
                                            name="trial_expiration_date"
                                            customInput={<MaskedInput />}
                                            selected={formik.values.trial_expiration_date && new Date(formik.values.trial_expiration_date)}
                                            onChange={(opt) => formik.setFieldValue("trial_expiration_date", opt)}
                                            showDayDropdown={true}
                                            showMonthDropdown={true}
                                            showYearDropdown={true}
                                            shouldCloseOnSelect={true}
                                            calendarStartDay={1}
                                            scrollableYearDropdown={false}
                                        />
                                    </div>}

                                    {formik.touched.trial_expiration_date && formik.errors.trial_expiration_date && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.trial_expiration_date}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>
                        <div className="d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch">

                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'START.DATE' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{moment(formik.values.employee_start_date).format('DD-MM-YYYY')}</p> : <div className="w-100">
                                        <DatePicker
                                            wrapperClassName="datePicker"
                                            dateFormat="dd/MM/yyyy"
                                            placeholder="-- -- --"
                                            customInput={<MaskedInput />}
                                            name="employee_start_date"
                                            selected={formik.values.employee_start_date && new Date(formik.values.employee_start_date)}
                                            onChange={(opt) => formik.setFieldValue("employee_start_date", opt)}
                                            showDayDropdown={true}
                                            showMonthDropdown={true}
                                            showYearDropdown={true}
                                            shouldCloseOnSelect={true}
                                            calendarStartDay={1}
                                            scrollableYearDropdown={false}
                                        />
                                    </div>}

                                    {formik.touched.employee_start_date && formik.errors.employee_start_date && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.employee_start_date}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'CLOSING.DATE' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{moment(formik.values.date_contract).format('DD-MM-YYYY')}</p> : <div className="w-100">
                                        <DatePicker
                                            wrapperClassName="datePicker"
                                            dateFormat="dd/MM/yyyy"
                                            customInput={<MaskedInput />}
                                            placeholder="-- -- --"
                                            name="date_contract"
                                            selected={formik.values.date_contract && new Date(formik.values.date_contract)}
                                            onChange={(opt) => formik.setFieldValue("date_contract", opt)}
                                            showDayDropdown={true}
                                            showMonthDropdown={true}
                                            showYearDropdown={true}
                                            shouldCloseOnSelect={true}
                                            calendarStartDay={1}
                                            scrollableYearDropdown={false}
                                        />
                                    </div>}

                                    {formik.touched.date_contract && formik.errors.date_contract && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.date_contract}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6`}>
                                    {intl.formatMessage({ id: 'REGULATION.OF.PROPERTY.RELATIONS' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.regulation_property_relations}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'REGULATION.OF.PROPERTY.RELATIONS' })}
                                        {...formik.getFieldProps("regulation_property_relations")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.regulation_property_relations && formik.errors.regulation_property_relations,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.regulation_property_relations && !formik.errors.regulation_property_relations,
                                            }
                                        )}
                                    />}
                                    {formik.touched.regulation_property_relations && formik.errors.regulation_property_relations && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.regulation_property_relations}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6`}>
                                    {intl.formatMessage({ id: 'CIRCUMSTANCES' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.termination_cases}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'CIRCUMSTANCES' })}
                                        {...formik.getFieldProps("termination_cases")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.termination_cases && formik.errors.termination_cases,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.termination_cases && !formik.errors.termination_cases,
                                            }
                                        )}
                                    />}
                                    {formik.touched.termination_cases && formik.errors.termination_cases && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.termination_cases}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>



                        </div>

                        <div className="d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch">

                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6`}>
                                    {intl.formatMessage({ id: 'OTHER.TERMS.OF.SALARY.PAYMENT' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.other_condition_wages}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'OTHER.TERMS.OF.SALARY.PAYMENT' })}
                                        {...formik.getFieldProps("other_condition_wages")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.other_condition_wages && formik.errors.other_condition_wages,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.other_condition_wages && !formik.errors.other_condition_wages,
                                            }
                                        )}
                                    />}
                                    {formik.touched.other_condition_wages && formik.errors.other_condition_wages && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.other_condition_wages}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'WORKPLACE.STATUS' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{[...workStatuses]?.find(ws => ws?.id == formik.values?.workplace_status)?.title}</p> : <select
                                        {...formik.getFieldProps("workplace_status")}
                                        className={clsx(
                                            "form-select form-select-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.workplace_status && formik.errors.workplace_status,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.workplace_status && !formik.errors.workplace_status,
                                            }
                                        )}
                                        data-kt-select2="true"
                                        data-placeholder="Select "
                                        data-allow-clear="true"
                                        disabled={formik.isSubmitting}
                                    >
                                        <option value="">{intl.formatMessage({ id: 'SELECT' })}</option>
                                        {workStatuses.length > 0 && workStatuses.map(work => (
                                            <option key={work?.id} value={work?.id}>{work?.title}</option>
                                        ))}
                                    </select>}

                                    {formik.touched.workplace_status && formik.errors.workplace_status && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.workplace_status}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'WORKPLACE.CONDITIONS' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{[...conditions]?.find(c => c?.id == formik.values?.working_conditions)?.title}</p> : <select
                                        {...formik.getFieldProps("working_conditions")}
                                        className={clsx(
                                            "form-select form-select-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.working_conditions && formik.errors.working_conditions,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.working_conditions && !formik.errors.working_conditions,
                                            }
                                        )}
                                        data-kt-select2="true"
                                        data-placeholder="Select "
                                        data-allow-clear="true"
                                        disabled={formik.isSubmitting}
                                    >
                                        <option value="">{intl.formatMessage({ id: 'SELECT' })}</option>
                                        {conditions.length > 0 && conditions.map(con => (
                                            <option value={con?.id} key={con?.id}>{con?.title}</option>
                                        ))}
                                    </select>}

                                    {formik.touched.working_conditions && formik.errors.working_conditions && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.working_conditions}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6`}>
                                    {intl.formatMessage({ id: 'JOB.DESCRIPTION' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.job_description}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'JOB.DESCRIPTION' })}
                                        {...formik.getFieldProps("job_description")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.job_description && formik.errors.job_description,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.job_description && !formik.errors.job_description,
                                            }
                                        )}
                                    />}
                                    {formik.touched.job_description && formik.errors.job_description && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.job_description}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>

                        <div className="d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch">

                            {checkFieldByOption && <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${checkFieldByOption && 'required'}`}>
                                    {intl.formatMessage({ id: 'REASON.FOR.TEMPRORAY.CLOSURE' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.reasons_temporary_clause}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'REASON.FOR.TEMPRORAY.CLOSURE' })}
                                        {...formik.getFieldProps("reasons_temporary_clause")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.reasons_temporary_clause && formik.errors.reasons_temporary_clause,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.reasons_temporary_clause && !formik.errors.reasons_temporary_clause,
                                            }
                                        )}
                                    />}
                                    {formik.touched.reasons_temporary_clause && formik.errors.reasons_temporary_clause && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.reasons_temporary_clause}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>}

                            {checkFieldByOption && <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${checkFieldByOption && 'required'}`}>
                                    {intl.formatMessage({ id: 'TEMPRORAY.CLOSING.DATE' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{moment(formik.values.date_conclusion_contract).format('DD-MM-YYYY')}</p> : <div className="w-100">
                                        <DatePicker
                                            wrapperClassName="datePicker"
                                            dateFormat="dd/MM/yyyy"
                                            placeholder="-- -- --"
                                            name="date_conclusion_contract"
                                            customInput={<MaskedInput />}
                                            selected={formik.values.date_conclusion_contract && new Date(formik.values.date_conclusion_contract)}
                                            onChange={(opt) => formik.setFieldValue("date_conclusion_contract", opt)}
                                            showDayDropdown={true}
                                            showMonthDropdown={true}
                                            showYearDropdown={true}
                                            shouldCloseOnSelect={true}
                                            calendarStartDay={1}
                                            scrollableYearDropdown={false}
                                        />
                                    </div>}

                                    {formik.touched.date_conclusion_contract && formik.errors.date_conclusion_contract && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.date_conclusion_contract}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>}

                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'QUOTA' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.quota}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'QUOTA' })}
                                        {...formik.getFieldProps("quota")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.quota && formik.errors.quota,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.quota && !formik.errors.quota,
                                            }
                                        )}
                                    />}
                                    {formik.touched.quota && formik.errors.quota && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.quota}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button
                            type="reset"
                            onClick={() => {
                                setShowPage(false)
                                setSelectId(null);
                                setShowView(false)
                            }}
                            disabled={formik.isSubmitting}
                            className="btn btn-light btn-active-light-primary me-2"
                        >
                            {intl.formatMessage({ id: 'DISCARD' })}
                        </button>
                        {showView == false && <button
                            type="submit"
                            disabled={
                                formik.isSubmitting ||
                                !formik.isValid ||
                                !formik.touched
                            }
                            className="btn btn-primary"
                            id="kt_account_profile_details_submit"
                        >
                            <span className="indicator-label">{formik.isSubmitting ? intl.formatMessage({ id: "PLEASE.WAIT" }) : intl.formatMessage({ id: 'AUTH.GENERAL.SUBMIT_BUTTON' })}</span>
                        </button>}
                    </div>
                </form>
                {(formik.isSubmitting) && <UsersListLoading />}
            </div>
        </div>

    )
}

export default CreateAndUpdatePage

