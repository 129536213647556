import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import ApexChartt from '../../../_metronic/partials/widgets/charts/Chart1'
import ApexChartoval from '../../../_metronic/partials/widgets/charts/Chart2'
import ApexChartoval2 from '../../../_metronic/partials/widgets/charts/Chart3'
import ApexChartline from '../../../_metronic/partials/widgets/charts/Chart4'
import ApexChartbar2 from '../../../_metronic/partials/widgets/charts/Chart5'
import ApexChartOval2 from '../../../_metronic/partials/widgets/charts/Chart6'
import { Helmet } from 'react-helmet-async';

const DashboardPage = () => {

  const intl = useIntl();
  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "MENU.DASHBOARD" })}</title>
      </Helmet>
      <div className='row g-5 g-xl-8 mt-1'>
        <div className='col-xl-6'>
          <div className='card card-primary'>
            <div className='card-body'>
              <div className='chart'>
                {/* <ChartOne /> */}
                <ApexChartt />
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-6'>
          <div className='card card-primary'>
            <div className='card-body'>
              <div className='chart'>
                {/* <ChartTwo /> */}
                <ApexChartoval />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row g-5 g-xl-8 mt-1'>
        <div className='col-xl-6'>
          <div className='card card-primary'>
            <div className='card-body'>
              <div className='chart'>
                {/* <ChartThree /> */}
                <ApexChartoval2 />
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-6'>
          <div className='card card-primary'>
            <div className='card-body'>
              <div className='chart'>
                {/* <ChartFour /> */}
                <ApexChartline />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row g-5 g-xl-8 mt-1'>
        <div className='col-xl-6'>
          <div className='card card-primary'>
            <div className='card-body'>
              <div className='chart'>
                {/* <ChartFive /> */}
                <ApexChartbar2 />
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-6'>
          <div className='card card-primary'>
            <div className='card-body'>
              <div className='chart'>
                {/* <ChartSix /> */}
                <ApexChartOval2 />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
