import clsx from 'clsx'
import { useIntl } from 'react-intl';

const UserCustomHeader = ({ className, title, tableProps }) => {

  const intl = useIntl();


  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className
      )}
      style={{ cursor: 'pointer' }}
    >
      {title == "Actions" ? intl.formatMessage({ id: 'ACTIONS' }) :
        title == "ASA" ? intl.formatMessage({ id: "NSF" }) :
          title == "İşəgötürənin adı" ? intl.formatMessage({ id: "NAME.OF.EMPLOYER" }) :
            title == "Tarix aralığı" ? intl.formatMessage({ id: 'DATE.RANGE' }) :
              title == "İşdən çıxma səbəbi" ? intl.formatMessage({ id: "REASON.FOR.DISMISSIAL" }) :
                title == "Fəaliyyət sektoru" ? intl.formatMessage({ id: "ACTIVITY.SECTOR" }) :
                  title}
    </th>
  )
}

export { UserCustomHeader }
