// @ts-nocheck
import { UserInfoCell } from "./UserInfoCell";
import { UserActionsCell } from "./UserActionsCell";
import { UserCustomHeader } from "./UserCustomHeader";
import moment from "moment";

const usersColumns = [
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title="ID" className="min-w-125px" />
    ),
    id: 1,
    accessor: "id",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.id} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ASA"
        className="min-w-125px"
      />
    ),
    id: 2,
    accessor: "user",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.employee?.firstname + ' ' + props.data[props.row.index]?.employee?.lastname} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Vəsiqənin seriya və nömrəsi"
        className="min-w-125px"
      />
    ),
    id: 3,
    accessor: "seriesAndNumberOfTheVoucher",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.license_serial_number} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Kateqoriya"
        className="min-w-125px"
      />
    ),
    id: 4,
    accessor: "category",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index].category?.description} />
    ),
  },



  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Vəsiqəni verən orqan"
        className="min-w-125px"
      />
    ),
    id: 5,
    accessor: "issuingAuthority",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index].license_issuer} />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Vəsiqənin verildiyi tarix"
        className="min-w-125px"
      />
    ),
    id: 6,
    accessor: "theDateOfIssuanceOfThePermit",
    Cell: ({ ...props }) => (
      <UserInfoCell user={moment(props.data[props.row.index].issue_date).format('DD-MM-YYYY')} />
    ),
  },



  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Etibarlılıq müddəti"
        className="min-w-125px"
      />
    ),
    id: 7,
    accessor: "thePeriodOfValidity",
    Cell: ({ ...props }) => (
      <UserInfoCell user={moment(props.data[props.row.index].expire_date).format('DD-MM-YYYY')} />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Actions"
        className="text-end min-w-100px"
      />
    ),
    id: 8,
    accessor: "actions",
    Cell: ({ ...props }) => (
      <UserActionsCell id={props.data[props.row.index].id} />
    ),
  },
];

export { usersColumns };
