import { useTable, useColumns } from 'react-table'
import { CustomHeaderColumn } from './columns/CustomHeaderColumn'
import { useQueryResponseData, useQueryResponseLoading } from '../core/QueryResponseProvider'
import { usersColumns } from './columns/columns'
import { IntervieweristLoading } from '../components/loading/IntervieweristLoading'
import { InterviewerListPagination } from '../components/pagination/InterviewerListPagination'
import { KTCardBody } from '../../../../../../_metronic/helpers'
import InterviewerColumnVisibilityModal from '../components/modal/InterviewerColumnVisibilityModal'
import { useIntl } from 'react-intl';

const InterviewersTable = ({ printRef, show, setShow }) => {
  const intl = useIntl();
  const users = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = users
  const columns = usersColumns
  const {
    getTableProps,
    getTableBodyProps,
    headers,
    rows,
    prepareRow,
    state: { hiddenColumns },
    setHiddenColumns,
  } = useTable(
    {
      data,
      columns,
    },
    useColumns
  )

  return (
    <KTCardBody className='py-4'>
      {show && (
        <InterviewerColumnVisibilityModal
          setShow={setShow}
          hiddenColumns={hiddenColumns}
          headers={headers}
          columns={columns}
          setHiddenColumns={setHiddenColumns}
        />
      )}
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          ref={printRef}
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((header) => {
                switch (header.isVisible) {
                  case false:
                    return null
                  case true:
                    return <CustomHeaderColumn key={header.id} column={header} />
                  default:
                    return null
                }
              })}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row, i) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, index) => (
                      <td {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    {intl.formatMessage({ id: 'NO.MATCHING.FOUND' })}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <InterviewerListPagination />
      {isLoading && <IntervieweristLoading />}
    </KTCardBody>
  )
}

export { InterviewersTable }
