import axios, { AxiosResponse } from "axios";
import { ID, Response } from "../../../../../../_metronic/helpers";
import { FamilyInfo, FamilyInfoQueryResponse } from "./_models";

const API_URL = process.env.REACT_APP_BASE_URL;

const FAMILY_INFO_URL = `${API_URL}/api/family-infos`;
const GET_FAMILY_INFO_URL = `${API_URL}/api/family-infos`;

const getFamiliesInfo = (query: string): Promise<FamilyInfoQueryResponse> => {
  return axios
    .get(`${GET_FAMILY_INFO_URL}?${query}`)
    .then((d: AxiosResponse<FamilyInfoQueryResponse>) => {
      return d.data;
    });
};


const getFamilyInfoByID = (id: ID): Promise<FamilyInfo | undefined> => {
  return axios.get(`${FAMILY_INFO_URL}/${id}`).then((response) => {
    return response.data;
  });
};

const createFamilyInfo = (
  user: FamilyInfo
): Promise<FamilyInfo | undefined> => {
  return axios
    .post(FAMILY_INFO_URL, user)
    .then((response: AxiosResponse<Response<FamilyInfo>>) => response.data)
    .then((response: Response<FamilyInfo>) => response.data)
    .catch(err => {
      return err?.response?.data?.message
    })
};

const updateFamilyInfo = (
  user: FamilyInfo
): Promise<FamilyInfo | undefined> => {
  return axios
    .put(`${FAMILY_INFO_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<FamilyInfo>>) => response.data)
    .then((response: Response<FamilyInfo>) => response.data)
    .catch(err => {
       return err?.response?.data?.message
    })
};

const deleteFamilyInfo = (userId: ID): Promise<void> => {
  return axios.delete(`${FAMILY_INFO_URL}/${userId}`).then(() => {
     return "success"
  }).catch(err => {
     return err
  });
};

const deleteFamiliesInfos = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) =>
    axios.delete(`${FAMILY_INFO_URL}/${id}`)
  );
  return axios.all(requests).then(() => {});
};

export {
  getFamiliesInfo,
  getFamilyInfoByID,
  createFamilyInfo,
  updateFamilyInfo,
  deleteFamiliesInfos,
  deleteFamilyInfo,
};
