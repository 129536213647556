import axios, { AxiosResponse } from "axios";
import { ID, Response } from "../../../../../../_metronic/helpers";
import {
  UserMedicalInformation,
  UserMedicalInformationQueryResponse,
} from "./_models";

const API_URL = process.env.REACT_APP_BASE_URL;
const USERS_MEDICAL_INFORMATION_URL = `${API_URL}/api/medical-infos`;
const GET_MEDICAL_INFORMATION_URL = `${API_URL}/api/medical-infos`;

const getUsersMedicalInformations =
  (): Promise<UserMedicalInformationQueryResponse> => {
    return axios
      .get(`${GET_MEDICAL_INFORMATION_URL}`)
      .then((d: AxiosResponse<UserMedicalInformationQueryResponse>) => {
        return d;
      })
      .catch((err) => err);
  };

const getUserMedicalInformationById = (
  id: ID
): Promise<UserMedicalInformation | undefined> => {
  return axios
    .get(`${USERS_MEDICAL_INFORMATION_URL}/${id}`)
    .then((response) => {
      return response.data;
    });
};

const createUserMedicalInformation = (
  user: UserMedicalInformation
): Promise<UserMedicalInformation | undefined> => {
  return axios
    .post(USERS_MEDICAL_INFORMATION_URL, user)
    .then(
      (response: AxiosResponse<Response<UserMedicalInformation>>) =>
        response.data
    )
    .catch((err) => err?.response?.data?.message);
};

const updateUserMedicalInformation = (
  user: UserMedicalInformation
): Promise<UserMedicalInformation | undefined> => {
  return axios
    .put(`${USERS_MEDICAL_INFORMATION_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<UserMedicalInformation>>) => {})
    .catch((err) => err?.response?.data?.message);
};

const deleteUserMedicalInformation = (userId: ID): Promise<void> => {
  return axios
    .delete(`${USERS_MEDICAL_INFORMATION_URL}/${userId}`)
    .then(() => {});
};

const deleteUsersMedicalInformations = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) =>
    axios.delete(`${USERS_MEDICAL_INFORMATION_URL}/${id}`)
  );
  return axios.all(requests).then(() => {});
};

export {
  getUsersMedicalInformations,
  getUserMedicalInformationById,
  createUserMedicalInformation,
  updateUserMedicalInformation,
  deleteUserMedicalInformation,
  deleteUsersMedicalInformations,
};
