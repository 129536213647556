import { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import ReactToPrint from "react-to-print";
import { downloadExcel } from "react-export-table-to-excel";
import { KTIcon } from "../../../../../../_metronic/helpers";
import { useQueryResponse } from "../core/QueryResponseProvider";
import { CSVDownload } from "react-csv";
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { useIntl } from 'react-intl';

const UsersToolButtons = ({ printRef, setShow }) => {
  const intl = useIntl();
  const { filterUsers } = useQueryResponse();

  const [copy, setCopy] = useState({
    text: "",
    copied: false,
  });

  const [check, setCheck] = useState(false);

  useEffect(() => {
    setCopy((copy) => ({
      ...copy,
      text: [...filterUsers.map((fU) => {
        return [fU?.id, `${fU?.employee?.firstname} ${fU?.employee?.lastname} ${fU?.employee?.father_name}`, `${fU?.m_firstname} ${fU?.m_lastname}`, fU?.family_member_type?.label, fU?.contact_number, fU?.address]
      })].flat(Infinity).join("->"),
    }));
  }, [filterUsers]);

  const handleColumnVisibilityModal = () => {
    setShow(true);
  };
  const csvData = [
    ["ID", intl.formatMessage({ id: "NSF" }), intl.formatMessage({ id: "NAMEE" }), intl.formatMessage({ id: 'TYPE.OF.FAMILY.MEMBER' }), intl.formatMessage({ id: "CONTACT.NUMBER" }), intl.formatMessage({ id: 'ADDRESS' })],
    ...filterUsers?.map(fU => {
      return [fU?.id, `${fU?.employee?.firstname} ${fU?.employee?.lastname} ${fU?.employee?.father_name}`, `${fU?.m_firstname} ${fU?.m_lastname}`, fU?.family_member_type?.label, fU?.contact_number, fU?.address]
    })
  ]

  const header = ["ID", intl.formatMessage({ id: "NSF" }), intl.formatMessage({ id: "NAMEE" }), intl.formatMessage({ id: 'TYPE.OF.FAMILY.MEMBER' }), intl.formatMessage({ id: "CONTACT.NUMBER" }), intl.formatMessage({ id: 'ADDRESS' })];
  const body = [...filterUsers?.map(fU => {
    return [fU?.id, `${fU?.employee?.firstname} ${fU?.employee?.lastname} ${fU?.employee?.father_name}`, `${fU?.m_firstname} ${fU?.m_lastname}`, fU?.family_member_type?.label, fU?.contact_number, fU?.address]
  })];

  const handleDownloadExcel = () => {
    downloadExcel({
      fileName: "Family Infos",
      sheet: "Family Infos",
      tablePayload: {
        header,
        body
      }
    })
  }
  const handleExportPDFTable = () => {
    const doc = new jsPDF();

    autoTable(doc, {
      head: [["ID", intl.formatMessage({ id: "NSF" }), intl.formatMessage({ id: "NAMEE" }), intl.formatMessage({ id: 'TYPE.OF.FAMILY.MEMBER' }), intl.formatMessage({ id: "CONTACT.NUMBER" }), intl.formatMessage({ id: 'ADDRESS' })]],
      body: [...filterUsers?.map(fU => {
        return [fU?.id, `${fU?.employee?.firstname} ${fU?.employee?.lastname} ${fU?.employee?.father_name}`, `${fU?.m_firstname} ${fU?.m_lastname}`, fU?.family_member_type?.label, fU?.contact_number, fU?.address]
      })]
    })

    doc.save('family-infos.pdf');

  }

  return (
    <div className="d-flex flex-column justify-content-center align-items-center gap-2 mt-2">
      <button
        type="button"
        className="btn btn-light-primary mx-auto w-75"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
      >
        <CopyToClipboard
          text={copy.text}
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          onCopy={() => {
            setCopy({ ...copy, copied: true });
            toast.success("Copied");
          }}
        >
          <span>{intl.formatMessage({ id: 'COPY' })}</span>
        </CopyToClipboard>
      </button>


      <ReactToPrint
        trigger={() => {
          return <button
            type="button"
            className="btn btn-light-primary mx-auto w-75"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
          >{intl.formatMessage({ id: 'PRINT' })}</button>;
        }}
        content={() => printRef.current}
      />

      <button
        type="button"
        className="btn btn-light-primary mx-auto w-75"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        onClick={handleDownloadExcel}
      >
        <KTIcon iconName="exit-up" className="fs-2" />
        {intl.formatMessage({ id: 'EXCEL' })}
      </button>
      <button
        type="button"
        className="btn btn-light-primary mx-auto w-75"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        onClick={() => {
          setCheck(true);
          setTimeout(() => {
            setCheck(false);
          }, 1000)
        }}
      >
        <KTIcon iconName="exit-up" className="fs-2" />
        CSV
        {check && <CSVDownload data={csvData} filename={"family-infos.csv"} separator={";"} enclosingCharacter={``} />}
      </button>
      <button
        type="button"
        className="btn btn-light-primary mx-auto w-75"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        onClick={handleExportPDFTable}
      >
        <KTIcon iconName="exit-up" className="fs-2" />
        PDF
      </button>

      <button
        type="button"
        className="btn btn-light-primary mx-auto w-75"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        onClick={handleColumnVisibilityModal}
      >
        {intl.formatMessage({ id: 'COLUMN.VISIBILITY' })}
      </button>
    </div>
  );
};

export default UsersToolButtons;
