// @ts-nocheck
import { UserInfoCell } from "./UserInfoCell";
import { UserActionsCell } from "./UserActionsCell";
import { UserCustomHeader } from "./UserCustomHeader";

const usersColumns = [
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title="ID" className="min-w-125px" />
    ),
    id: 1,
    accessor: "id",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.id} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ASA"
        className="min-w-125px"
      />
    ),
    id: 2,
    accessor: "user",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.employee?.firstname + " " + props.data[props.row.index]?.employee?.lastname} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Dil"
        className="min-w-125px"
      />
    ),
    id: 3,
    accessor: "language",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.language?.name} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Oxuma"
        className="min-w-125px"
      />
    ),
    id: 4,
    accessor: "reading",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index].language_reading?.label} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Yazma"
        className="min-w-125px"
      />
    ),
    id: 5,
    accessor: "writing",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index].language_writing?.label} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Danışmaq"
        className="min-w-125px"
      />
    ),
    id: 6,
    accessor: "talking",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index].language_speaking?.label} />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Başa düşmə"
        className="min-w-125px"
      />
    ),
    id: 7,
    accessor: "understanding",
    Cell: ({ ...props }) => (
      <UserInfoCell
        user={props.data[props.row.index].language_understanding?.label}
      />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Actions"
        className="text-end min-w-100px"
      />
    ),
    id: 8,
    accessor: "actions",
    Cell: ({ ...props }) => (
      <UserActionsCell id={props.data[props.row.index].id} />
    ),
  },
];

export { usersColumns };
