import { UserInfoCell } from "./UserInfoCell";
import { UserActionsCell } from "./UserActionsCell";
import { UserCustomHeader } from "./UserCustomHeader";

const usersColumns = [
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title="ID" className="min-w-125px" />
    ),
    id: 1,
    accessor: "Sicil No",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.id} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Ad"
        className="min-w-125px"
      />
    ),
    id: 2,
    accessor: "Ad",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.name} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Soyadı"
        className="min-w-125px"
      />
    ),
    id: 3,
    accessor: "Soyadı",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index].surname} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Vəzifə"
        className="min-w-125px"
      />
    ),
    id: 4,
    accessor: "Vəzifə",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index].position} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Ölkə"
        className="min-w-125px"
      />
    ),
    id: 5,
    accessor: "Ölkə",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index].contry } />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="İtirilmə riski"
        className="min-w-125px"
      />
    ),
    id: 6,
    accessor: "İtirilmə riski",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.risk} />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Növbəti vəzifəyə uyğunluğu"
        className="min-w-125px"
      />
    ),
    id: 7,
    accessor: "Növbəti vəzifəyə uyğunluğu",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.nextPos} />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="İş ailəsi"
        className="min-w-125px"
      />
    ),
    id: 8,
    accessor: "İş ailəsi",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.workFamily} />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Birbaşa rəhbəri"
        className="min-w-125px"
      />
    ),
    id: 9,
    accessor: "Birbaşa rəhbəri",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.leader} />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Təşkilati statusu (Sədr -X)"
        className="min-w-125px"
      />
    ),
    id: 10,
    accessor: "Təşkilati statusu (Sədr -X)",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.teamStatus} />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Cinsi"
        className="min-w-125px"
      />
    ),
    id: 11,
    accessor: "Cinsi",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.gender} />
    ),
  },


  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Hazırki vəzifəsində işləyir"
        className="min-w-125px"
      />
    ),
    id: 12,
    accessor: "Hazırki vəzifəsində işləyir",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.current} />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Şirkətdaxili təcrübəsi"
        className="min-w-125px"
      />
    ),
    id: 13,
    accessor: "Şirkətdaxili təcrübəsi",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.inside} />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Mobillik"
        className="min-w-125px"
      />
    ),
    id: 14,
    accessor: "Mobillik",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.mobillik} />
    ),
  },

  
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Yaş aralığı"
        className="min-w-125px"
      />
    ),
    id: 15,
    accessor: "Yaş aralığı",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.ageAverage} />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Dil bilikləri 1"
        className="min-w-125px"
      />
    ),
    id: 16,
    accessor: "Dil bilikləri 1",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.lanOne} />
    ),
  },

  
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Dil bilikləri 2"
        className="min-w-125px"
      />
    ),
    id: 17,
    accessor: "Dil bilikləri 2",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.lanTwo} />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Dil bilikləri 3"
        className="min-w-125px"
      />
    ),
    id: 18,
    accessor: "Dil bilikləri 3",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.lanThree} />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Actions"
        className="text-end min-w-100px"
      />
    ),
    id: 19,
    accessor: "actions",
    Cell: ({ ...props }) => (
      <UserActionsCell id={props.data[props.row.index].id} />
    ),
  },
];

export { usersColumns };
