import { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import ReactToPrint from "react-to-print";
import "react-toastify/dist/ReactToastify.css";
import { downloadExcel } from "react-export-table-to-excel";
import { KTIcon } from "../../../../../../_metronic/helpers";
import { useQueryResponse } from "../core/QueryResponseProvider";
import { CSVDownload } from "react-csv";
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import moment from "moment";
import { useIntl } from 'react-intl';

const UsersToolButtons = ({ printRef, setShow }) => {
  const intl = useIntl();

  const { filterUsers } = useQueryResponse();

  const [copy, setCopy] = useState({
    text: "",
    copied: false,
  });

  const [check, setCheck] = useState(false);

  useEffect(() => {
    setCopy((copy) => ({
      ...copy,
      text: [...filterUsers.map((fu) => {
        return [fu?.id, `${fu?.employee?.firstname} ${fu?.employee?.lastname} ${fu?.employee?.father_name}`, fu?.group?.label, fu?.category?.id == 1 ? 'Kateqoriya 1' : 'Kateqoriya 2', fu?.staff?.label, fu?.rank?.label, fu?.specialty_acc, fu?.fitness_service, fu?.registration_service, moment(fu?.registration_date).format('DD-MM-YYYY'), fu?.general, fu?.special, fu?.no_official, fu?.additional_information, moment(fu?.completion_date).format('DD-MM-YYYY')]
      })].flat(Infinity).join("->"),
    }));
  }, [filterUsers]);


  const handleColumnVisibilityModal = () => {
    setShow(true);
  };

  const csvData = [
    ["ID", intl.formatMessage({ id: "NSF" }), intl.formatMessage({ id: "REGISTRATION.GROUP" }), intl.formatMessage({ id: "REGISTRATION.CATEGORY" }),
      intl.formatMessage({ id: "STAFF" }), intl.formatMessage({ id: 'MILITARY.RANK' }),
      intl.formatMessage({ id: "MILITARY.ACCOUNTING.MAJOR" }), intl.formatMessage({ id: "MILITARY.FITNESS.FOR.SERVICE" }), intl.formatMessage({ id: 'PLACE.OF.MILITARY.REGISTRATION.SERVICE' }),
      intl.formatMessage({ id: 'DATE.OF.MILITARY.REGISTRATION' }), intl.formatMessage({ id: 'TOTAL.TEAM.NO' }), intl.formatMessage({ id: "SPECIAL" }), intl.formatMessage({ id: "RECORD.ABSENCE" }),
      intl.formatMessage({ id: "ADDITIONAL.INFORMATION" }), intl.formatMessage({ id: 'FILLING.DATE' })
    ],
    ...filterUsers?.map(fu => {
      return [fu?.id, `${fu?.employee?.firstname} ${fu?.employee?.lastname} ${fu?.employee?.father_name}`, fu?.group?.label, fu?.category?.id == 1 ? 'Kateqoriya 1' : 'Kateqoriya 2', fu?.staff?.label, fu?.rank?.label, fu?.specialty_acc, fu?.fitness_service, fu?.registration_service, moment(fu?.registration_date).format('DD-MM-YYYY'), fu?.general, fu?.special, fu?.no_official, fu?.additional_information, moment(fu?.completion_date).format('DD-MM-YYYY')]
    })
  ];

  const header = ["ID", intl.formatMessage({ id: "NSF" }), intl.formatMessage({ id: "REGISTRATION.GROUP" }), intl.formatMessage({ id: "REGISTRATION.CATEGORY" }),
    intl.formatMessage({ id: "STAFF" }), intl.formatMessage({ id: 'MILITARY.RANK' }),
    intl.formatMessage({ id: "MILITARY.ACCOUNTING.MAJOR" }), intl.formatMessage({ id: "MILITARY.FITNESS.FOR.SERVICE" }), intl.formatMessage({ id: 'PLACE.OF.MILITARY.REGISTRATION.SERVICE' }),
    intl.formatMessage({ id: 'DATE.OF.MILITARY.REGISTRATION' }), intl.formatMessage({ id: 'TOTAL.TEAM.NO' }), intl.formatMessage({ id: "SPECIAL" }), intl.formatMessage({ id: "RECORD.ABSENCE" }),
    intl.formatMessage({ id: "ADDITIONAL.INFORMATION" }), intl.formatMessage({ id: 'FILLING.DATE' })
  ];

  const body = [...filterUsers?.map(fu => {
    return [fu?.id, `${fu?.employee?.firstname} ${fu?.employee?.lastname} ${fu?.employee?.father_name}`, fu?.group?.label, fu?.category?.id == 1 ? 'Kateqoriya 1' : 'Kateqoriya 2', fu?.staff?.label, fu?.rank?.label, fu?.specialty_acc, fu?.fitness_service, fu?.registration_service, moment(fu?.registration_date).format('DD-MM-YYYY'), fu?.general, fu?.special, fu?.no_official, fu?.additional_information, moment(fu?.completion_date).format('DD-MM-YYYY')]
  })];

  const handleDownloadExcel = () => {
    downloadExcel({
      fileName: "Military Infos",
      sheet: "Military Infos",
      tablePayload: {
        header,
        body
      }
    })
  }

  const handleExportPDFTable = () => {
    const doc = new jsPDF({ format: 'a3', orientation: 'landscape' });

    autoTable(doc, {
      head: [["ID", intl.formatMessage({ id: "NSF" }), intl.formatMessage({ id: "REGISTRATION.GROUP" }), intl.formatMessage({ id: "REGISTRATION.CATEGORY" }),

        intl.formatMessage({ id: "STAFF" }), intl.formatMessage({ id: 'MILITARY.RANK' }),
        intl.formatMessage({ id: "MILITARY.ACCOUNTING.MAJOR" }), intl.formatMessage({ id: "MILITARY.FITNESS.FOR.SERVICE" }), intl.formatMessage({ id: 'PLACE.OF.MILITARY.REGISTRATION.SERVICE' }),
        intl.formatMessage({ id: 'DATE.OF.MILITARY.REGISTRATION' }), intl.formatMessage({ id: 'TOTAL.TEAM.NO' }), intl.formatMessage({ id: "SPECIAL" }), intl.formatMessage({ id: "RECORD.ABSENCE" }),
        intl.formatMessage({ id: "ADDITIONAL.INFORMATION" }), intl.formatMessage({ id: 'FILLING.DATE' })
      ]],
      body: [...filterUsers?.map((fu, idx) => {
        return [fu?.id, `${fu?.employee?.firstname} ${fu?.employee?.lastname} ${fu?.employee?.father_name}`, fu?.group?.label, fu?.category?.id == 1 ? 'Kateqoriya 1' : 'Kateqoriya 2', fu?.staff?.label, fu?.rank?.label, fu?.specialty_acc, fu?.fitness_service, fu?.registration_service, moment(fu?.registration_date).format('DD-MM-YYYY'), fu?.general, fu?.special, fu?.no_official, fu?.additional_information, moment(fu?.completion_date).format('DD-MM-YYYY')]
      })]

    });

    doc.save('army-infos.pdf');
  }

  return (
    <div className="d-flex flex-column justify-content-center align-items-center gap-2 mt-2">
      <button
        type="button"
        className="btn btn-light-primary mx-auto w-75"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"

      >
        <CopyToClipboard
          text={copy.text}
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          onCopy={() => {
            setCopy({ ...copy, copied: true });
            toast.success("Copied");
          }}
        >
          <span>{intl.formatMessage({ id: 'COPY' })}</span>
        </CopyToClipboard>
      </button>

      <ReactToPrint
        trigger={() => {
          return <button
            type="button"
            className="btn btn-light-primary mx-auto w-75"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
          >{intl.formatMessage({ id: 'PRINT' })}</button>;
        }}
        content={() => printRef.current}
      />

      <button
        type="button"
        className="btn btn-light-primary mx-auto w-75"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        onClick={handleDownloadExcel}
      >
        <KTIcon iconName="exit-up" className="fs-2" />
        {intl.formatMessage({ id: 'EXCEL' })}
      </button>
      <button
        type="button"
        className="btn btn-light-primary mx-auto w-75"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        onClick={() => {
          setCheck(true);
          setTimeout(() => {
            setCheck(false);
          }, 1000)
        }}
      >
        <KTIcon iconName="exit-up" className="fs-2" />
        CSV
        {check && <CSVDownload data={csvData} separator={";"} enclosingCharacter={``} filename={"militaries.csv"} />}
      </button>
      <button
        type="button"
        className="btn btn-light-primary mx-auto w-75"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        onClick={handleExportPDFTable}
      >
        <KTIcon iconName="exit-up" className="fs-2" />
        PDF
      </button>

      <button
        type="button"
        className="btn btn-light-primary mx-auto w-75"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        onClick={handleColumnVisibilityModal}
      >
        {intl.formatMessage({ id: 'COLUMN.VISIBILITY' })}
      </button>
    </div>
  );
};

export default UsersToolButtons;
