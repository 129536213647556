import { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import ReactToPrint from "react-to-print";
import { downloadExcel } from "react-export-table-to-excel";
import { KTIcon } from "../../../../../../_metronic/helpers";
import { useQueryResponse } from "../core/QueryResponseProvider";
import { CSVDownload } from "react-csv";
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { useIntl } from 'react-intl';

const UsersToolButtons = ({ printRef, setShow }) => {
  const intl = useIntl();
  const { filterUsers } = useQueryResponse();

  const [copy, setCopy] = useState({
    text: "",
    copied: false,
  });

  useEffect(() => {
    setCopy((copy) => ({
      ...copy,
      text: [...filterUsers.map((f) => {
        return [f?.id, `${f?.employee?.firstname} ${f?.employee?.lastname} ${f?.employee?.father_name}`, f?.parent?.parent?.parent?.parent?.label, f?.parent?.parent?.parent?.label, f?.parent?.parent?.label, f?.parent?.label, f?.label]
      })].flat(Infinity).join("->"),
    }));
  }, [filterUsers]);

  const [check, setCheck] = useState(false);

  const handleColumnVisibilityModal = () => {
    setShow(true);
  };

  const csvData = [
    ["ID", intl.formatMessage({ id: "NSF" }), intl.formatMessage({ id: "DIRECTORATE" }), intl.formatMessage({ id: "DEPARTMENT" }), intl.formatMessage({ id: "BRANCHH" }), intl.formatMessage({ id: "AREA.SECTION" }),
      intl.formatMessage({ id: "POSITION" })
    ],
    ...filterUsers.map((f, idx) => {
      return [idx + 1, `${f?.employee?.firstname} ${f?.employee?.lastname} ${f?.employee?.father_name}`, f?.parent?.parent?.parent?.parent?.label, f?.parent?.parent?.parent?.label, f?.parent?.parent?.label, f?.parent?.label, f?.label]
    })
  ];


  const header = ["ID", intl.formatMessage({ id: "NSF" }), intl.formatMessage({ id: "DIRECTORATE" }), intl.formatMessage({ id: "DEPARTMENT" }), intl.formatMessage({ id: "BRANCHH" }), intl.formatMessage({ id: "AREA.SECTION" }),
    intl.formatMessage({ id: "POSITION" })
  ];

  const body = [...filterUsers.map((f, idx) => {
    return [idx + 1, `${f?.employee?.firstname} ${f?.employee?.lastname} ${f?.employee?.father_name}`, f?.parent?.parent?.parent?.parent?.label, f?.parent?.parent?.parent?.label, f?.parent?.parent?.label, f?.parent?.label, f?.label]
  })];

  const handleDownloadExcel = () => {
    downloadExcel({
      fileName: "Worker's about workplace",
      sheet: "Worker's about workplace",
      tablePayload: {
        header,
        body
      }
    })
  }
  const handleExportPDFTable = () => {
    const doc = new jsPDF();

    autoTable(doc, {
      head: [["ID", intl.formatMessage({ id: "NSF" }), intl.formatMessage({ id: "DIRECTORATE" }), intl.formatMessage({ id: "DEPARTMENT" }), intl.formatMessage({ id: "BRANCHH" }), intl.formatMessage({ id: "AREA.SECTION" }),
        intl.formatMessage({ id: "POSITION" })
      ]],
      body: [...filterUsers.map((f, idx) => {
        return [f?.id, `${f?.employee?.firstname} ${f?.employee?.lastname} ${f?.employee?.father_name}`, f?.parent?.parent?.parent?.parent?.label, f?.parent?.parent?.parent?.label, f?.parent?.parent?.label, f?.parent?.label, f?.label]
      })]
    })
    doc.save('about-workplaces.pdf');
  }

  return (
    <div className="d-flex flex-column justify-content-center align-items-center gap-2 mt-2">
      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <CopyToClipboard
          text={copy.text}
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          onCopy={() => {
            setCopy({ ...copy, copied: true });
            toast.success("Copied");
          }}
        >
          <span>{intl.formatMessage({ id: "COPY" })}</span>
        </CopyToClipboard>
      </button>

      <ReactToPrint
        trigger={() => {
          return <button
            type='button'
            className='btn btn-light-primary mx-auto w-75'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >{intl.formatMessage({ id: "PRINT" })}</button>
        }}
        content={() => printRef.current}
      />

      <button
        type="button"
        className="btn btn-light-primary mx-auto w-75"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        onClick={handleDownloadExcel}
      >
        <KTIcon iconName="exit-up" className="fs-2" />
        {intl.formatMessage({ id: "EXCEL" })}
      </button>
      <button
        type="button"
        className="btn btn-light-primary mx-auto w-75"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        onClick={() => {
          setCheck(true);
          setTimeout(() => {
            setCheck(false);
          }, 1000)
        }}
      >
        <KTIcon iconName="exit-up" className="fs-2" />
        CSV
        {check && <CSVDownload data={csvData} filename={"workplaces.csv"} separator={";"} enclosingCharacter={``} />}
      </button>
      <button
        type="button"
        className="btn btn-light-primary mx-auto w-75"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        onClick={handleExportPDFTable}
      >
        <KTIcon iconName="exit-up" className="fs-2" />
        PDF
      </button>

      <button
        type="button"
        className="btn btn-light-primary mx-auto w-75"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        onClick={handleColumnVisibilityModal}
      >
        {intl.formatMessage({ id: "COLUMN.VISIBILITY" })}
      </button>
    </div>
  );
};

export default UsersToolButtons;
