import { ID, Response } from "../../../../../../_metronic/helpers";

export type InOut = {
  id?: ID;
  employee_id?: string | number | null;
  action_type_id?: string | number | null;
  action_date?: string | null;
  action_time?: string | null;
};

export type InOutQueryResponse = Response<Array<InOut>>;

export const initialInOut: InOut = {
  employee_id: null,
  action_type_id: null,
  action_date: null,
  action_time: null,
};
