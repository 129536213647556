import { ID, Response } from "../../../../../../_metronic/helpers";
export type UserCertification = {
  id?: ID;
  employee_id?: string | null;
  training_center_name?: string;
  training_name?: string;
  training_date?: string | null;
  given_date?: string | null;
};

export type UserCertificationQueryResponse = Response<Array<UserCertification>>;

export const initialUserCertification: UserCertification = {
  employee_id: null,
  training_center_name: "",
  training_name: "",
  training_date: null,
  given_date: null,
};
