import clsx from 'clsx';
import { useIntl } from 'react-intl';

const PermissionCustomHeader = ({ className, title, tableProps }) => {

  const intl = useIntl();

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className
      )}
    >
      {title == "ASA" ? intl.formatMessage({ id: "NSF" }) :
        title == "Davamiyyəti izlənmir" ? intl.formatMessage({ id: "ATTENDANCE_MONITORED" }) :
          title == "Aylıq norma izlənir" ? intl.formatMessage({ id: "MONTHYLY_NORM_WATCH" }) :
            title == "Gün içində tolerans" ? intl.formatMessage({ id: "DAILY_TOLERANS" }) :
              title == "Begin date" ? intl.formatMessage({ id: "START.DATEE" }) :
                title == "End date" ? intl.formatMessage({ id: "END.DATE" }) :
                  title == "Actions" ? intl.formatMessage({ id: "ACTIONS" }) :
                    title}
    </th>
  )
}

export { PermissionCustomHeader }
