import { useContext, useState, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
} from "../../../../../../_metronic/helpers";
import { getPermissions } from "./_requests";
import { useQueryRequest } from "./QueryRequestProvider";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

const QueryResponseContext = createResponseContext(initialQueryResponse);
const QueryResponseProvider = ({ children }) => {
  const navigate = useNavigate()
  const { state } = useQueryRequest();
  const [query, setQuery] = useState(state);
  const updatedQuery = useMemo(() => state, [state]);
  const [filterPermissions, setFilterPermissions] = useState([]);


  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);


  const { isFetching, refetch, data } = useQuery(
    "concessions",
    async () => {


      try {
        const pageQuery = "concessions"
        const result = await getPermissions(pageQuery);

        if (result.status === 200) {
          setFilterPermissions(result.data);
          return result.data;
        } else if (result?.response?.status === 401) {
          localStorage.removeItem('token');
          navigate('/auth/login');


        } else {

          throw new Error(result?.response?.data?.message)
        }
      } catch (error) {

        toast.error(error?.message, {
          autoClose: 10000
        });

        return error.message
      }
    },

    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      // onSuccess: (data) => setFilterPermissions(data),
    }
  );

  const searchByUsernName = (searchText) => {
    setFilterPermissions(
      [...data].filter((user) => {
        if (
          user.employee_id?.firstname.toLowerCase().includes(searchText.toLowerCase()) ||
          user.employee_id?.lastname.toLowerCase().includes(searchText.toLowerCase())
        ) {
          return user;
        }
        return false;
      })
    );
  };

  return (
    <QueryResponseContext.Provider
      value={{
        isLoading: isFetching,
        refetch,
        filterPermissions,
        searchByUsernName,
        query,
      }}
    >
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponseData = () => {
  const { filterPermissions } = useQueryResponse();

  if (filterPermissions.length === 0) {
    return [];
  }

  return filterPermissions || [];
};

const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    links: [],
    ...initialQueryState,
  };

  const { filterPermissions } = useQueryResponse();
  if (
    !filterPermissions ||
    !filterPermissions.payload ||
    !filterPermissions.payload.pagination
  ) {
    return defaultPaginationState;
  }

  return filterPermissions.payload.pagination;
};

const useQueryResponseLoading = () => {
  const { isLoading } = useQueryResponse();
  return isLoading;
};

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
};
