import { useState, useEffect, forwardRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { initialUserPreviousJobsInformation } from './components/core/_models'
import { createUserPreviousJobsInformation, updateUserPreviousJobsInformation } from "./components/core/_requests";
import clsx from "clsx";
import InputMask from 'react-input-mask';
import { isNotEmpty } from '../../../../_metronic/helpers';
import { UsersListLoading } from "./components/loading/UsersListLoading"
import { getUserPreviousJobsInformationById } from './components/core/_requests';
import { useQueryResponse } from "./components/core/QueryResponseProvider";
import { useMutation, useQueryClient } from "react-query";
import moment from "moment";
import DatePicker from "react-datepicker";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useIntl } from 'react-intl';

const CreateAndUpdatePage = ({ setShowPage, setShowView, showView }) => {

    const intl = useIntl();

    const { id } = useParams();

    const MaskedInput = forwardRef(({ value, onChange, onClick }, ref) => (
        <div className="ui input" onClick={onClick}>
            <InputMask ref={ref} mask="99/99/9999" placeholder="dd/mm/yyyy" value={value ? value : 'dd/mm/yyyy'} onChange={onChange} />
        </div>
    ));

    const baseUrl = process.env.REACT_APP_BASE_URL;

    const user = useSelector(state => state.persistedReducer.user.user)
    const navigate = useNavigate()
    const { selectId, setSelectId } = useQueryResponse();

    const queryClient = useQueryClient()

    const editUserPreviousJobsInformationSchema = Yup.object().shape({
        employee_id: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

        prev_employer: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

        start_date: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

        end_date: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

        leave_reason: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

        sector: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),
    });
    const [userPreviousJobsForEdit] = useState({
        employee_id: id ? id : initialUserPreviousJobsInformation.employee_id,
        prev_employer: initialUserPreviousJobsInformation.prev_employer,
        start_date: initialUserPreviousJobsInformation.start_date,
        end_date: initialUserPreviousJobsInformation.end_date,
        leave_reason: initialUserPreviousJobsInformation.leave_reason,
        sector: initialUserPreviousJobsInformation.sector,
    });

    const [employees, setEmployees] = useState([])
    const getUserPreviousJobs = useMutation(() => { }, {
        onSuccess: () => queryClient.invalidateQueries(['users-previous-jobs-information'])
    })
    const formik = useFormik({
        initialValues: userPreviousJobsForEdit,
        validationSchema: editUserPreviousJobsInformationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            try {


                if (isNotEmpty(values.id)) {

                    const result = await updateUserPreviousJobsInformation({ ...values, update_user_id: user?.id, start_date: moment(values.start_date).format("YYYY-MM-DD"), end_date: moment(values.end_date).format('YYYY-MM-DD') });

                    if (typeof result == "string") {
                        throw new Error(result)
                    } else {
                        toast.success(`${intl.formatMessage({ id: 'SUCCESS.MESSAGE.UPDATE' })}`);
                        setShowPage(false);
                        getUserPreviousJobs.mutateAsync();
                    }
                } else {
                    const result = await createUserPreviousJobsInformation({ ...values, update_user_id: user?.id, insert_user_id: user?.id, start_date: moment(values.start_date).format("YYYY-MM-DD"), end_date: moment(values.end_date).format('YYYY-MM-DD') });

                    if (typeof result == "string") {
                        throw new Error(result)
                    } else {
                        toast.success(`${intl.formatMessage({ id: 'SUCCESS.MESSAGE.ADD' })}`);
                        setShowPage(false);
                        getUserPreviousJobs.mutateAsync();
                    }
                }
            } catch (ex) {
                toast.error(ex.message)
            } finally {
                setSubmitting(true);
                setSelectId(null);
            }
        },
    });

    const getUserPreviousJobsInfo = async () => {
        const result = await getUserPreviousJobsInformationById(selectId);
        formik.setValues({ ...result, employee_id: result?.employee?.id })
    }


    const getEmployeeByIdPreviosJobsInfos = async (id) => {
        try {
            const request = await axios.get(`${baseUrl}/api/employees/${id}/previous-workplaces`);

            if (request.status == 200) {
                const info = request.data?.find(fI => fI?.id == selectId);

                if (info) {

                    formik.setValues({ ...info, employee_id: info?.employee?.id })
                }
            } else {
                throw new Error(request?.data)
            }
        } catch (error) {

            if (error?.response?.status == 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')
            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }
    const getAllEmployees = async () => {
        try {
            const request = await axios.get(`${baseUrl}/api/employees`);

            if (request.status === 200) {
                setEmployees(request.data)
            } else {
                throw new Error(request.data)
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')
            }
        }
    }

    useEffect(() => {

        if (id) {
            getEmployeeByIdPreviosJobsInfos(id)
        }
        else if (selectId) {
            getUserPreviousJobsInfo();
        }
        getAllEmployees();
    }, [selectId, id]);

    return (

        <div className="card mb-5 mb-xl-10">
            <div
                className="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_account_profile_details"
                aria-expanded="true"
                aria-controls="kt_account_profile_details"
            >
                <div className="card-title m-0">
                    <h3 className="fw-bold m-0">{showView ? intl.formatMessage({ id: 'VIEW.PREVIOUS.JOB' }) : selectId ? intl.formatMessage({ id: 'UPDATE.PREVIOUS.JOB' }) : intl.formatMessage({ id: 'CREATE.PREVIOUS.JOB' })}</h3>
                </div>
            </div>
            <div id="kt_account_settings_profile_details" className="collapse show">
                <form
                    id="kt_account_profile_details_form"
                    className="form fv-plugins-bootstrap5 fv-plugins-framework"
                    onSubmit={formik.handleSubmit}
                    noValidate
                >
                    <div className="card-body border-top p-9">
                        <div className="d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch">
                            {id ? null : <div className="d-flex flex-column gap-2 mb-6 col-lg-3 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'EMPLOYEE' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.employee?.firstname + " " + formik.values?.employee?.lastname}</p> : <select
                                        {...formik.getFieldProps("employee_id")}
                                        className={clsx(
                                            "form-select form-select-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.employee_id && formik.errors.employee_id,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.employee_id && !formik.errors.employee_id,
                                            }
                                        )}
                                        data-kt-select2="true"
                                        data-placeholder="Select "
                                        data-allow-clear="true"
                                        disabled={formik.isSubmitting}
                                    >
                                        <option value="">{intl.formatMessage({ id: 'SELECT' })}</option>
                                        {employees.length > 0 && employees.map(employee => (
                                            <option key={employee?.id} value={employee?.id}>{employee?.firstname + " " + employee?.lastname}</option>
                                        ))}
                                    </select>}

                                    {formik.touched.employee_id && formik.errors.employee_id && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.employee_id}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>}

                            <div className="d-flex flex-column gap-2 mb-6 col-lg-3 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'NAME.OF.EMPLOYER' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.prev_employer}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'NAME.OF.EMPLOYER' })}
                                        {...formik.getFieldProps("prev_employer")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.prev_employer && formik.errors.prev_employer,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.prev_employer && !formik.errors.prev_employer,
                                            }
                                        )}
                                    />}
                                    {formik.touched.prev_employer && formik.errors.prev_employer && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.prev_employer}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-3 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'ACTIVITY.SECTOR' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.sector}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'ACTIVITY.SECTOR' })}
                                        {...formik.getFieldProps("sector")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.sector && formik.errors.sector,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.sector && !formik.errors.sector,
                                            }
                                        )}
                                    />}
                                    {formik.touched.sector && formik.errors.sector && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.sector}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>

                        <div className="d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch">
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-3 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: "DATE.RANGE" })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container d-flex justify-content-between gap-3">
                                    <div className="d-flex flex-column col-lg-5">
                                        {showView ? <p>{moment(formik.values.start_date).format('DD-MM-YYYY')}</p> : <div className="w-100">
                                            <DatePicker
                                                wrapperClassName="datePicker"
                                                dateFormat="dd/MM/yyyy"
                                                placeholder="-- -- --"
                                                customInput={<MaskedInput />}
                                                name="start_date"
                                                selected={formik.values.start_date && new Date(formik.values.start_date)}
                                                onChange={(opt) => formik.setFieldValue("start_date", opt)}
                                                showDayDropdown={true}
                                                showMonthDropdown={true}
                                                showYearDropdown={true}
                                                shouldCloseOnSelect={true}
                                                calendarStartDay={1}
                                                scrollableYearDropdown={false}
                                            />
                                        </div>}

                                        {formik.touched.start_date && formik.errors.start_date && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    <span role="alert">{formik.errors.start_date}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="d-flex flex-column col-lg-5">
                                        {showView ? <p>{moment(formik.values.end_date).format('DD-MM-YYYY')}</p> : <div className="w-100">
                                            <DatePicker
                                                wrapperClassName="datePicker"
                                                dateFormat="dd/MM/yyyy"
                                                customInput={<MaskedInput />}
                                                placeholder="-- -- --"
                                                name="end_date"
                                                selected={formik.values.end_date && new Date(formik.values.end_date)}
                                                onChange={(opt) => formik.setFieldValue("end_date", opt)}
                                                showDayDropdown={true}
                                                showMonthDropdown={true}
                                                showYearDropdown={true}
                                                shouldCloseOnSelect={true}
                                                calendarStartDay={1}
                                                scrollableYearDropdown={false}
                                            />
                                        </div>}

                                        {formik.touched.end_date && formik.errors.end_date && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    <span role="alert">{formik.errors.end_date}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-3 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'REASON.FOR.DISMISSIAL' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.leave_reason}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'REASON.FOR.DISMISSIAL' })}
                                        {...formik.getFieldProps("leave_reason")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.leave_reason && formik.errors.leave_reason,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.leave_reason && !formik.errors.leave_reason,
                                            }
                                        )}
                                    />}
                                    {formik.touched.leave_reason && formik.errors.leave_reason && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.leave_reason}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button
                            type="reset"
                            onClick={() => {
                                setShowPage(false)
                                setSelectId(null);
                                setShowView(false)
                            }}
                            disabled={formik.isSubmitting}
                            className="btn btn-light btn-active-light-primary me-2"
                        >
                            {intl.formatMessage({ id: 'DISCARD' })}
                        </button>
                        {showView == false && <button
                            type="submit"
                            disabled={
                                formik.isSubmitting ||
                                !formik.isValid ||
                                !formik.touched
                            }
                            className="btn btn-primary"
                            id="kt_account_profile_details_submit"
                        >
                            <span className="indicator-label">{formik.isSubmitting ? intl.formatMessage({ id: "PLEASE.WAIT" }) : intl.formatMessage({ id: 'AUTH.GENERAL.SUBMIT_BUTTON' })}</span>
                        </button>}
                    </div>
                </form>
                {(formik.isSubmitting) && <UsersListLoading />}
            </div>
        </div>

    )
}

export default CreateAndUpdatePage

