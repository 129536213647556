import { useState } from "react";
import ViewDocumentModal from "./ViewDocumentModal";

const Content = ({ data, theads }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  return (
    <div className="table-responsive card card-primary">

      {show && <ViewDocumentModal handleClose={handleClose} />}
      <table className="table table-striped table-bordered table-hover">
        <thead>
          <tr>
            {theads.map((th, idx) => (
              <th key={idx}>{th}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data.length === 0 ? (
            <h1 className="p-5">No matching found...</h1>
          ) : (
            data.map((d) => (
              <tr key={d.id}>
                <td>
                  <button className="btn btn-primary" onClick={() => setShow(true)}>Sənədə bax</button>
                </td>
                <td>{d.code}</td>
                <td>{d.firstName}</td>
                <td>{d.surname}</td>
                <td>{d.fatherName}</td>
                <td>{d.position}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Content;
