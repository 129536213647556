import { combineReducers } from '@reduxjs/toolkit'
import selectCompanySlice from './selectCompanySlice'
import userSlice from './userSlice'
import companiesSlice from './companiesSlice'
import selectedLanguage from './selectedLanguage'

const rootReducer = combineReducers({
  selectedCompany: selectCompanySlice,
  user: userSlice,
  selectedLanguage: selectedLanguage,
  companies: companiesSlice
})

export default rootReducer
