import axios, { AxiosResponse } from "axios";
import { ID, Response } from "../../../../../../_metronic/helpers";
import {
  UserWorkExpirenceInformation,
  UserWorkExpirenceInformationQueryResponse,
} from "./_models";

const API_URL = process.env.REACT_APP_BASE_URL;

const USERS_WORK_EXPIRENCE_INFORMATION_URL = `${API_URL}/api/workExperiences`;
const GET_WORK_EXPIRENCE_INFORMATION_URL = `${API_URL}/api/workExperiences`;

const getUsersWorkExpirenceInformations = (
  query: string
): Promise<UserWorkExpirenceInformationQueryResponse> => {
  return axios
    .get(`${GET_WORK_EXPIRENCE_INFORMATION_URL}?${query}`)
    .then((d: AxiosResponse<UserWorkExpirenceInformationQueryResponse>) => {
      return d;
    })
    .catch(err => err)
};

const getUserWorkExpirenceInformationById = (
  id: ID
): Promise<UserWorkExpirenceInformation | undefined> => {
  return axios
    .get(`${USERS_WORK_EXPIRENCE_INFORMATION_URL}/${id}`)
    .then((response) => {
      return response.data;
    });
};

const createUserWorkExpirenceInformation = (
  user: UserWorkExpirenceInformation
): Promise<UserWorkExpirenceInformation | undefined> => {
  return axios
    .post(`${API_URL}/api/workExperiences`, user)
    .then(
      (response: AxiosResponse<Response<UserWorkExpirenceInformation>>) =>
        response.data
    )
    .catch(err => err?.response?.data?.message)
};

const updateUserWorkExpirenceInformation = (
  user: UserWorkExpirenceInformation
): Promise<UserWorkExpirenceInformation | undefined> => {
  return axios
    .put(`${USERS_WORK_EXPIRENCE_INFORMATION_URL}/${user.id}`, user)
    .then(
      (response: AxiosResponse<Response<UserWorkExpirenceInformation>>) => { }
    )
    .catch(err => err?.response?.data?.message)
};

const deleteUserWorkExpirenceInformation = (userId: ID): Promise<void> => {
  return axios
    .delete(`${USERS_WORK_EXPIRENCE_INFORMATION_URL}/${userId}`)
    .then(() => { });
};

const deleteUsersWorkExpirenceInformations = (
  userIds: Array<ID>
): Promise<void> => {
  const requests = userIds.map((id) =>
    axios.delete(`${USERS_WORK_EXPIRENCE_INFORMATION_URL}/${id}`)
  );
  return axios.all(requests).then(() => { });
};

export {
  getUsersWorkExpirenceInformations,
  getUserWorkExpirenceInformationById,
  createUserWorkExpirenceInformation,
  updateUserWorkExpirenceInformation,
  deleteUserWorkExpirenceInformation,
  deleteUsersWorkExpirenceInformations,
};
