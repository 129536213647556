import { useMemo, useCallback, forwardRef } from 'react'
import DatePicker from "react-datepicker";
import moment from "moment";
import CaretUp from "../../../../../_metronic/assets/images/CaretUp.svg";


const DateRange = ({ beginDate, setBeginDate, expiryDate, setExpiryDate }) => {

    const MyCustomCalendarIcon = useCallback(
        ({ onClick }) => (
            <button
                onClick={onClick}
                style={{
                    outline: "none",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                }}
                className="d-flex justify-content-center align-items-center"
            >
                <img src={CaretUp} alt="arrow down" />
            </button>
        ),
        []
    );

    const CustomInputBeginTime = useMemo(
        () =>
            forwardRef(({ value, onClick }, ref) => (
                <div onClick={onClick} className="new-custom-input">
                    <p onClick={onClick} style={{ color: "#000" }}>
                        {beginDate != null
                            ? moment(beginDate).format("DD-MM-YYYY")
                            : "Select begin time"}
                    </p>
                    <MyCustomCalendarIcon onClick={onClick} />
                </div>
            )),
        [beginDate]
    );

    const CustomInputExpiryTime = useMemo(
        () =>
            forwardRef(({ value, onClick }, ref) => (
                <div onClick={onClick} className="new-custom-input">
                    <p onClick={onClick} style={{ color: "#000" }}>
                        {expiryDate != null
                            ? moment(expiryDate).format("DD-MM-YYYY")
                            : "Select expiry time"}
                    </p>
                    <MyCustomCalendarIcon onClick={onClick} />
                </div>
            )),
        [expiryDate]
    );

    return (
        <div className='d-flex flex-column gap-4'>
            <label className="col-form-label">Tarix Aralığı</label>
            <div className="w-100">
                <DatePicker
                    wrapperClassName="datePicker"
                    dateFormat="yyy-mm-dd"
                    placeholder="-- -- --"
                    selected={beginDate}
                    customInput={<CustomInputBeginTime />}
                    onChange={(opt) => setBeginDate(opt)}
                    showYearDropdown
                    yearDropdownItemNumber={50}
                    calendarStartDay={1}
                    scrollableYearDropdown
                    shouldCloseOnSelect={true}
                />
            </div>
            <div className="w-100">
                <DatePicker
                    wrapperClassName="datePicker"
                    dateFormat="yyy-mm-dd"
                    placeholder="-- -- --"
                    selected={expiryDate}
                    customInput={<CustomInputExpiryTime />}
                    onChange={(opt) => setExpiryDate(opt)}
                    showYearDropdown
                    yearDropdownItemNumber={50}
                    calendarStartDay={1}
                    scrollableYearDropdown
                    shouldCloseOnSelect={true}
                />
            </div>
        </div>
    )
}

export default DateRange
