import clsx from 'clsx';
import { useIntl } from 'react-intl';

const PermissionCustomHeader = ({ className, title, tableProps }) => {

  const intl = useIntl();

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className
      )}
    >
      {title == "Tipi" ? intl.formatMessage({ id: "TYPE" }) :
        title == "Tarix" ? intl.formatMessage({ id: "DATE" }) :
          title == "Saat" ? intl.formatMessage({ id: "TIME" }) :
            title == "Actions" ? intl.formatMessage({ id: "ACTIONS" }) :
              title == "ASA" ? intl.formatMessage({ id: "NSF" }) :
                title}
    </th>
  )
}

export { PermissionCustomHeader }
