import { useState, useEffect, forwardRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputMask from 'react-input-mask';
import { initialFamilyInfo } from './components/core/_models'
import { createFamilyInfo, updateFamilyInfo } from "./components/core/_requests";
import clsx from "clsx";
import { isNotEmpty } from '../../../../_metronic/helpers';
import { UsersListLoading } from "./components/loading/UsersListLoading"
import { getFamilyInfoByID } from './components/core/_requests';
import DatePicker from "react-datepicker";
import moment from "moment";
import { useQueryResponse } from "./components/core/QueryResponseProvider";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useIntl } from "react-intl";

const CreateAndUpdatePage = ({ setShowPage, setShowView, showView }) => {

    const intl = useIntl();

    const { id } = useParams();

    const phoneRegExp = /^\(0\d{2}\)-\d{3}-\d{2}-\d{2}$/;

    const MaskedInput = forwardRef(({ value, onChange, onClick }, ref) => (
        <div className="ui input" onClick={onClick}>
            <InputMask ref={ref} mask="99/99/9999" placeholder="dd/mm/yyyy" value={value ? value : 'dd/mm/yyyy'} onChange={onChange} />
        </div>
    ));
    const editFamilySchema = Yup.object().shape({
        employee_id: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

        member_type_id: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

        m_firstname: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

        m_lastname: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

        m_surname: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

        gender_id: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        birth_date: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),


        contact_number: Yup.string().matches(phoneRegExp, `${intl.formatMessage({ id: "PHONE_NOT_VALID" })}`)
            .required(`${intl.formatMessage({ id: "REQUIRED" })}`),


        address: Yup.string()
            .required(`${intl.formatMessage({ id: "REQUIRED" })}`),
    });
    const navigate = useNavigate();

    const { selectId, setSelectId } = useQueryResponse();

    const queryClient = useQueryClient()

    const baseUrl = process.env.REACT_APP_BASE_URL;

    const [familyForEdit] = useState({
        employee_id: id ? id : initialFamilyInfo.employee_id,
        m_firstname: initialFamilyInfo.m_firstname,
        m_lastname: initialFamilyInfo.m_lastname,
        member_type_id: initialFamilyInfo.member_type_id,
        m_surname: initialFamilyInfo.m_surname,
        gender_id: initialFamilyInfo.gender_id,
        birth_date: initialFamilyInfo.birth_date,
        contact_number: initialFamilyInfo.contact_number,
        address: initialFamilyInfo.address,
    });

    const getFamilies = useMutation(() => { }, {
        onSuccess: () => queryClient.invalidateQueries(['familyinfo'])
    });

    const [employees, setEmployees] = useState([])

    const [sexes, setSexes] = useState([]);
    const [familyMemberTypes, setFamilyMemberTypes] = useState([])
    const formik = useFormik({
        initialValues: familyForEdit,
        validationSchema: editFamilySchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            try {
                if (isNotEmpty(values.id)) {
                    const result = await updateFamilyInfo({ ...values, birth_date: moment(values.birth_date).format('YYYY-MM-DD') });

                    if (typeof result === 'string') {
                        throw new Error(result)
                    } else {
                        toast.success(`${intl.formatMessage({ id: 'SUCCESS.MESSAGE.UPDATE' })}`)
                        setShowPage(false);
                        getFamilies.mutateAsync();
                    }
                } else {
                    const result = await createFamilyInfo({ ...values, birth_date: moment(values.birth_date).format('YYYY-MM-DD') });

                    if (typeof result == "string") {
                        throw new Error(result)
                    } else {
                        toast.success(`${intl.formatMessage({ id: 'SUCESSS.MESSAGE.ADD' })}`)
                        setShowPage(false);
                        getFamilies.mutateAsync();
                    }
                }
            } catch (ex) {
                toast.error(ex?.message, {
                    autoClose: 10000
                })
            } finally {
                setSubmitting(true);
                setSelectId(null);
            }
        },
    });

    const getFamilyInfo = async () => {
        const result = await getFamilyInfoByID(selectId);
        formik.setValues({ ...result, gender_id: result?.sex?.id, member_type_id: result?.family_member_type?.id, employee_id: result?.employee?.id })
    }

    const getEmployeeByIdFamilyInfos = async (id) => {
        try {
            const request = await axios.get(`${baseUrl}/api/employees/${id}/family-infos`);

            if (request.status == 200) {
                const familyInfo = request.data?.find(fI => fI?.id == selectId);
                if (familyInfo) {

                    formik.setValues({ ...familyInfo, gender_id: familyInfo?.sex?.id, member_type_id: familyInfo?.family_member_type?.id, employee_id: familyInfo?.employee?.id })
                }
            } else {
                throw new Error(request?.data)
            }
        } catch (error) {
            if (error?.response?.status == 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')
            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }


    const getAllEmployees = async () => {
        try {
            const request = await axios.get(`${baseUrl}/api/employees`);

            if (request.status === 200) {
                setEmployees(request.data)
            } else {
                throw new Error(request.data)
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')
            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }

    const getAllSexes = async () => {
        try {
            const request = await axios.get(`${baseUrl}/api/sexes`);

            if (request.status === 200) {
                setSexes(request.data)
            } else {
                throw new Error(request.data)
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token')
                navigate('/auth/login')
            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }

    const getAllFamilyMemberTypes = async () => {
        try {
            const request = await axios.get(`${baseUrl}/api/family-member-types`);

            if (request.status === 200) {
                setFamilyMemberTypes(request?.data)
            } else {
                throw new Error(request.data)
            }
        } catch (error) {

            if (error?.response?.status == 401) {
                localStorage.removeItem('token')
                navigate('/auth/login')
            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }

    useEffect(() => {
        if (id) {
            getEmployeeByIdFamilyInfos(id)

        } else if (selectId) {
            getFamilyInfo();
        }

        getAllEmployees();
        getAllSexes();
        getAllFamilyMemberTypes()

    }, [selectId, id]);



    return (

        <div className="card mb-5 mb-xl-10">
            <div
                className="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_account_profile_details"
                aria-expanded="true"
                aria-controls="kt_account_profile_details"
            >
                <div className="card-title m-0">
                    <h3 className="fw-bold m-0">{showView ? intl.formatMessage({ id: 'VIEW.FAMILY.INFO' }) : selectId ? intl.formatMessage({ id: 'UPDATE.FAMILY.INFO' }) : intl.formatMessage({ id: 'CREATE.FAMILY.INFO' })}</h3>
                </div>
            </div>
            <div id="kt_account_settings_profile_details" className="collapse show">
                <form
                    id="kt_account_profile_details_form"
                    className="form fv-plugins-bootstrap5 fv-plugins-framework"
                    onSubmit={formik.handleSubmit}
                    noValidate
                >
                    <div className="card-body border-top p-9">
                        <div className="d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch">
                            {id ? null : <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'EMPLOYEE' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values.employee?.firstname + " " + formik.values?.employee?.lastname}</p> : <select
                                        {...formik.getFieldProps("employee_id")}
                                        className={clsx(
                                            "form-select form-select-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.employee_id && formik.errors.employee_id,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.employee_id && !formik.errors.employee_id,
                                            }
                                        )}
                                        data-kt-select2="true"
                                        data-placeholder="Select "
                                        data-allow-clear="true"
                                        disabled={formik.isSubmitting}
                                    >
                                        <option value="">{intl.formatMessage({ id: 'SELECT' })}</option>

                                        {employees.length > 0 && employees.map(employee => (
                                            <option key={employee?.id} value={employee?.id}>{employee?.firstname + " " + employee?.lastname}</option>
                                        ))}
                                    </select>}

                                    {formik.touched.employee_id && formik.errors.employee_id && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.employee_id}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>}
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'TYPE.OF.FAMILY.MEMBER' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.family_member_type?.label}</p> : <select
                                        {...formik.getFieldProps("member_type_id")}
                                        className={clsx(
                                            "form-select form-select-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.member_type_id && formik.errors.member_type_id,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.member_type_id && !formik.errors.member_type_id,
                                            }
                                        )}
                                        data-kt-select2="true"
                                        data-allow-clear="true"
                                        disabled={formik.isSubmitting}
                                    >
                                        <option value="">{intl.formatMessage({ id: 'SELECT' })}</option>
                                        {familyMemberTypes.length > 0 && familyMemberTypes.map(fm => (
                                            <option key={fm?.id} value={fm?.id}>{fm?.label}</option>
                                        ))}

                                    </select>}

                                    {formik.touched.member_type_id && formik.errors.member_type_id && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.member_type_id}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'NAME' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.m_firstname}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'NAME' })}
                                        {...formik.getFieldProps("m_firstname")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.m_firstname && formik.errors.m_firstname,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.m_firstname && !formik.errors.m_firstname,
                                            }
                                        )}
                                    />}
                                    {formik.touched.m_firstname && formik.errors.m_firstname && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.m_firstname}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'SURNAME' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values.m_lastname}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'SURNAME' })}
                                        {...formik.getFieldProps("m_lastname")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.m_lastname && formik.errors.m_lastname,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.m_lastname && !formik.errors.m_lastname,
                                            }
                                        )}
                                    />}
                                    {formik.touched.m_lastname && formik.errors.m_lastname && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.m_lastname}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {id && <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'FATHER.NAME' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values.m_surname}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'FATHER.NAME' })}
                                        {...formik.getFieldProps("m_surname")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.m_surname && formik.errors.m_surname,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.m_surname && !formik.errors.m_surname,
                                            }
                                        )}
                                    />}
                                    {formik.touched.m_surname && formik.errors.m_surname && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.m_surname}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>}

                        </div>
                        <div className="d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch">
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'GENDER' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.sex?.label}</p> : <select
                                        {...formik.getFieldProps("gender_id")}
                                        className={clsx(
                                            "form-select form-select-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.gender_id && formik.errors.gender_id,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.gender_id && !formik.errors.gender_id,
                                            }
                                        )}
                                        data-kt-select2="true"
                                        data-placeholder="Select "
                                        data-allow-clear="true"
                                        disabled={formik.isSubmitting}
                                    >
                                        <option value="">{intl.formatMessage({ id: 'SELECT' })}</option>
                                        {sexes.length > 0 && sexes.map(sex => (
                                            <option key={sex?.id} value={sex?.id}>{sex?.label}</option>
                                        ))}

                                    </select>}

                                    {formik.touched.gender_id && formik.errors.gender_id && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.gender_id}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'DATE.OF.BIRTH' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{moment(formik.values.birth_date).format('DD-MM-YYYY')}</p> : <div className="w-100">
                                        <DatePicker
                                            wrapperClassName="datePicker"
                                            dateFormat="dd/MM/yyyy"
                                            placeholder="-- -- --"
                                            customInput={<MaskedInput />}
                                            name="theDateOfIssuanceOfThePermit"
                                            selected={formik.values.birth_date && new Date(formik.values.birth_date)}
                                            onChange={(opt) =>
                                                formik.setFieldValue("birth_date", opt)
                                            }
                                            showYearDropdown
                                            yearDropdownItemNumber={50}
                                            calendarStartDay={1}
                                            scrollableYearDropdown
                                            shouldCloseOnSelect={true}
                                        />
                                    </div>}

                                    {formik.touched.birth_date &&
                                        formik.errors.birth_date && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    <span role="alert">
                                                        {formik.errors.birth_date}
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            </div>

                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'CONTACT.NUMBER' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values.contact_number}</p> : <InputMask mask="(099)-999-99-99"
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'CONTACT.NUMBER' })}
                                        {...formik.getFieldProps("contact_number")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.contact_number && formik.errors.contact_number,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.contact_number && !formik.errors.contact_number,
                                            }
                                        )}
                                    />}
                                    {formik.touched.contact_number && formik.errors.contact_number && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.contact_number}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'ADDRESS' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values.address}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'ADDRESS' })}
                                        {...formik.getFieldProps("address")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid": formik.touched.address && formik.errors.address,
                                            },
                                            {
                                                "is-valid": formik.touched.address && !formik.errors.address,
                                            }
                                        )}
                                    />}
                                    {formik.touched.address && formik.errors.address && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.address}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {id == null && <div className="d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch">
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'FATHER.NAME' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values.m_surname}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'FATHER.NAME' })}
                                        {...formik.getFieldProps("m_surname")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.m_surname && formik.errors.m_surname,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.m_surname && !formik.errors.m_surname,
                                            }
                                        )}
                                    />}
                                    {formik.touched.m_surname && formik.errors.m_surname && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.m_surname}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>}
                    </div>
                    <div className="card-footer d-flex gap-2 justify-content-end py-6 px-9">

                        <button
                            type="reset"
                            onClick={() => {
                                setShowPage(false)
                                setSelectId(null)
                                setShowView(false)
                            }}
                            disabled={formik.isSubmitting}
                            className="btn btn-light btn-active-light-primary me-2"
                        >
                            {intl.formatMessage({ id: 'DISCARD' })}
                        </button>

                        {showView == false && <button
                            type="submit"
                            disabled={
                                formik.isSubmitting ||
                                !formik.isValid ||
                                !formik.touched
                            }
                            className="btn btn-primary"
                            id="kt_account_profile_details_submit"
                        >
                            <span className="indicator-label">{formik.isSubmitting ? intl.formatMessage({ id: "PLEASE.WAIT" }) : intl.formatMessage({ id: 'AUTH.GENERAL.SUBMIT_BUTTON' })}</span>
                        </button>}
                    </div>
                </form>
                {(formik.isSubmitting) && <UsersListLoading />}
            </div>
        </div>


    )
}

export default CreateAndUpdatePage

