import clsx from 'clsx'
import { useIntl } from 'react-intl';

const UserCustomHeader = ({ className, title, tableProps }) => {
  const intl = useIntl();

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className
      )}
      style={{ cursor: 'pointer' }}
    >
      {title == "Actions" ? intl.formatMessage({ id: 'ACTIONS' }) :
        title == "ASA" ? intl.formatMessage({ id: 'NSF' }) :
          title == "Dil" ? intl.formatMessage({ id: "LANGUAGE" }) :
            title == "Oxuma" ? intl.formatMessage({ id: 'READING' }) :
              title == "Yazma" ? intl.formatMessage({ id: 'WRITING' }) :
                title == "Danışmaq" ? intl.formatMessage({ id: "SPEAKING" }) :
                  title == "Başa düşmə" ? intl.formatMessage({ id: 'UNDERSTADING' }) :
                    title}
    </th>
  )
}

export { UserCustomHeader }
