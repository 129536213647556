import { PageTitle } from "../../../_metronic/layout/core";

const CalendarPage = () => {
    
  const breadcrumb = [
    {
      title: "Calendar",
      path: "/calendar",
      isSeparator: false,
      isActive: false,
    },
  ];

  return (
    <div>
      <PageTitle breadcrumbs={breadcrumb}>Calendar</PageTitle>
    </div>
  );
};

export default CalendarPage;
