import {useListView} from '../../core/ListViewProvider'
import {InOutToolbar} from './InOutToolbar'
import {InOutGrouping} from './InOutGrouping'
import {InOutSearchComponent} from './InOutSearchComponent'

const InOutHeader = ({printRef, setShow}) => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <InOutSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <InOutGrouping /> : <InOutToolbar printRef={printRef} setShow={setShow} />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {InOutHeader}
