import { Suspense, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { AuthInit } from './modules/auth'
import { ThemeModeProvider } from '../_metronic/partials'
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedLanguage } from '../redux-store/slices/selectedLanguage'
import axios from 'axios';

export function setupAxios(axios, lng, comp) {

  axios.defaults.headers.Accept = "application/json";
  axios.defaults.headers["Accept-Company"] = comp
  axios.defaults.headers["Accept-Language"] = lng
  axios.interceptors.request.use(
    (config) => {
      const auth = localStorage.getItem("token")
        ? `Bearer ${localStorage.getItem("token")}`
        : null;
      if (auth) {
        config.headers.Authorization = `Bearer ${auth}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );

}

const App = () => {
  const dispatch = useDispatch();

  const selectedLanguage = useSelector(state => state.persistedReducer.selectedLanguage.selectedLanguage);
  const selectedCompany = useSelector(state => state.persistedReducer.selectedCompany.selectedCompany)

  useEffect(() => {
    if (selectedLanguage == null) {
      dispatch(setSelectedLanguage({ name: 'az' }))
    }
  }, []);

  setupAxios(axios, selectedLanguage?.name, selectedCompany)


  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <ThemeModeProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </ThemeModeProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export { App }
