import { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import ReactToPrint from "react-to-print";
import { downloadExcel } from "react-export-table-to-excel";
import { KTIcon } from "../../../../../../_metronic/helpers";
import { useQueryResponse } from "../core/QueryResponseProvider";
import { CSVDownload } from "react-csv";
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import moment from "moment";
import { useIntl } from 'react-intl';

const UsersToolButtons = ({ printRef, setShow }) => {
  const intl = useIntl();
  const { filterUsers } = useQueryResponse();

  const [copy, setCopy] = useState({
    text: "",
    copied: false,
  });

  const [check, setCheck] = useState(false);

  useEffect(() => {
    setCopy((copy) => ({
      ...copy,
      text: [...filterUsers.map((fu) => {
        return [fu?.id, `${fu?.employee?.firstname} ${fu?.employee?.lastname} ${fu?.employee?.father_name}`, fu?.trp_serial_number, fu?.trp_permit_reason, moment(fu?.trp_permit_date).format('DD-MM-YYYY'), moment(fu?.trp_valid_date).format('DD-MM-YYYY'), fu?.trp_issuer, fu?.prp_serial_number, moment(fu?.prp_permit_date).format('DD-MM-YYYY'), moment(fu?.prp_valid_date).format('DD-MM-YYYY'), fu?.prp_issuer, fu?.wp_serial_number, moment(fu?.wp_permit_date).format('DD-MM-YYYY'), moment(fu?.wp_valid_date).format('DD-MM-YYYY')]
      })].flat(Infinity).join("->"),
    }));
  }, [filterUsers]);

  const handleColumnVisibilityModal = () => {
    setShow(true);
  };

  const csvData = [
    ["ID", intl.formatMessage({ id: "NSF" }), intl.formatMessage({ id: 'VOUCHER.NUMBER.SERIES' }),
      intl.formatMessage({ id: "BASIS.OF.DELIVERY" }), intl.formatMessage({ id: "DATE.OF.ISSUANCE.OF.PERMIT" }),
      intl.formatMessage({ id: "PERIOD.VALIDITY" }), intl.formatMessage({ id: "NAME.OF.ISSUING.AUTHORITY" }),
      intl.formatMessage({ id: 'VOUCHER.NUMBER.SERIES' }), intl.formatMessage({ id: "DATE.OF.ISSUEE" }),
      intl.formatMessage({ id: "PERIOD.VALIDITY" }), intl.formatMessage({ id: "NAME.OF.ISSUING.AUTHORITY" }),
      intl.formatMessage({ id: 'VOUCHER.NUMBER.SERIES' }), intl.formatMessage({ id: "DATE.OF.ISSUEE" }), intl.formatMessage({ id: "PERIOD.VALIDITY" })
    ],
    ...filterUsers?.map(fu => {
      return [fu?.id, `${fu?.employee?.firstname} ${fu?.employee?.lastname} ${fu?.employee?.father_name}`, fu?.trp_serial_number, fu?.trp_permit_reason, moment(fu?.trp_permit_date).format('DD-MM-YYYY'), moment(fu?.trp_valid_date).format('DD-MM-YYYY'), fu?.trp_issuer, fu?.prp_serial_number, moment(fu?.prp_permit_date).format('DD-MM-YYYY'), moment(fu?.prp_valid_date).format('DD-MM-YYYY'), fu?.prp_issuer, fu?.wp_serial_number, moment(fu?.wp_permit_date).format('DD-MM-YYYY'), moment(fu?.wp_valid_date).format('DD-MM-YYYY')]
    })
  ];

  const header = ["ID", intl.formatMessage({ id: "NSF" }), intl.formatMessage({ id: 'VOUCHER.NUMBER.SERIES' }),
    intl.formatMessage({ id: "BASIS.OF.DELIVERY" }), intl.formatMessage({ id: "DATE.OF.ISSUANCE.OF.PERMIT" }),
    intl.formatMessage({ id: "PERIOD.VALIDITY" }), intl.formatMessage({ id: "NAME.OF.ISSUING.AUTHORITY" }),
    intl.formatMessage({ id: 'VOUCHER.NUMBER.SERIES' }), intl.formatMessage({ id: "DATE.OF.ISSUEE" }),
    intl.formatMessage({ id: "PERIOD.VALIDITY" }), intl.formatMessage({ id: "NAME.OF.ISSUING.AUTHORITY" }),
    intl.formatMessage({ id: 'VOUCHER.NUMBER.SERIES' }), intl.formatMessage({ id: "DATE.OF.ISSUEE" }), intl.formatMessage({ id: "PERIOD.VALIDITY" })
  ];

  const body = [...filterUsers?.map(fu => {
    return [fu?.id, `${fu?.employee?.firstname} ${fu?.employee?.lastname} ${fu?.employee?.father_name}`, fu?.trp_serial_number, fu?.trp_permit_reason, moment(fu?.trp_permit_date).format('DD-MM-YYYY'), moment(fu?.trp_valid_date).format('DD-MM-YYYY'), fu?.trp_issuer, fu?.prp_serial_number, moment(fu?.prp_permit_date).format('DD-MM-YYYY'), moment(fu?.prp_valid_date).format('DD-MM-YYYY'), fu?.prp_issuer, fu?.wp_serial_number, moment(fu?.wp_permit_date).format('DD-MM-YYYY'), moment(fu?.wp_valid_date).format('DD-MM-YYYY')]
  })];

  const handleExportExcel = () => {
    downloadExcel({
      fileName: "Migration Information",
      sheet: "Migration Information",
      tablePayload: {
        header,
        body
      }
    })
  }

  const handleExportPDFTable = () => {
    const doc = new jsPDF({
      format: 'a2',
      orientation: 'landscape'
    });

    autoTable(doc, {
      head: [["ID", intl.formatMessage({ id: "NSF" }), intl.formatMessage({ id: 'VOUCHER.NUMBER.SERIES' }),
        intl.formatMessage({ id: "BASIS.OF.DELIVERY" }), intl.formatMessage({ id: "DATE.OF.ISSUANCE.OF.PERMIT" }),
        intl.formatMessage({ id: "PERIOD.VALIDITY" }), intl.formatMessage({ id: "NAME.OF.ISSUING.AUTHORITY" }),
        intl.formatMessage({ id: 'VOUCHER.NUMBER.SERIES' }), intl.formatMessage({ id: "DATE.OF.ISSUEE" }),
        intl.formatMessage({ id: "PERIOD.VALIDITY" }), intl.formatMessage({ id: "NAME.OF.ISSUING.AUTHORITY" }),
        intl.formatMessage({ id: 'VOUCHER.NUMBER.SERIES' }), intl.formatMessage({ id: "DATE.OF.ISSUEE" }), intl.formatMessage({ id: "PERIOD.VALIDITY" })
      ]],
      body: [...filterUsers?.map(fu => {
        return [fu?.id, `${fu?.employee?.firstname} ${fu?.employee?.lastname} ${fu?.employee?.father_name}`, fu?.trp_serial_number, fu?.trp_permit_reason, moment(fu?.trp_permit_date).format('DD-MM-YYYY'), moment(fu?.trp_valid_date).format('DD-MM-YYYY'), fu?.trp_issuer, fu?.prp_serial_number, moment(fu?.prp_permit_date).format('DD-MM-YYYY'), moment(fu?.prp_valid_date).format('DD-MM-YYYY'), fu?.prp_issuer, fu?.wp_serial_number, moment(fu?.wp_permit_date).format('DD-MM-YYYY'), moment(fu?.wp_valid_date).format('DD-MM-YYYY')]
      })]
    })

    doc.save('migration-information-infos.pdf');
  }

  return (
    <div className="d-flex flex-column justify-content-center align-items-center gap-2 mt-2">
      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <CopyToClipboard
          text={copy.text}
          style={{display:'flex', justifyContent:'center', width:'100%'}}
          onCopy={() => {
            setCopy({ ...copy, copied: true })
            toast.success('Copied')
          }}
        >
          <span>{intl.formatMessage({ id: "COPY" })}</span>
        </CopyToClipboard>
      </button>

      <ReactToPrint
        trigger={() => {
          return <button
            type="button"
            className="btn btn-light-primary mx-auto w-75"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
          >{intl.formatMessage({ id: 'PRINT' })}</button>;
        }}
        content={() => printRef.current}
      />

      <button
        type="button"
        className="btn btn-light-primary mx-auto w-75"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        onClick={handleExportExcel}
      >
        <KTIcon iconName="exit-up" className="fs-2" />
        {intl.formatMessage({ id: "EXCEL" })}
      </button>
      <button
        type="button"
        className="btn btn-light-primary mx-auto w-75"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        onClick={() => {
          setCheck(true);
          setTimeout(() => {
            setCheck(false);
          }, 1000)
        }}
      >
        <KTIcon iconName="exit-up" className="fs-2" />
        CSV
        {check && <CSVDownload data={csvData} separator={";"} enclosingCharacter={``} />}
      </button>
      <button
        type="button"
        className="btn btn-light-primary mx-auto w-75"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        onClick={handleExportPDFTable}
      >
        <KTIcon iconName="exit-up" className="fs-2" />
        PDF
      </button>

      <button
        type="button"
        className="btn btn-light-primary mx-auto w-75"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        onClick={handleColumnVisibilityModal}
      >
        {intl.formatMessage({ id: "COLUMN.VISIBILITY" })}
      </button>

    </div>
  );
};

export default UsersToolButtons;
