import Select from "react-select";
import { customStyles } from "../../../../../utilities/customStyles";
import { Form } from "react-bootstrap";

const Header = ({
  selectCompany,
  handleSearchByField,
  selectUser,
  selectPosition,
  codeInput,
  setCodeInput,
  setSelectCompany,
  setSelectPosition,
  setSelectUser,
  users,
  companies,
  positions,
  setShowTabel,
}) => {
  return (
    <div className="card card-primary p-5">
      <div className="filter_content d-flex flex-wrap justify-content-between">
        <div className="workplace_filter_content d-flex flex-column flex-grow-1">
          <label className="col-form-label">İş yeri</label>
          <Select
            options={companies}
            placeholder="Select"
            value={selectCompany}
            onChange={(opt) => {
              setSelectCompany(opt);
              setShowTabel(true);
              handleSearchByField(opt.label);
            }}
            styles={customStyles}
            className="w-75"
          />
        </div>
        <div className="code_filter_content d-flex flex-column flex-grow-1">
          <label className="col-form-label">Kod</label>
          <Form.Control
            value={codeInput}
            className="w-75"
            placeholder="Type Code"
            onChange={(e) => {
              setCodeInput(e.target.value);
              setShowTabel(true);
              handleSearchByField(e.target.value);
            }}
          />
        </div>
        <div className="user_filter_content d-flex flex-column flex-grow-1">
          <label className="col-form-label">İşçi</label>
          <Select
            options={users}
            placeholder="Select"
            value={selectUser}
            onChange={(opt) => {
              setSelectUser(opt);
              setShowTabel(true);
              handleSearchByField(opt.label);
            }}
            styles={customStyles}
            className="w-75"
          />
        </div>

        <div className="position_content d-flex flex-column flex-grow-1">
          <label className="col-form-label">Vəzifəsi</label>
          <Select
            options={positions}
            placeholder="Select"
            value={selectPosition}
            onChange={(opt) => {
              setSelectPosition(opt);
              setShowTabel(true);
              handleSearchByField(opt.label);
            }}
            styles={customStyles}
            className="w-75"
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
