// @ts-nocheck
import { UserInfoCell } from "./UserInfoCell";
import { UserActionsCell } from "./UserActionsCell";
import { UserCustomHeader } from "./UserCustomHeader";
import moment from "moment";

const usersColumns = [
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title="ID" className="min-w-125px" />
    ),
    id: 1,
    accessor: "id",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.id} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ASA"
        className="min-w-125px"
      />
    ),
    id: 2,
    accessor: "user",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.employee?.firstname + " " + props.data[props.row.index]?.employee?.lastname} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Tədris mərkəzinin adı"
        className="min-w-125px"
      />
    ),
    id: 3,
    accessor: "theNameOfTheTrainingCenter",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.training_center_name} />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Tədrisin adı/mövzusu"
        className="min-w-125px"
      />
    ),
    id: 5,
    accessor: "training_name",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index].training_name} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Tədrisin tarixi"
        className="min-w-125px"
      />
    ),
    id: 6,
    accessor: "historyOfTeaching",
    Cell: ({ ...props }) => (
      <UserInfoCell user={moment(props.data[props.row.index].training_date).format("DD-MM-YYYY")} />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Sertifikatın verilmə tarixi"
        className="min-w-125px"
      />
    ),
    id: 7,
    accessor: "given_date",
    Cell: ({ ...props }) => (
      <UserInfoCell
        user={moment(props.data[props.row.index].given_date).format('DD-MM-YYYY')}
      />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Actions"
        className="text-end min-w-100px"
      />
    ),
    id: 8,
    accessor: "actions",
    Cell: ({ ...props }) => (
      <UserActionsCell id={props.data[props.row.index].id} />
    ),
  },
];

export { usersColumns };
