// @ts-nocheck
import { UserInfoCell } from "./UserInfoCell";
import { UserActionsCell } from "./UserActionsCell";
import { UserCustomHeader } from "./UserCustomHeader";

const usersColumns = [
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title="ID" className="min-w-125px" />
    ),
    id: 1,
    accessor: "id",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.id} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ASA"
        className="min-w-125px"
      />
    ),
    id: 2,
    accessor: "user",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.employee?.firstname + " " + props.data[props.row.index]?.employee?.lastname } />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Bacarığın adı"
        className="min-w-125px"
      />
    ),
    id: 3,
    accessor: "skill_name",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.skill_name} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Bacarığın açıqlaması"
        className="min-w-125px"
      />
    ),
    id: 4,
    accessor: "skill_description",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index].skill_description} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Actions"
        className="text-end min-w-100px"
      />
    ),
    id: 5,
    accessor: "actions",
    Cell: ({ ...props }) => (
      <UserActionsCell id={props.data[props.row.index].id} />
    ),
  },
];

export { usersColumns };
