import { useState, useEffect, forwardRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { initialPermission } from './core/_models';
import { createPermission, updatePermission } from "./core/_requests";
import clsx from "clsx";
import InputMask from 'react-input-mask';
import { isNotEmpty } from '../../../../../_metronic/helpers';
import { PermissionLoading } from "./components/loading/PermissionLoading";
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { getPermissionById } from './core/_requests';
import { useIntl } from 'react-intl';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";
import moment from "moment";

const PermissionCreateAndUpdatePage = () => {
    const intl = useIntl();
    const { id } = useParams();
    const { pathname } = useLocation();

    const user = useSelector(state => state.persistedReducer.user.user);

    const MaskedInput = forwardRef(({ value, onChange, onClick }, ref) => (
        <div className="ui input" onClick={onClick}>
            <InputMask ref={ref} mask="99/99/9999" placeholder="dd/mm/yyyy" value={value ? value : 'dd/mm/yyyy'} onChange={onChange} />
        </div>
    ));

    const navigate = useNavigate();

    const editOverTimeListSchema = Yup.object().shape({
        employee_id: Yup.string()
            .required(`${intl.formatMessage({ id: 'REQUIRED' })}`),
        attendance_not_tracked: Yup.string()
            .required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

        monthly_norm_tracked: Yup.string()
            .required(`${intl.formatMessage({ id: 'REQUIRED' })}`),


        daily_tolerance: Yup.string()
            .required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

        start_date: Yup.string()
            .required(`${intl.formatMessage({ id: 'REQUIRED' })}`),
        expire_date: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`)
    });
    const [overtimeListForEdit] = useState({
        employee_id: initialPermission.employee_id,
        attendance_not_tracked: initialPermission.attendance_not_tracked,
        monthly_norm_tracked: initialPermission.monthly_norm_tracked,
        daily_tolerance: initialPermission.daily_tolerance,
        start_date: initialPermission.start_date,
        expire_date: initialPermission.expire_date
    });


    const [employees, setEmployees] = useState([]);
    const [options, setOptions] = useState([]);

    const formik = useFormik({
        initialValues: overtimeListForEdit,
        validationSchema: editOverTimeListSchema,

        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            try {
                if (isNotEmpty(values.id)) {
                    const result = await updatePermission({ ...values, update_user_id: user?.id, start_date: moment(values.start_date).format('YYYY-MM-DD'), expire_date: moment(values.expire_date).format('YYYY-MM-DD'), status: 1 }, "concessions");
                    if (typeof result === "string") {
                        throw new Error(result)
                    } else {
                        toast.success(`${intl.formatMessage({ id: 'SUCCESS.MESSAGE.UPDATE' })}`);
                        navigate(-1);
                    }
                } else {
                    const result = await createPermission({ ...values, insert_user_id: user?.id, start_date: moment(values.start_date).format('YYYY-MM-DD'), expire_date: moment(values.expire_date).format('YYYY-MM-DD'), update_user_id: user?.id, status: 1 }, "concessions");
                    if (typeof result === "string") {
                        throw new Error(result)
                    } else {
                        toast.success(`${intl.formatMessage({ id: 'SUCCESS.MESSAGE.ADD' })}`);
                        navigate(-1);
                    }
                }
            } catch (error) {
                toast.error(error?.message, {
                    autoClose: 10000
                })
            }
            finally {
                setSubmitting(false)
            }
        },
    });


    const getAllEmployees = async () => {
        try {
            const request = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/employees`);

            if (request.status === 200) {
                setEmployees(request.data)
            } else {
                throw new Error(request.data)
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')

            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }


    const getAllOptions = async () => {
        try {
            const request = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/options`);

            if (request.status === 200) {
                setOptions(request.data)
            } else {
                throw new Error(request.data)
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')

            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }

    const getOverTimeList = async () => {
        const result = await getPermissionById("concessions", id);
        formik.setFieldValue('id', result?.id)
        formik.setFieldValue('employee_id', result.employee_id?.id);
        formik.setFieldValue('attendance_not_tracked', result.attendance_not_tracked?.id);
        formik.setFieldValue('monthly_norm_tracked', result.monthly_norm_tracked?.id);
        formik.setFieldValue('daily_tolerance', result.daily_tolerance);
        formik.setFieldValue('start_date', result.start_date);
        formik.setFieldValue('expire_date', result.expire_date);
    }

    useEffect(() => {
        if (id) {
            getOverTimeList();
        }
        getAllEmployees();
        getAllOptions();
    }, [])
    return (

        <div className="card mb-5 mb-xl-10">
            <div
                className="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_account_profile_details"
                aria-expanded="true"
                aria-controls="kt_account_profile_details"
            >
                <div className="card-title m-0">
                    <h3 className="fw-bold m-0">{(id && pathname.includes('view')) ? intl.formatMessage({ id: "VIEW_CONCESSION" }) :
                        (id && !pathname.includes('view')) ? intl.formatMessage({ id: "UPDATE_CONCESSION" }) : intl.formatMessage({ id: "CREATE_CONCESSION" })}</h3>
                </div>
            </div>
            <div id="kt_account_settings_profile_details" className="collapse show">
                <form
                    id="kt_account_profile_details_form"
                    className="form fv-plugins-bootstrap5 fv-plugins-framework"
                    onSubmit={formik.handleSubmit}
                    noValidate
                >
                    <div className="card-body border-top p-9">

                        <div className="d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch">
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-3 col-12 col-md-5">
                                <label className={`col-form-label fw-semibold fs-6 ${pathname.includes('view') == false && 'required'}`}>
                                    {intl.formatMessage({ id: "EMPLOYEE" })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {pathname.includes('view') ? <p>{employees?.find(em => em?.id == formik.values.employee_id)?.firstname} {employees?.find(em => em?.id == formik.values.employee_id)?.lastname}</p> : <select
                                        {...formik.getFieldProps("employee_id")}
                                        className={clsx(
                                            "form-select form-select-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.employee_id && formik.errors.employee_id,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.employee_id && !formik.errors.employee_id,
                                            }
                                        )}
                                        data-kt-select2="true"
                                        data-placeholder="Select "
                                        data-allow-clear="true"
                                        disabled={formik.isSubmitting}
                                    >
                                        <option value="">{intl.formatMessage({ id: 'SELECT' })}</option>

                                        {employees.length > 0 && employees.map(emp => (
                                            <option key={emp?.id} value={emp?.id}>{emp?.firstname} {emp?.lastname}</option>
                                        ))}

                                    </select>}
                                    {formik.touched.employee_id && formik.errors.employee_id && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.employee_id}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-3 col-12 col-md-5">
                                <label className={`col-form-label fw-semibold fs-6 ${pathname.includes('view') == false && 'required'}`}>
                                    {intl.formatMessage({ id: "ATTENDANCE_MONITORED" })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {pathname.includes('view') ? <p>{options?.find(op => op?.id == formik.values.attendance_not_tracked)?.label}</p> : <select
                                        {...formik.getFieldProps("attendance_not_tracked")}
                                        className={clsx(
                                            "form-select form-select-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.attendance_not_tracked && formik.errors.attendance_not_tracked,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.attendance_not_tracked && !formik.errors.attendance_not_tracked,
                                            }
                                        )}
                                        data-kt-select2="true"
                                        data-placeholder="Select "
                                        data-allow-clear="true"
                                        disabled={formik.isSubmitting}
                                    >
                                        <option value="">{intl.formatMessage({ id: 'SELECT' })}</option>
                                        {options.length > 0 && options.map(opt => (
                                            <option value={opt?.id} key={opt?.id}>{opt?.label}</option>
                                        ))}
                                    </select>}
                                    {formik.touched.attendance_not_tracked && formik.errors.attendance_not_tracked && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.attendance_not_tracked}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-3 col-12 col-md-5">
                                <label className={`col-form-label fw-semibold fs-6 ${pathname.includes('view') == false && 'required'}`}>
                                    {intl.formatMessage({ id: "MONTHYLY_NORM_WATCH" })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {pathname.includes('view') ? <p>{options?.find(op => op?.id == formik.values.monthly_norm_tracked)?.label}</p> : <select
                                        {...formik.getFieldProps("monthly_norm_tracked")}
                                        className={clsx(
                                            "form-select form-select-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.monthly_norm_tracked && formik.errors.monthly_norm_tracked,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.monthly_norm_tracked && !formik.errors.monthly_norm_tracked,
                                            }
                                        )}
                                        data-kt-select2="true"
                                        data-placeholder="Select "
                                        data-allow-clear="true"
                                        disabled={formik.isSubmitting}
                                    >
                                        <option value="">{intl.formatMessage({ id: 'SELECT' })}</option>
                                        {options.length > 0 && options.map(opt => (
                                            <option value={opt?.id} key={opt?.id}>{opt?.label}</option>
                                        ))}
                                    </select>}
                                    {formik.touched.monthly_norm_tracked && formik.errors.monthly_norm_tracked && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.monthly_norm_tracked}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch">

                            <div className="d-flex flex-column gap-2 mb-6 col-lg-3 col-12 col-md-5">
                                <label className={`col-form-label fw-semibold fs-6 ${pathname.includes('view') == false && 'required'}`}>
                                    {intl.formatMessage({ id: "DAILY_TOLERANS" })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {pathname.includes('view') ? <p>{formik.values.daily_tolerance}</p> : <select
                                        {...formik.getFieldProps("daily_tolerance")}
                                        className={clsx(
                                            "form-select form-select-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.daily_tolerance && formik.errors.daily_tolerance,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.daily_tolerance && !formik.errors.daily_tolerance,
                                            }
                                        )}
                                        data-kt-select2="true"
                                        data-placeholder="Select "
                                        data-allow-clear="true"
                                        disabled={formik.isSubmitting}
                                    >
                                        <option value="">{intl.formatMessage({ id: 'SELECT' })}</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </select>}
                                    {formik.touched.daily_tolerance && formik.errors.daily_tolerance && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.daily_tolerance}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-3 col-12 col-md-5">
                                <label className={`col-form-label fw-semibold fs-6 ${pathname.includes('view') == false && 'required'}`}>
                                    {intl.formatMessage({ id: "START.DATEE" })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {pathname.includes('view') ? <p>{moment(formik.values.start_date).format("DD-MM-YYYY")}</p> : <div className="w-100">
                                        <DatePicker
                                            wrapperClassName="datePicker"
                                            dateFormat="dd/MM/yyyy"
                                            placeholder="-- -- --"
                                            customInput={<MaskedInput />}
                                            name="start_date"
                                            selected={formik.values.start_date && new Date(formik.values.start_date)}
                                            onChange={(opt) => formik.setFieldValue("start_date", opt)}
                                            showMonthDropdown={true}
                                            showYearDropdown={true}
                                            shouldCloseOnSelect={true}
                                            calendarStartDay={1}
                                            scrollableYearDropdown={false}
                                        />
                                    </div>}
                                    {formik.touched.start_date && formik.errors.start_date && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.start_date}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-3 col-12 col-md-5">
                                <label className={`col-form-label fw-semibold fs-6 ${pathname.includes('view') == false && 'required'}`}>
                                    {intl.formatMessage({ id: "END.DATE" })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {pathname.includes('view') ? <p>{moment(formik.values.expire_date).format("DD-MM-YYYY")}</p> : <div className="w-100">
                                        <DatePicker
                                            wrapperClassName="datePicker"
                                            dateFormat="dd/MM/yyyy"
                                            placeholder="-- -- --"
                                            name="expire_date"
                                            customInput={<MaskedInput />}
                                            selected={formik.values.expire_date && new Date(formik.values.expire_date)}
                                            onChange={(opt) => formik.setFieldValue("expire_date", opt)}
                                            showMonthDropdown={true}
                                            showYearDropdown={true}
                                            shouldCloseOnSelect={true}
                                            calendarStartDay={1}
                                            scrollableYearDropdown={false}
                                        />
                                    </div>}
                                    {formik.touched.expire_date && formik.errors.expire_date && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.expire_date}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <Link to=""
                            type="reset"
                            onClick={() => navigate(-1)}
                            disabled={formik.isSubmitting}
                            className="btn btn-light btn-active-light-primary me-2"
                        >
                            {intl.formatMessage({ id: "DISCARD" })}
                        </Link>
                        {!pathname.includes('view') && <button
                            type="submit"
                            disabled={
                                formik.isSubmitting ||
                                !formik.isValid ||
                                !formik.touched
                            }
                            className="btn btn-primary"
                            id="kt_account_profile_details_submit"
                        >
                            <span className="indicator-label">{formik.isSubmitting ? intl.formatMessage({ id: "PLEASE.WAIT" }) : intl.formatMessage({ id: 'AUTH.GENERAL.SUBMIT_BUTTON' })}</span>
                        </button>}
                    </div>
                </form>
                {(formik.isSubmitting) && <PermissionLoading />}
            </div>
        </div>
    )
}

export default PermissionCreateAndUpdatePage

