import { useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import ReactToPrint from 'react-to-print'
import { downloadExcel } from 'react-export-table-to-excel'
import { KTIcon } from '../../../../../../../_metronic/helpers'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { CSVDownload } from 'react-csv'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable';
import { useIntl } from "react-intl";
import { useSelector } from 'react-redux';

const OfferCandidateToolButons = ({ printRef, setShow }) => {
  const intl = useIntl();
  const { filterUsers } = useQueryResponse()

  const companies = useSelector(state => state.persistedReducer.companies.companies);
  const [copy, setCopy] = useState({
    text: '',
    copied: false,
  });

  const [check, setCheck] = useState(false);

  useEffect(() => {
    setCopy(copy => ({
      ...copy, text: [...filterUsers.map((f) => {
        return [f?.id, f?.title, f?.number_of_vacancies, f?.minimum_age, f?.maximum_age, companies?.find(c => c?.id == f?.company_id)?.name]
      })].flat(Infinity).join('->')
    }))
  }, [filterUsers])

  const handleColumnVisibilityModal = () => {
    setShow(true)
  }

  const csvData = [
    ["ID", intl.formatMessage({ id: "NAMEE" }), intl.formatMessage({ id: "NUMBER.OF.VACANCIES" }), intl.formatMessage({ id: 'MINIMUM.AGE' }), intl.formatMessage({ id: 'MAXIMUM.AGE' }), intl.formatMessage({ id: "WORKPLACE" })],
    ...filterUsers?.map(f => {
      return [f?.id, f?.title, f?.number_of_vacancies, f?.minimum_age, f?.maximum_age, companies?.find(c => c?.id == f?.company_id)?.name]
    })];

  const header = ["ID", intl.formatMessage({ id: "NAMEE" }), intl.formatMessage({ id: "NUMBER.OF.VACANCIES" }), intl.formatMessage({ id: 'MINIMUM.AGE' }), intl.formatMessage({ id: 'MAXIMUM.AGE' }), intl.formatMessage({ id: "WORKPLACE" })];
  const body = [...filterUsers?.map(f => {
    return [f?.id, f?.title, f?.number_of_vacancies, f?.minimum_age, f?.maximum_age, companies?.find(c => c?.id == f?.company_id)?.name]
  })];

  const handleExportExcel = () => {
    downloadExcel({
      fileName: 'Vacancies',
      sheet: "Vacancies",
      tablePayload: {
        header,
        body
      }
    })
  }
  const handleExportPDFTable = () => {
    const doc = new jsPDF();

    autoTable(doc, {
      head: [["ID", intl.formatMessage({ id: "NAMEE" }), intl.formatMessage({ id: "NUMBER.OF.VACANCIES" }), intl.formatMessage({ id: 'MINIMUM.AGE' }), intl.formatMessage({ id: "MAXIMUM.AGE" }), intl.formatMessage({ id: "WORKPLACE" })]],
      body: [...filterUsers?.map(f => {
        return [f?.id, f?.title, f?.number_of_vacancies, f?.minimum_age, f?.maximum_age, companies?.find(c => c?.id == f?.company_id)?.name]
      })]
    });

    doc.save("approver's vacancy")
  }

  return (
    <div className='d-flex flex-column justify-content-center align-items-center gap-2 mt-2'>

      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <CopyToClipboard
          text={copy.text} style={{display:'flex',width:'100%', justifyContent:'center'}}
          onCopy={() => {
            setCopy({ ...copy, copied: true })
            toast.success('Copied')
          }}
        >
          <span>{intl.formatMessage({ id: "COPY" })}</span>
        </CopyToClipboard>
      </button>

      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <ReactToPrint
          trigger={() => {
            return <span>{intl.formatMessage({ id: 'PRINT' })}</span>
          }}
          content={() => printRef.current}
        />
      </button>

      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        onClick={handleExportExcel}
      >
        <KTIcon iconName='exit-up' className='fs-2' />
        {intl.formatMessage({ id: 'EXCEL' })}
      </button>
      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        onClick={() => {
          setCheck(true);
          setTimeout(() => {
            setCheck(false)
          }, 1000)
        }}
      >
        <KTIcon iconName='exit-up' className='fs-2' />
        CSV
        {check && <CSVDownload data={csvData} separator={";"} enclosingCharacter={``} filename={'vacancies.csv'} target="_blank" />}
      </button>
      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        onClick={handleExportPDFTable}

      >
        <KTIcon iconName='exit-up' className='fs-2' />
        PDF
      </button>

      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        onClick={handleColumnVisibilityModal}
      >
        {intl.formatMessage({ id: "COLUMN.VISIBILITY" })}
      </button>
    </div>
  )
}

export default OfferCandidateToolButons
