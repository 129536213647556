import axios from "axios";
import { useDispatch } from "react-redux";
import { removeSelectedCompany } from "../../../../redux-store/slices/selectCompanySlice";

const API_URL = process.env.REACT_APP_BASE_URL || "http://192.168.0.80:3000";

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/api/auth/me`;
export const LOGIN_URL = `${API_URL}/api/auth/login`;
export const REGISTER_URL = `${API_URL}/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`;

// Server should return AuthModel
export const login = async (username: string, password: string): Promise<any> => {
  try {
    const request = await axios.post(LOGIN_URL, {
      username,
      password,
    });

    if (request.status !== 200) {

      throw new Error(request.data);
    } else {
      return {
        status: true,
        token: request.data?.access_token,
      };
    }
  } catch (error: any) {
    return {
      status: false,
      message: error?.response?.data?.message,
    };
  }
};

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  });
}

export const getUserByToken = async (token: string): Promise<any> => {
  
  try {
    const header = `Bearer ${token}`;
    const request = await axios.post(GET_USER_BY_ACCESSTOKEN_URL, {
      headers: { Authorization: header },
    });

    if (request.status !== 200) {
      throw new Error(request.data);
    } else {
      return {
        status: true,
        user: request.data,
      };
    }
  } catch (error: any) {
    return {
      status: false,
      message: error?.response?.data?.message,
    };
  }
};
