import clsx from 'clsx'
import { useIntl } from 'react-intl';

const UserCustomHeader = ({ className, title, tableProps }) => {
  const intl = useIntl();

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className
      )}
      style={{ cursor: 'pointer' }}
    >
      {title == "Actions" ? intl.formatMessage({ id: "ACTIONS" }) :
        title == "İstifadəçi  adı" ? intl.formatMessage({ id: 'USERNAME' }) :
          title == "Adı" ? intl.formatMessage({ id: 'NAMEE' }) :
            title == 'Soyadı' ? intl.formatMessage({ id: 'SURNAME' }) :
              title == "Mail" ? intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' }) :
                title == "İş yeri" ? intl.formatMessage({ id: 'WORKPLACE' }) : title}
    </th>
  )
}

export { UserCustomHeader }
