import { useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { KTIcon } from '../../../../../../../_metronic/helpers'
import { deleteUser } from '../../core/_requests'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux';
import { useIntl } from "react-intl";

const UserActionsCell = ({ id }) => {

  const intl = useIntl();
  const userPermissions = useSelector(state => state.persistedReducer.user.user.permissions);

  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])


  const deleteItem = useMutation(() => deleteUser(id), {
    onSuccess: () => {
      // ✅ update detail view directly
      toast.success(`${intl.formatMessage({ id: "SUCCESS.MESSAGE.DELETE" })}`)
      queryClient.invalidateQueries(["users"])
    },
  })

  return (
    <>
      <button
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        {intl.formatMessage({ id: "ACTIONS" })}
        <KTIcon iconName='down' className='fs-5 m-0' />
      </button>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {userPermissions.length == 0 ? <div className='menu-item px-3'>
          <Link to={`update/${id}`} className='menu-link px-3' >
            {intl.formatMessage({ id: "EDIT" })}
          </Link>
        </div> : [...userPermissions].find(userPermisison => userPermisison?.name == "users update") && <div className='menu-item px-3'>
          <Link to={`update/${id}`} className='menu-link px-3' >
            {intl.formatMessage({ id: "EDIT" })}
          </Link>
        </div>}
        {userPermissions.length == 0 ? <div className='menu-item px-3'>
          <Link to={`view/${id}`} className='menu-link px-3' >
            {intl.formatMessage({ id: "VIEW" })}
          </Link>
        </div> : [...userPermissions].find(userPermisison => userPermisison?.name == "users index") && <div className='menu-item px-3'>
          <Link to={`view/${id}`} className='menu-link px-3' >
            {intl.formatMessage({ id: "VIEW" })}
          </Link>
        </div>}
        {userPermissions.length == 0 ? <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            onClick={async () => await deleteItem.mutateAsync()}
          >
            {intl.formatMessage({ id: "DELETE" })}
          </a>
        </div> : [...userPermissions].find(userPermission => userPermission?.name == "users destroy") && <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            onClick={async () => await deleteItem.mutateAsync()}
          >
            {intl.formatMessage({ id: "DELETE" })}
          </a>
        </div>}
      </div>
    </>
  )
}

export { UserActionsCell }
