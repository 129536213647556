import { PermissionInfoCell } from "./PermissionInfoCell";
import { PermissionActionsCell } from './PermissionActionsCell'
import { PermissionCustomHeader } from "./PermissionCustomHeader";
import moment from "moment";

const permissionColumns = [
  {
    Header: (props) => (
      <PermissionCustomHeader
        tableProps={props}
        title="ID"
        className="min-w-125px"
      />
    ),
    id: "id",
    Cell: ({ ...props }) => (
      <PermissionInfoCell permission={props.data[props.row.index]?.id} />
    ),
  },

  {
    Header: (props) => (
      <PermissionCustomHeader
        tableProps={props}
        title="ASA"
        className="min-w-125px"
      />
    ),
    id: "ASA",
    Cell: ({ ...props }) => (
      <PermissionInfoCell permission={`${props.data[props.row.index]?.employee_id?.firstname} ${props.data[props.row.index]?.employee_id?.lastname}`} />
    ),
  },
  {
    Header: (props) => (
      <PermissionCustomHeader
        tableProps={props}
        title="Davamiyyəti izlənmir"
        className="min-w-125px"
      />
    ),
    id: "Davamiyyəti izlənmir",
    Cell: ({ ...props }) => (
      <PermissionInfoCell permission={props.data[props.row.index]?.attendance_not_tracked?.label} />
    ),
  },

  {
    Header: (props) => (
      <PermissionCustomHeader
        tableProps={props}
        title="Aylıq norma izlənir"
        className="min-w-125px"
      />
    ),
    id: "Aylıq norma izlənir",
    Cell: ({ ...props }) => (
      <PermissionInfoCell permission={props.data[props.row.index]?.monthly_norm_tracked?.label} />
    ),
  },

  {
    Header: (props) => (
      <PermissionCustomHeader
        tableProps={props}
        title="Gün içində tolerans"
        className="min-w-125px"
      />
    ),
    id: "Gün içində tolerans",
    Cell: ({ ...props }) => (
      <PermissionInfoCell permission={props.data[props.row.index]?.daily_tolerance} />
    ),
  },

  {
    Header: (props) => (
      <PermissionCustomHeader
        tableProps={props}
        title="Begin date"
        className="min-w-125px"
      />
    ),
    id: "begin date",
    Cell: ({ ...props }) => (
      <PermissionInfoCell permission={moment(props.data[props.row.index]?.start_date).format("DD-MM-YYYY")} />
    ),
  },

  {
    Header: (props) => (
      <PermissionCustomHeader
        tableProps={props}
        title="End date"
        className="min-w-125px"
      />
    ),
    id: "End date",
    Cell: ({ ...props }) => (
      <PermissionInfoCell
        permission={moment(props.data[props.row.index]?.expire_date).format("DD-MM-YYYY")}
      />
    ),
  },
  {
    Header: (props) => (
      <PermissionCustomHeader
        tableProps={props}
        title="Actions"
        className="text-end min-w-100px"
      />
    ),
    id: "actions",
    Cell: ({ ...props }) => (
      <PermissionActionsCell id={props.data[props.row.index].id} />
    ),
  },
];

export { permissionColumns };
