import clsx from 'clsx'
import { useIntl } from 'react-intl';

const UserCustomHeader = ({ className, title, tableProps }) => {

  const intl = useIntl();
  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className
      )}
      style={{ cursor: 'pointer' }}
    >
      {title == "Actions" ? intl.formatMessage({ id: 'ACTIONS' }) :
        title == "ASA" ? intl.formatMessage({ id: "NSF" }) :
          title == "Təhsili" ? intl.formatMessage({ id: 'EDUCATION' }) :
            title == "Təhsil müəssisəsinin adı" ? intl.formatMessage({ id: 'EDUCATION.INSTITUTION' }) :
              title == "Fakültə" ? intl.formatMessage({ id: "FACULTY" }) :
                title == "İxtisas" ? intl.formatMessage({ id: 'SPECIALIZATION' }) :
                  title == "Diplomun seriya və nömrəsi" ? intl.formatMessage({ id: "VOUCHER.NUMBER.SERIES" }) :
                    title == "Bitirdiyi il" ? intl.formatMessage({ id: "YEAR.GRADUATED" }) :
                      title == "Diplomun verildiyi tarix" ? intl.formatMessage({ id: 'DATE.OF.ISSUEE' }) :
                        title}
    </th>
  )
}

export { UserCustomHeader }
