import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { useIntl } from 'react-intl';

const FeedbackModal = ({ setShow, setData, selectedId, selectedFeature, data }) => {

    const intl = useIntl();
    const [isLoading, setIsLoading] = useState(false);
    const [text, setText] = useState(null);

    useEffect(() => {
        if (data[selectedFeature].datas[selectedId].feedback.length == 0) {
            setText(null);
        } else {
            setText(data[selectedFeature].datas[selectedId].feedback);
        }
    }, [selectedId, selectedFeature]);

    const handleAddFeedBack = () => {
        setIsLoading(true);
        setData(data.map((pd, i) => {
            if (i == selectedFeature) {
                pd = {
                    ...pd, datas: pd.datas.map((p, index) => {
                        if (index == selectedId) {
                            p = { ...p, feedback: text };
                        }
                        return p;
                    })
                }
            }
            return pd;
        }));

        setTimeout(() => {
            setIsLoading(false);
            setShow(false);
        }, 1000);

    }


    useEffect(() => {
        return () => {
            setIsLoading(false);
            setText(null);
        }
    }, [])
    return (
        <div className='modal_test_box'>
            <div
                className="modal show"
                style={{ display: 'block', position: 'initial' }}
            >
                <Modal.Dialog>
                    <Modal.Header>
                        <Modal.Title>Feedback</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-flex flex-column">
                            <div className="row mt-10">
                                <div className="col-lg-11 mb-10 mb-lg-0">
                                    <textarea value={text} className='form-control form-control-solid mb-3 mb-lg-0' onChange={(e) => {
                                        if (e.target.value == "") {
                                            setText(null);
                                        } else {
                                            setText(e.target.value);
                                        }
                                    }} rows={5} placeholder='Type...'></textarea>
                                </div>
                            </div>
                            <div className="d-flex flex-center flex-row-fluid pt-12 gap-5">
                                <button type="submit" className="btn btn-secondary" id="kt_modal_upgrade_plan_btn" onClick={() => setShow(false)}>
                                    Discard
                                </button>
                                <button disabled={isLoading == false && text == null} type="submit" className="btn btn-primary" id="kt_modal_upgrade_plan_btn" onClick={handleAddFeedBack}>
                                    {isLoading ? <div>
                                        {intl.formatMessage({ id: "PLEASE.WAIT" })} <Spinner animation="border" size="sm" />
                                    </div> : <span className="indicator-label">
                                        Submit</span>}
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal.Dialog>
            </div>
        </div>
    )
}

export default FeedbackModal
