import { useRef, useState } from 'react'
import { ListViewProvider } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { VacancyListHeader } from './components/header/VacancyListHeader'
import { VacanciesTable } from './table/VacanciesTable.jsx'
import { KTCard } from '../../../../../_metronic/helpers'
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';

const VacanciesList = () => {

  const intl = useIntl();

  const printRef = useRef(null)
  const [show, setShow] = useState(false);

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'MENU.RECRUIMENT.VACANCIES' })}
        </title>
      </Helmet>
      <KTCard>
        <VacancyListHeader printRef={printRef} setShow={setShow} />
        <VacanciesTable printRef={printRef} show={show} setShow={setShow} />
      </KTCard>
    </>
  )
}

const VacanciesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <VacanciesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { VacanciesListWrapper }
