import { useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import ReactToPrint from 'react-to-print'
import { downloadExcel } from 'react-export-table-to-excel'
import { KTIcon } from '../../../../../../../_metronic/helpers'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { CSVDownload } from "react-csv";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { useIntl } from 'react-intl';
import moment from 'moment'

const InOutFilter = ({ printRef, setShow }) => {
  const intl = useIntl();

  const { filterPermissions } = useQueryResponse();
  const [check, setCheck] = useState(false);

  const [copy, setCopy] = useState({
    text: '',
    copied: false,
  });

  useEffect(() => {
    setCopy(copy => ({
      ...copy, text: [...filterPermissions.map((user) => {
        return [
          user?.id,
          `${user?.employee_id?.firstname} ${user?.employee_id?.lastname}`,
          user?.action_type_id?.label,
          moment(user?.action_date).format('DD-MM-YYYY'),
          user?.action_time
        ]
      })].flat(Infinity).join('->')
    }))
  }, [filterPermissions])


  const handleExportPDFTable = () => {
    const doc = new jsPDF();

    autoTable(doc, {
      head: [["ID", intl.formatMessage({ id: "NSF" }), intl.formatMessage({ id: "TYPE" }), intl.formatMessage({ id: "DATE" }), intl.formatMessage({ id: "TIME" })]],
      body: [...filterPermissions.map(user => {
        return [
          user?.id,
          `${user?.employee_id?.firstname} ${user?.employee_id?.lastname}`,
          user?.action_type_id?.label,
          moment(user?.action_date).format('DD-MM-YYYY'),
          user?.action_time
        ]
      })]
    })

    doc.save('in-outs.pdf');
  }

  const handleColumnVisibilityModal = () => {
    setShow(true)
  }

  const csvData = [
    ["ID", intl.formatMessage({ id: "NSF" }), intl.formatMessage({ id: "TYPE" }), intl.formatMessage({ id: "DATE" }), intl.formatMessage({ id: "TIME" })],
    ...filterPermissions.map(user => {
      return [
        user?.id,
        `${user?.employee_id?.firstname} ${user?.employee_id?.lastname}`,
        user?.action_type_id?.label,
        moment(user?.action_date).format('DD-MM-YYYY'),
        user?.action_time
      ];
    })
  ];

  const header = ["ID",  intl.formatMessage({ id: "NSF" }), intl.formatMessage({ id: "TYPE" }), intl.formatMessage({ id: "DATE" }), intl.formatMessage({ id: "TIME" })];
  const body = [...filterPermissions.map(user => {
    return [
      user?.id,
      `${user?.employee_id?.firstname} ${user?.employee_id?.lastname}`,
      user?.action_type_id?.label,
      moment(user?.action_date).format('DD-MM-YYYY'),
      user?.action_time
    ];
  })]
  const handleExportExcel = () => {
    downloadExcel({
      fileName: "In outs",
      sheet: "In outs",
      tablePayload: {
        header,
        body
      }
    })
  }
  return (
    <div className='d-flex flex-column justify-content-center align-items-center gap-2 mt-2'>
      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <CopyToClipboard
          text={copy.text} style={{display:'flex',width:'100%', justifyContent:'center'}}
          onCopy={() => {
            setCopy({ ...copy, copied: true })
            toast.success('Copied')
          }}
        >
          <span>{intl.formatMessage({ id: "COPY" })}</span>
        </CopyToClipboard>
      </button>

      <ReactToPrint
        trigger={() => {
          return <button
            type='button'
            className='btn btn-light-primary mx-auto w-75'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >{intl.formatMessage({ id: "PRINT" })}</button>;
        }}
        content={() => printRef.current}
      />

      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        onClick={handleExportExcel}
      >
        <KTIcon iconName='exit-up' className='fs-2' />
        {intl.formatMessage({ id: "EXCEL" })}
      </button>
      <button
        type="button"
        className="btn btn-light-primary mx-auto w-75"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        onClick={() => {
          setCheck(true);
          setTimeout(() => {
            setCheck(false);
          }, 1000)
        }}
      >
        <KTIcon iconName="exit-up" className="fs-2" />
        CSV
        {check && <CSVDownload separator={";"} enclosingCharacter={``} filename={'In-outs.csv'} data={csvData} target="_blank" />}
      </button>
      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        onClick={handleExportPDFTable}
      >
        <KTIcon iconName='exit-up' className='fs-2' />
        PDF
      </button>

      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        onClick={handleColumnVisibilityModal}
      >
        {intl.formatMessage({ id: "COLUMN.VISIBILITY" })}
      </button>


    </div>
  )
}

export { InOutFilter }
