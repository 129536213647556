import { useLang } from './HRAPP18n';
import { IntlProvider } from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/az'

import enMessages from './messages/en.json'
import azMessages from "./messages/az.json"
import ruMessages from "./messages/ru.json"

const allMessages = {
  en: enMessages,
  az: azMessages,
  ru: ruMessages
}

const I18nProvider = ({ children }) => {
  const locale = useLang()
  const messages = allMessages[locale]

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export { I18nProvider }
