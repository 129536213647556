import { useState, useEffect, forwardRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { initialUserDrivingLicense } from './components/core/_models'
import { createUserDrivingLicense, updateUserDrivingLicense } from "./components/core/_requests";
import clsx from "clsx";
import InputMask from 'react-input-mask';
import { isNotEmpty } from '../../../../_metronic/helpers';
import { UsersListLoading } from "./components/loading/UsersListLoading"
import { getUserDrivingLicenseById } from './components/core/_requests';
import { useQueryResponse } from "./components/core/QueryResponseProvider";
import { useMutation, useQueryClient } from "react-query";
import moment from "moment";
import DatePicker from "react-datepicker";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { useIntl } from 'react-intl';

const CreateAndUpdatePage = ({ setShowPage, setShowView, showView }) => {

    const intl = useIntl();

    const { id } = useParams()
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const navigate = useNavigate();
    const user = useSelector(state => state.persistedReducer.user.user)
    const MaskedInput = forwardRef(({ value, onChange, onClick }, ref) => (
        <div className="ui input" onClick={onClick}>
            <InputMask ref={ref} mask="99/99/9999" placeholder="dd/mm/yyyy" value={value ? value : 'dd/mm/yyyy'} onChange={onChange} />
        </div>
    ));

    const { selectId, setSelectId } = useQueryResponse();

    const queryClient = useQueryClient()
    const editUserDrivingLicenseSchema = Yup.object().shape({
        employee_id: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

        license_serial_number: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

        category_id: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

        license_issuer: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

        issue_date: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

        expire_date: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),
    });

    const [userDrivingLicenseForEdit] = useState({
        employee_id: id ? id : initialUserDrivingLicense.employee_id,
        license_serial_number: initialUserDrivingLicense.license_serial_number,
        category_id: initialUserDrivingLicense.category_id,
        license_issuer: initialUserDrivingLicense.license_issuer,
        issue_date: initialUserDrivingLicense.issue_date,
        expire_date: initialUserDrivingLicense.expire_date,
    });

    const [employees, setEmployees] = useState([])
    const [categories, setCategories] = useState([])

    const getUserDrivingLicense = useMutation(() => { }, {
        onSuccess: () => queryClient.invalidateQueries(['users-driving-licneses'])
    })
    const formik = useFormik({
        initialValues: userDrivingLicenseForEdit,
        validationSchema: editUserDrivingLicenseSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            try {
                if (isNotEmpty(values.id)) {
                    const result = await updateUserDrivingLicense({ ...values, update_user_id: user?.id, issue_date: moment(values.issue_date).format('YYYY-MM-DD'), expire_date: moment(values.expire_date).format('YYYY-MM-DD') });

                    if (typeof result == "string") {
                        throw new Error(result)
                    } else {
                        toast.success(`${intl.formatMessage({ id: 'SUCCESS.MESSAGE.UPDATE' })}`);
                        setShowPage(false);
                        getUserDrivingLicense.mutateAsync();
                    }
                } else {
                    const result = await createUserDrivingLicense({ ...values, update_user_id: user?.id, insert_user_id: user?.id, issue_date: moment(values.issue_date).format('YYYY-MM-DD'), expire_date: moment(values.expire_date).format('YYYY-MM-DD') });

                    if (typeof result == "string") {
                        throw new Error(result)
                    } else {
                        toast.success(`${intl.formatMessage({ id: 'SUCCESS.MESSAGE.ADD' })}`);
                        setShowPage(false);
                        getUserDrivingLicense.mutateAsync();
                    }
                }
            } catch (ex) {
                toast.error(ex?.message, {
                    autoClose: 10000
                })
            } finally {
                setSubmitting(true);
                setSelectId(null);
            }
        },
    });

    const getUserDrivingLicenseInfo = async () => {
        const result = await getUserDrivingLicenseById(selectId);
        formik.setValues({ ...result, employee_id: result?.employee?.id, category_id: result?.category?.id })
    }


    const getEmployeeByIdDrivingLicenseInfos = async (id) => {
        try {
            const request = await axios.get(`${baseUrl}/api/employees/${id}/driving-licenses`);

            if (request.status == 200) {
                const info = request?.data?.find(d => d?.id == selectId);

                if (info) {
                    formik.setValues({ ...info, employee_id: info?.employee?.id, category_id: info?.category?.id })
                }
            } else {
                throw new Error(request?.data)
            }
        } catch (error) {

            if (error?.response?.status == 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')
            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }

    const getAllEmployess = async () => {
        try {
            const request = await axios.get(`${baseUrl}/api/employees`);

            if (request.status === 200) {
                setEmployees(request.data)
            } else {
                throw new Error(request.data)
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')
            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }

    const getAllDrivingCategories = async () => {
        try {
            const request = await axios.get(`${baseUrl}/api/driver-license-categories`);

            if (request.status === 200) {
                setCategories(request.data)
            } else {
                throw new Error(request.data)
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')
            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }



    useEffect(() => {


        if (id) {
            getEmployeeByIdDrivingLicenseInfos(id);
        }
        else if (selectId) {
            getUserDrivingLicenseInfo();
        }
        getAllEmployess();
        getAllDrivingCategories()
    }, [selectId, id]);

    return (

        <div className="card mb-5 mb-xl-10">
            <div
                className="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_account_profile_details"
                aria-expanded="true"
                aria-controls="kt_account_profile_details"
            >
                <div className="card-title m-0">
                    <h3 className="fw-bold m-0">{showView ? intl.formatMessage({ id: "VIEW.DRIVING" }) : selectId ? intl.formatMessage({ id: "UPDATE.DRIVING" }) : intl.formatMessage({ id: 'CREATE.DRIVING' })}</h3>
                </div>
            </div>
            <div id="kt_account_settings_profile_details" className="collapse show">
                <form
                    id="kt_account_profile_details_form"
                    className="form fv-plugins-bootstrap5 fv-plugins-framework"
                    onSubmit={formik.handleSubmit}
                    noValidate
                >
                    <div className="card-body border-top p-9">
                        <div className="d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch">
                            {id ? null : <div className="d-flex flex-column gap-2 mb-6 col-lg-3 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'EMPLOYEE' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.employee?.firstname + " " + formik.values?.employee?.lastname}</p> : <select
                                        {...formik.getFieldProps("employee_id")}
                                        className={clsx(
                                            "form-select form-select-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.employee_id && formik.errors.employee_id,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.employee_id && !formik.errors.employee_id,
                                            }
                                        )}
                                        data-kt-select2="true"
                                        data-placeholder="Select "
                                        data-allow-clear="true"
                                        disabled={formik.isSubmitting}
                                    >
                                        <option value="">{intl.formatMessage({ id: "SELECT" })}</option>
                                        {employees.length > 0 && employees.map(employee => (
                                            <option key={employee?.id} value={employee?.id}>{employee?.firstname + " " + employee?.lastname}</option>
                                        ))}
                                    </select>}

                                    {formik.touched.employee_id && formik.errors.employee_id && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.employee_id}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>}
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-3 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'VOUCHER.NUMBER.SERIES' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.license_serial_number}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'VOUCHER.NUMBER.SERIES' })}
                                        {...formik.getFieldProps("license_serial_number")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.license_serial_number && formik.errors.license_serial_number,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.license_serial_number && !formik.errors.license_serial_number,
                                            }
                                        )}
                                    />}
                                    {formik.touched.license_serial_number && formik.errors.license_serial_number && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.license_serial_number}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-3 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'CATEGORY' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.category?.description}</p> : <select
                                        {...formik.getFieldProps("category_id")}
                                        className={clsx(
                                            "form-select form-select-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.category_id && formik.errors.category_id,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.category_id && !formik.errors.category_id,
                                            }
                                        )}
                                        data-kt-select2="true"
                                        data-placeholder="Select "
                                        data-allow-clear="true"
                                        disabled={formik.isSubmitting}
                                    >
                                        <option value="">{intl.formatMessage({ id: "SELECT" })}</option>
                                        {categories.length > 0 && categories.map(category => (
                                            <option key={category?.id} value={category?.id}>{category?.description}</option>
                                        ))}


                                    </select>}

                                    {formik.touched.category_id && formik.errors.category_id && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.category_id}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch">
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-3 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'ISSUING.AUTHORITY' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.license_issuer}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'ISSUING.AUTHORITY' })}
                                        {...formik.getFieldProps("license_issuer")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.license_issuer && formik.errors.license_issuer,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.license_issuer && !formik.errors.license_issuer,
                                            }
                                        )}
                                    />}
                                    {formik.touched.license_issuer && formik.errors.license_issuer && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.license_issuer}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-3 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'DATE.OF.ISSUEE' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{moment(formik.values.issue_date).format('DD-MM-YYYY')}</p> : <div className="w-100">
                                        <DatePicker
                                            wrapperClassName="datePicker"
                                            dateFormat="dd/MM/yyyy"
                                            placeholder="-- -- --"
                                            customInput={<MaskedInput />}
                                            name="issue_date"
                                            selected={formik.values.issue_date && new Date(formik.values.issue_date)}
                                            onChange={(opt) => formik.setFieldValue("issue_date", opt)}
                                            showDayDropdown={true}
                                            showMonthDropdown={true}
                                            showYearDropdown={true}
                                            shouldCloseOnSelect={true}
                                            calendarStartDay={1}
                                            scrollableYearDropdown={false}
                                        />
                                    </div>}

                                    {formik.touched.issue_date && formik.errors.issue_date && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.issue_date}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-3 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'VALIDITY' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{moment(formik.values.expire_date).format('DD-MM-YYYY')}</p> : <div className="w-100">
                                        <DatePicker
                                            wrapperClassName="datePicker"
                                            dateFormat="dd/MM/yyyy"
                                            placeholder="-- -- --"
                                            customInput={<MaskedInput />}
                                            name="expire_date"
                                            selected={formik.values.expire_date && new Date(formik.values.expire_date)}
                                            onChange={(opt) => formik.setFieldValue("expire_date", opt)}
                                            showDayDropdown={true}
                                            showMonthDropdown={true}
                                            showYearDropdown={true}
                                            shouldCloseOnSelect={true}
                                            calendarStartDay={1}
                                            scrollableYearDropdown={false}
                                        />
                                    </div>}

                                    {formik.touched.expire_date && formik.errors.expire_date && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.expire_date}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button
                            type="reset"
                            onClick={() => {
                                setShowPage(false)
                                setSelectId(null);
                                setShowView(false)
                            }}
                            disabled={formik.isSubmitting}
                            className="btn btn-light btn-active-light-primary me-2"
                        >
                            {intl.formatMessage({ id: "DISCARD" })}
                        </button>
                        {showView == false && <button
                            type="submit"
                            disabled={
                                formik.isSubmitting ||
                                !formik.isValid ||
                                !formik.touched
                            }
                            className="btn btn-primary"
                            id="kt_account_profile_details_submit"
                        >
                            <span className="indicator-label">{formik.isSubmitting ? intl.formatMessage({id:"PLEASE.WAIT"}) : intl.formatMessage({ id: 'AUTH.GENERAL.SUBMIT_BUTTON' })}</span>
                        </button>}
                    </div>
                </form>
                {(formik.isSubmitting) && <UsersListLoading />}
            </div>
        </div>

    )
}

export default CreateAndUpdatePage

