import { UserInfoCell } from "./UserInfoCell";
import { UserActionsCell } from "./UserActionsCell";
import { UserCustomHeader } from "./UserCustomHeader";
import moment from "moment";

const armyInfoes = [
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title="ID" className="min-w-125px" />
    ),
    id: 1,
    accessor: "id",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.id} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ASA"
        className="min-w-125px"
      />
    ),
    id: 2,
    accessor: "user",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.employee?.firstname + " " + props.data[props.row.index]?.employee?.lastname} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Qeydiyyat qrupu"
        className="min-w-125px"
      />
    ),
    id: 3,
    accessor: "registerGroup",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.group?.label} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Qeydiyyat kateqoriyası"
        className="min-w-125px"
      />
    ),
    id: 4,
    accessor: "registerCategory",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index].category?.id == 1 ? 'Kateqoriya 1' : 'Kateqoriya 2'} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Heyət"
        className="min-w-125px"
      />
    ),
    id: 5,
    accessor: "staff",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index].staff?.label} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Hərbi rütbə"
        className="min-w-125px"
      />
    ),
    id: 6,
    accessor: "militaryRank",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index].rank?.label} />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Hərbi uçot ixtisası"
        className="min-w-125px"
      />
    ),
    id: 7,
    accessor: "militaryAccountingMajor",
    Cell: ({ ...props }) => (
      <UserInfoCell
        user={props.data[props.row.index].specialty_acc}
      />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Hərbi xidmətə yararlılıq"
        className="min-w-125px"
      />
    ),
    id: 8,
    accessor: "fitness_service",
    Cell: ({ ...props }) => (
      <UserInfoCell
        user={props.data[props.row.index].fitness_service}
      />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Hərbi qeydiyyat üzrə xidmətin yeri"
        className="min-w-125px"
      />
    ),
    id: 9,
    accessor: "registration_service",
    Cell: ({ ...props }) => (
      <UserInfoCell
        user={props.data[props.row.index].registration_service}
      />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Hərbi qeydiyyata alındığı tarix"
        className="min-w-125px"
      />
    ),
    id: 10,
    accessor: "registration_date",
    Cell: ({ ...props }) => (
      <UserInfoCell
        user={moment(props.data[props.row.index].registration_date).format("DD-MM-YYYY")}
      />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Ümumi (komanda №-si)"
        className="min-w-125px"
      />
    ),
    id: 11,
    accessor: "general",
    Cell: ({ ...props }) => (
      <UserInfoCell
        user={props.data[props.row.index].general}
      />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Xüsusi"
        className="min-w-125px"
      />
    ),
    id: 12,
    accessor: "special",
    Cell: ({ ...props }) => (
      <UserInfoCell
        user={props.data[props.row.index].special}
      />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Hərbi vəzifəli olmaması barədə qeyd"
        className="min-w-125px"
      />
    ),
    id: 13,
    accessor: "no_official",
    Cell: ({ ...props }) => (
      <UserInfoCell
        user={props.data[props.row.index].no_official}
      />
    ),
  },
    {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Əlavə məlumatlar"
        className="min-w-125px"
      />
    ),
    id: 14,
    accessor: "additional_information",
    Cell: ({ ...props }) => (
      <UserInfoCell
        user={props.data[props.row.index].additional_information}
      />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Doldurulma tarixi"
        className="min-w-125px"
      />
    ),
    id: 15,
    accessor: "completion_date",
    Cell: ({ ...props }) => (
      <UserInfoCell
        user={moment(props.data[props.row.index].completion_date).format('DD-MM-YYYY')}
      />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Actions"
        className="text-end min-w-100px"
      />
    ),
    id: 24,
    accessor: "actions",
    Cell: ({ ...props }) => (
      <UserActionsCell id={props.data[props.row.index].id} />
    ),
  },
];

export { armyInfoes };
