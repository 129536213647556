/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
} from '../../../../../../_metronic/helpers'
import { getUsersPreviousJobsInformations } from './_requests'
import { useQueryRequest } from './QueryRequestProvider'
import { getUserManagementByIdInfos } from '../../../users-management/components/core/_requests'
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'

const QueryResponseContext = createResponseContext(initialQueryResponse)
const QueryResponseProvider = ({ children, showPage, setShowPage, setShowView, showView }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { state } = useQueryRequest()
  const [query, setQuery] = useState(state)
  const updatedQuery = useMemo(() => state, [state])
  const [filterUsers, setFilterUsers] = useState([])
  const [selectId, setSelectId] = useState(null);

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const { isFetching, refetch, data } = useQuery(
    'users-previous-jobs-information',
    async () => {
      try {
        if (id) {
          const result = await getUserManagementByIdInfos(id, 'previous-workplaces');
          setFilterUsers(result.length == 0 ? [] : result);
          return result.length == 0 ? [] : result;
        } else {
          const result = await getUsersPreviousJobsInformations(query);
          if (result?.status == 200) {
            setFilterUsers(result?.data)
            return result.data;
          } else if (result?.response?.status == 401) {
            localStorage.removeItem('token');
            navigate('/auth/login')
          } else {
            throw new Error(result?.response?.data?.message)
          }

        }
      } catch (error) {
         toast.error(error?.message, {
            autoClose: 10000
        })
      }
    },

    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      // onSuccess: (data) => setFilterUsers(data)
    }
  )

  const searchByUsernName = (searchText) => {
    setFilterUsers(
      [...data].filter((user) => {
        if (user.prev_employer.toLowerCase().includes(searchText.toLowerCase()) ||
          user.leave_reason.toLowerCase().includes(searchText.toLowerCase()) ||
          user.sector.toLowerCase().includes(searchText.toLowerCase())) {
          return true;
        }
        return false;
      })
    )


  }

  return (
    <QueryResponseContext.Provider
      value={{ isLoading: isFetching, refetch, selectId, setSelectId, showPage, setShowPage, setShowView, showView, filterUsers, searchByUsernName, query }}
    >
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const { filterUsers } = useQueryResponse()

  if (filterUsers.length === 0) {
    return []
  }

  return filterUsers || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    links: [],
    ...initialQueryState,
  }

  const { filterUsers } = useQueryResponse()
  if (!filterUsers || !filterUsers.payload || !filterUsers.payload.pagination) {
    return defaultPaginationState
  }

  return filterUsers.payload.pagination
}

const useQueryResponseLoading = () => {
  const { isLoading } = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
