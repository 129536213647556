import { useState, useMemo, useCallback, forwardRef } from "react";
import { Form, Modal } from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
import { customStyles } from "../../../../../../utilities/customStyles";
import CaretUp from "../../../../../../_metronic/assets/images/CaretUp.svg";

const GetFiredModal = ({ handleClose }) => {
  const [users] = useState([
    {
      label: "User 1",
      value: 1,
    },
    {
      label: "User 2",
      value: 2,
    },
    {
      label: "User 3",
      value: 3,
    },
  ]);

  const [typeOfTerminations] = useState([
    {
      label: "İşəgötürənin təşəbbüsü",
      value: 1,
    },

    {
      label: "İşçinin təşəbbüsü",
      value: 2,
    },
    {
      label: "ƏM müddətinin qurtarması",
      value: 3,
    },
    {
      label: "Əmək şəraiti şərtlərinin dəyişdirilməsi",
      value: 4,
    },
    {
      label: "Müəssisə mülkiyyətçisinin dəyişməsi",
      value: 5,
    },
    {
      label: "Tərəflərin iradəsindən asılı olmayan hallar",
      value: 6,
    },
    {
      label: "Tərəflərin əmək müqaviləsində müəyyən etdiyi hallar",
      value: 7,
    },
  ]);

  const [terminationClauses] = useState([
    {
      label: "Select 1",
      value: 1,
    },
    {
      label: "Select 2",
      value: 2,
    },
  ]);

  const [records] = useState([
    [
      {
        label: "Record 1",
        value: 1,
      },
      {
        label: "Record 2",
        value: 2,
      },

      {
        label: "Record 3",
        value: 3,
      },
    ],
  ]);
  const [selecttypeOfTermination, setSelecttypeOfTermination] = useState(null);

  const [selectUser, setSelectUser] = useState(null);

  const [selectDate, setSelectDate] = useState(null);

  const [selectterminationClause, setSelectterminationClause] = useState(null);

  const [selectRecord, setSelectRecord] = useState(null);

  const [textInput, setTextInput] = useState("");

  const MyCustomCalendarIcon = useCallback(
    ({ onClick }) => (
      <button
        onClick={onClick}
        style={{
          outline: "none",
          background: "none",
          border: "none",
          cursor: "pointer",
        }}
        className="d-flex justify-content-center align-items-center"
      >
        <img src={CaretUp} alt="arrow down" />
      </button>
    ),
    []
  );

  const CustomInputDateOfDismissal = useMemo(
    () =>
      forwardRef(({ value, onClick }, ref) => (
        <div onClick={onClick} className="new-custom-input">
          <p onClick={onClick} style={{ color: "#000" }}>
            {selectDate != null
              ? moment(selectDate).format("DD-MM-YYYY")
              : "Select"}
          </p>
          <MyCustomCalendarIcon onClick={onClick} />
        </div>
      )),
    [selectDate]
  );
  return (
    <div className="modal_test_box">
      <div
        className="modal"
        style={{
          display: "block",
          position: "initial",
          maxWidth: "1440px",
          margin: "0 auto",
        }}
      >
        <Modal.Dialog>
          <Modal.Header className="text-white bg-success fs-2">
            Əsas Məlumatlar
          </Modal.Header>
          <Modal.Body className="d-flex flex-column gap-5">
            <div className="overflow-auto h-300px py-4">
              <div className="field d-flex flex-column gap-2">
                <label className="col-form-label">İşçi</label>
                <Select
                  options={users}
                  value={selectUser}
                  onChange={(opt) => setSelectUser(opt)}
                  styles={customStyles}
                />
              </div>

              <div className="field d-flex flex-column gap-2">
                <label className="col-form-label">İşdən cıxış tarixi</label>
                <div className="w-100">
                  <DatePicker
                    wrapperClassName="datePicker"
                    dateFormat="yyy-mm-dd"
                    placeholder="-- -- --"
                    selected={selectDate}
                    customInput={<CustomInputDateOfDismissal />}
                    onChange={(opt) => setSelectDate(opt)}
                    showYearDropdown
                    yearDropdownItemNumber={50}
                    calendarStartDay={1}
                    scrollableYearDropdown
                    shouldCloseOnSelect={true}
                  />
                </div>
              </div>

              <div className="field d-flex flex-column gap-2">
                <label className="col-form-label">İşdən çıxış növü</label>
                <Select
                  options={typeOfTerminations}
                  value={selecttypeOfTermination}
                  onChange={(opt) => setSelecttypeOfTermination(opt)}
                  styles={customStyles}
                />
              </div>

              <div className="field d-flex flex-column gap-2">
                <label className="col-form-label">Xitam maddəsi</label>
                <Select
                  options={terminationClauses}
                  value={selectterminationClause}
                  onChange={(opt) => setSelectterminationClause(opt)}
                  styles={customStyles}
                />
              </div>
              <div className="field d-flex flex-column gap-2">
                <label className="col-form-label">Qeyd</label>
                <Select
                  options={records}
                  value={selectRecord}
                  onChange={(opt) => setSelectRecord(opt)}
                  styles={customStyles}
                />
              </div>

              <div className="field d-flex flex-column gap-2">
                <label className="col-form-label">
                  Əmək müqaviləsinə xitam verilərkən işçilərin təminatları
                </label>
                <Form.Control
                  value={textInput}
                  onChange={(e) => setTextInput(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={handleClose}>
              Save
            </button>
            <button className="btn btn-secondary" onClick={handleClose}>
              Close
            </button>
          </Modal.Footer>
        </Modal.Dialog>
      </div>
    </div>
  );
};

export default GetFiredModal;
