import { useState, useEffect, forwardRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { initialuserEducationInfo } from './components/core/_models'
import { createUserEducationInfo, updateUserEducationInfo } from "./components/core/_requests";
import clsx from "clsx";
import InputMask from 'react-input-mask';
import { isNotEmpty } from '../../../../_metronic/helpers';
import { UsersListLoading } from "./components/loading/UsersListLoading"
import { getUserEducationInfoById } from './components/core/_requests';
import { useQueryResponse } from "./components/core/QueryResponseProvider";
import { useMutation, useQueryClient } from "react-query";
import moment from "moment";
import DatePicker from "react-datepicker";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";

const CreateAndUpdatePage = ({ setShowPage }) => {

    const intl = useIntl();

    const { id } = useParams();

    const baseUrl = process.env.REACT_APP_BASE_URL;

    const MaskedInput = forwardRef(({ value, onChange, onClick }, ref) => (
        <div className="ui input" onClick={onClick}>
            <InputMask ref={ref} mask="99/99/9999" placeholder="dd/mm/yyyy" value={value ? value : 'dd/mm/yyyy'} onChange={onChange} />
        </div>
    ));

    const user = useSelector(state => state.persistedReducer.user.user)
    const navigate = useNavigate();

    const { selectId, setSelectId, showView, setShowView } = useQueryResponse();

    const queryClient = useQueryClient();

    const editUserEducationInfoSchema = Yup.object().shape({
        employee_id: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        qualification_id: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        institution_id: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        faculty: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        profession: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        diplom_issue_date: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        end_date: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        diplom_seria_num: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),
    });


    const [userEducationInfoForEdit] = useState({
        employee_id: id ? id : initialuserEducationInfo.employee_id,
        qualification_id: initialuserEducationInfo.qualification_idF,
        institution_id: initialuserEducationInfo.institution_id,
        faculty: initialuserEducationInfo.faculty,
        profession: initialuserEducationInfo.profession,
        diplom_issue_date: initialuserEducationInfo.diplom_issue_date,
        end_date: initialuserEducationInfo.regulationOfPropertyRelations,
        diplom_seria_num: initialuserEducationInfo.diplom_seria_num
    });


    const [employees, setEmployees] = useState([]);
    const [universities, setUniversities] = useState([]);
    const [qualifications, setQualifications] = useState([])
    const getUsersEducationInfo = useMutation(() => { }, {
        onSuccess: () => queryClient.invalidateQueries(['users-education-info'])
    })
    const formik = useFormik({
        initialValues: userEducationInfoForEdit,
        validationSchema: editUserEducationInfoSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            try {
                if (isNotEmpty(values.id)) {
                    const result = await updateUserEducationInfo({ ...values, update_user_id: user?.id, diplom_issue_date: moment(values.diplom_issue_date).format('YYYY-MM-DD') });
                    if (typeof result == "string") {
                        throw new Error(result)
                    } else {
                        toast.success(`${intl.formatMessage({ id: '"SUCCESS.MESSAGE.UPDATE' })}`);
                        setShowPage(false);
                        getUsersEducationInfo.mutateAsync();
                    }
                } else {
                    const result = await createUserEducationInfo({ ...values, insert_user_id: user?.id, update_user_id: user?.id, diplom_issue_date: moment(values.diplom_issue_date).format('YYYY-MM-DD') });

                    if (typeof result == "string") {
                        throw new Error(result)
                    } else {
                        toast.success(`${intl.formatMessage({ id: '"SUCCESS.MESSAGE.ADD' })}`);
                        setShowPage(false);
                        getUsersEducationInfo.mutateAsync();
                    }
                }
            } catch (ex) {
                toast.error(ex?.message, {
                    autoClose: 10000
                })
            } finally {
                setSubmitting(true);
                setSelectId(null);
            }
        },
    });

    const getUserEducationInfo = async () => {
        const result = await getUserEducationInfoById(selectId);
        formik.setValues({ ...result, employee_id: result?.employee?.id, institution_id: result?.institution?.id, qualification_id: result?.qualification?.id })
    }

    const getEmployeeByIdMilitaryInfos = async (id) => {
        try {
            const request = await axios.get(`${baseUrl}/api/employees/${id}/education-infos`);

            if (request.status == 200) {
                const familyInfo = request.data?.find(fI => fI?.id == selectId);

                if (familyInfo) {

                    formik.setValues({ ...familyInfo, employee_id: familyInfo?.employee?.id, institution_id: familyInfo?.institution?.id, qualification_id: familyInfo?.qualification?.id })
                }
            } else {
                throw new Error(request?.data)
            }
        } catch (error) {

            if (error?.response?.status == 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')
            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }
    const getAllEmployess = async () => {
        try {
            const request = await axios.get(`${baseUrl}/api/employees`);

            if (request.status === 200) {
                setEmployees(request.data)
            } else {
                throw new Error(request.data)
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')
            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }


    const getAllUniversities = async () => {
        try {
            const request = await axios.get(`${baseUrl}/api/universities`);

            if (request.status === 200) {
                setUniversities(request.data)
            } else {
                throw new Error(request.data)
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')
            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }


    const getAllQualifications = async () => {
        try {
            const request = await axios.get(`${baseUrl}/api/qualification-dictionaries`);

            if (request.status === 200) {
                setQualifications(request.data)
            } else {
                throw new Error(request.data)
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')
            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }
    useEffect(() => {
        if (id) {
            getEmployeeByIdMilitaryInfos(id)
        }
        else if (selectId) {
            getUserEducationInfo();
        }
        getAllEmployess();
        getAllUniversities();
        getAllQualifications();

    }, [selectId, id])

    return (

        <div className="card mb-5 mb-xl-10">
            <div
                className="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_account_profile_details"
                aria-expanded="true"
                aria-controls="kt_account_profile_details"
            >
                <div className="card-title m-0">
                    <h3 className="fw-bold m-0">{showView ? intl.formatMessage({ id: "VIEW.EDUCATION" }) : selectId ? intl.formatMessage({ id: 'UPDATE.EDUCATION' }) : intl.formatMessage({ id: 'CREATE.EDUCATION' })}</h3>
                </div>
            </div>
            <div id="kt_account_settings_profile_details" className="collapse show">
                <form
                    id="kt_account_profile_details_form"
                    className="form fv-plugins-bootstrap5 fv-plugins-framework"
                    onSubmit={formik.handleSubmit}
                    noValidate
                >
                    <div className="card-body border-top p-9">

                        <div className="d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch">
                            {id ? null : <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'EMPLOYEE' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.employee?.firstname + " " + formik.values?.employee?.lastname}</p> : <select
                                        {...formik.getFieldProps("employee_id")}
                                        className={clsx(
                                            "form-select form-select-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.employee_id && formik.errors.employee_id,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.employee_id && !formik.errors.employee_id,
                                            }
                                        )}
                                        data-kt-select2="true"
                                        data-placeholder="Select "
                                        data-allow-clear="true"
                                        disabled={formik.isSubmitting}
                                    >
                                        <option value="">{intl.formatMessage({ id: 'SELECT' })}</option>
                                        {employees.length > 0 && employees.map(employee => (
                                            <option key={employee?.id} value={employee?.id}>{employee?.firstname + " " + employee?.lastname}</option>
                                        ))}
                                    </select>}

                                    {formik.touched.employee_id && formik.errors.employee_id && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.employee_id}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>}
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'EDUCATION' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values.qualification?.label}</p> : <select
                                        {...formik.getFieldProps("qualification_id")}
                                        className={clsx(
                                            "form-select form-select-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.qualification_id && formik.errors.qualification_id,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.qualification_id && !formik.errors.qualification_id,
                                            }
                                        )}
                                        data-kt-select2="true"
                                        data-placeholder="Select "
                                        data-allow-clear="true"
                                        disabled={formik.isSubmitting}
                                    >
                                        <option value="">{intl.formatMessage({ id: 'SELECT' })}</option>
                                        {qualifications.length > 0 && qualifications.map(qua => (
                                            <option key={qua?.id} value={qua?.id}>{qua?.label}</option>
                                        ))}

                                    </select>}

                                    {formik.touched.qualification_id && formik.errors.qualification_id && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.qualification_id}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'EDUCATION.INSTITUTION' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.institution?.name}</p> : <select
                                        {...formik.getFieldProps("institution_id")}
                                        className={clsx(
                                            "form-select form-select-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.institution_id && formik.errors.institution_id,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.institution_id && !formik.errors.institution_id,
                                            }
                                        )}
                                        data-kt-select2="true"
                                        data-placeholder="Select "
                                        data-allow-clear="true"
                                        disabled={formik.isSubmitting}
                                    >
                                        <option value="">{intl.formatMessage({ id: 'SELECT' })}</option>
                                        {universities.length > 0 && universities.map(uni => (
                                            <option value={uni?.id} key={uni?.id}>{uni?.name}</option>
                                        ))}

                                    </select>}

                                    {formik.touched.institution_id && formik.errors.institution_id && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.institution_id}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'DATE.OF.ISSUE' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{moment(formik.values.diplom_issue_date).format('DD-MM-YYYY')}</p> : <div className="w-100">
                                        <DatePicker
                                            wrapperClassName="datePicker"
                                            dateFormat="dd/MM/yyyy"
                                            placeholder="-- -- --"
                                            customInput={<MaskedInput />}
                                            name="diplom_issue_date"
                                            selected={formik.values.diplom_issue_date && new Date(formik.values.diplom_issue_date)}
                                            onChange={(opt) => formik.setFieldValue("diplom_issue_date", opt)}
                                            showDayDropdown={true}
                                            showMonthDropdown={true}
                                            showYearDropdown={true}
                                            shouldCloseOnSelect={true}
                                            calendarStartDay={1}
                                            scrollableYearDropdown={false}
                                        />
                                    </div>}

                                    {formik.touched.diplom_issue_date && formik.errors.diplom_issue_date && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.diplom_issue_date}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch">
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'YEAR.GRADUATED' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{moment(formik.values.end_date).format('DD-MM-YYYY')}</p> : <div className="w-100">
                                        <DatePicker
                                            wrapperClassName="datePicker"
                                            dateFormat="dd/MM/yyyy"
                                            customInput={<MaskedInput />}
                                            placeholder="-- -- --"
                                            name="end_date"
                                            selected={formik.values.end_date && new Date(formik.values.end_date)}
                                            onChange={(opt) => formik.setFieldValue("end_date", opt)}
                                            showDayDropdown={true}
                                            showMonthDropdown={true}
                                            showYearDropdown={true}
                                            shouldCloseOnSelect={true}
                                            calendarStartDay={1}
                                            scrollableYearDropdown={false}
                                        />
                                    </div>}

                                    {formik.touched.end_date && formik.errors.end_date && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.end_date}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'SERIES.NUMBERS' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values.diplom_seria_num}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'SERIES.NUMBERS' })}
                                        {...formik.getFieldProps("diplom_seria_num")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.diplom_seria_num && formik.errors.diplom_seria_num,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.diplom_seria_num && !formik.errors.diplom_seria_num,
                                            }
                                        )}
                                    />}
                                    {formik.touched.diplom_seria_num && formik.errors.diplom_seria_num && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.diplom_seria_num}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'FACULTY' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values.faculty}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'FACULTY' })}
                                        {...formik.getFieldProps("faculty")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.faculty && formik.errors.faculty,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.faculty && !formik.errors.faculty,
                                            }
                                        )}
                                    />}
                                    {formik.touched.faculty && formik.errors.faculty && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.faculty}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'SPECIALIZATION' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values.profession}</p> : <input
                                        type="text"
                                        placeholder="İxtisas"
                                        {...formik.getFieldProps("profession")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.profession && formik.errors.profession,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.profession && !formik.errors.profession,
                                            }
                                        )}
                                    />}
                                    {formik.touched.profession && formik.errors.profession && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.profession}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button
                            type="reset"
                            onClick={() => {
                                setShowPage(false)
                                setSelectId(null)
                                setShowView(false)
                            }}
                            disabled={formik.isSubmitting}
                            className="btn btn-light btn-active-light-primary me-2"
                        >
                            {intl.formatMessage({ id: 'DISCARD' })}
                        </button>
                        {showView == false && <button
                            type="submit"
                            disabled={
                                formik.isSubmitting ||
                                !formik.isValid ||
                                !formik.touched
                            }
                            className="btn btn-primary"
                            id="kt_account_profile_details_submit"
                        >
                            <span className="indicator-label">{formik.isSubmitting ? intl.formatMessage({ id: "PLEASE.WAIT" }) : intl.formatMessage({ id: 'AUTH.GENERAL.SUBMIT_BUTTON' })}</span>
                        </button>}
                    </div>
                </form>
                {(formik.isSubmitting) && <UsersListLoading />}
            </div>
        </div>

    )
}

export default CreateAndUpdatePage

