import clsx from 'clsx'
import { useIntl } from 'react-intl';

const UserCustomHeader = ({ className, title, tableProps }) => {

  const intl = useIntl();
  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className
      )}
      style={{ cursor: 'pointer' }}
    >
      {title == "Actions" ? intl.formatMessage({ id: "ACTIONS" }) :
        title == "ASA" ? intl.formatMessage({ id: "NSF" }) :
          title == "Müəssisəyə qədər iş stajı" ? intl.formatMessage({ id: "PREVIOUS.WORK.EXPERIENCE" }) :
            title == "Müəssisədəki iş stajı" ? intl.formatMessage({ id: "WORKEXPERIENCE" }) :
              title == "Ümumi iş stajı" ? intl.formatMessage({ id: 'TOTAL.LENGTH.OF.SERVICE' }) :
                title}
    </th>
  )
}

export { UserCustomHeader }
