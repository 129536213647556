import { useTable, useColumns } from "react-table";
import { CustomHeaderColumn } from "./columns/CustomHeaderColumn";
import { useQueryResponseData, useQueryResponseLoading } from "../core/QueryResponseProvider";
import { usersColumns } from "./columns/columns";
import { UsersListPagination } from "../pagination/UsersListPagination";
import { UsersListLoading } from "../loading/UsersListLoading";
import { KTCardBody } from "../../../../../../_metronic/helpers";
import UsersColumnVisibilityModal from "../modal/UsersColumnVisibilityModal";
import { useIntl } from "react-intl";

const UsersTable = ({ printRef, show, setShow }) => {
  const intl = useIntl();
  const users = useQueryResponseData();
  const isLoading = useQueryResponseLoading();
  const data = users;
  const columns = usersColumns;
  const {
    getTableProps,
    getTableBodyProps,
    headers,
    rows,
    prepareRow,
    state: { hiddenColumns },
    setHiddenColumns,
  } = useTable(
    {
      data,
      columns,
    },
    useColumns
  );

  return (
    <KTCardBody className="py-4 users-table-card">
      {show && (
        <UsersColumnVisibilityModal
          setShow={setShow}
          hiddenColumns={hiddenColumns}
          headers={headers}
          columns={columns}
          setHiddenColumns={setHiddenColumns}
        />
      )}
      <div className="table-responsive">
        <table
          id="kt_table_users"
          ref={printRef}
          className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          {...getTableProps()}>
          <thead>
            <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
              {headers.length > 0 &&
                headers.map(header => {
                  switch (header.isVisible) {
                    case false:
                      return null;
                    case true:
                      return <CustomHeaderColumn key={header.id} column={header} />;
                    default:
                      return null;
                  }
                })}
            </tr>
          </thead>
          <tbody className="text-gray-600 fw-bold" {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className="d-flex text-center w-100 align-content-center justify-content-center">
                    {intl.formatMessage({ id: "NO.MATCHING.FOUND" })}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <UsersListPagination />
      {isLoading && <UsersListLoading />}
    </KTCardBody>
  );
};

export { UsersTable };
