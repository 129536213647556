import { PageTitle } from "../../../../_metronic/layout/core";
import ContentComponent from "./components/ContentComponent";

const DocumentsPage = () => {

  const breadCrumbs = [
    {
      title: "Contracts and Orders",
      path: "/documents/contracts-and-orders",
      isSeparator: false,
      isActive: false,
    },
  ];

  return (
    <div>
      <PageTitle breadcrumbs={breadCrumbs}>Contracts and Orders</PageTitle>
      <ContentComponent />
    </div>
  );
};

export default DocumentsPage;
