import { useRef } from "react";
import { KTIcon } from "../../../../../../_metronic/helpers";
import UsersToolButtons from "./UsersToolButtons";
import { useLocation } from "react-router-dom";
import { useIntl } from 'react-intl';

const UsersToolbar = ({ printRef, setShow, setShowPage }) => {

  const intl = useIntl();

  const { pathname } = useLocation();

  const dropdownRef = useRef(null);

  const handleDropdown = () => {
    if (dropdownRef.current.className.includes("show")) {
      dropdownRef.current.classList.remove('show');
      dropdownRef.current.classList.remove("active_menu")
    } else {
      dropdownRef.current.style.position = "absolute";
      dropdownRef.current.classList.add('show');
      dropdownRef.current.classList.add("active_menu")
    }
  }

  return (
    <>
      <div
        className="d-flex justify-content-end flex-wrap position-relative"
        data-kt-user-table-toolbar="base"
      >

        <button
          type='button'
          className='btn btn-light-primary me-3'
          onClick={handleDropdown}
        >
          <KTIcon iconName='filter' className='fs-2' />
          {intl.formatMessage({ id: "COLLAPSE" })}
        </button>


        <div ref={dropdownRef} className='menu menu-sub menu-sub-dropdown w-300px w-md-325px pb-2 scroll-y mh-200px mh-lg-250px' data-kt-menu='true'>
          {/* begin::Header */}
          <div className='px-7 py-5'>
            <div className='fs-5 text-dark fw-bolder'>{intl.formatMessage({ id: 'COLLAPSE' })} {intl.formatMessage({ id: 'OPTIONS' })}</div>
          </div>
          {/* end::Header */}

          {/* begin::Separator */}
          <div className='separator border-gray-200'></div>
          <UsersToolButtons
            printRef={printRef}
            setShow={setShow}
          />
        </div>

        {/* begin::Add user */}
        {pathname.includes('view') == false && <button
          type="button"
          className="btn btn-primary"
          onClick={() => setShowPage(true)}
        >
          <KTIcon iconName="plus" className="fs-2" />
          {intl.formatMessage({ id: 'ADD.WORKEXPERIENCE' })}
        </button>}
        {/* end::Add user */}
      </div>
    </>
  );
};

export { UsersToolbar };
