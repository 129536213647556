import { useState } from "react";
import Header from "./Header";
import Content from "./Content";

const ContentComponent = () => {
  const [data] = useState([
    {
      id: 1,
      code: "P00000001",
      firstName: "Aftandil",
      surname: "Abdiyev",
      fatherName: "Faiq",
      position: "Rəhbər",
    },
    {
      id: 2,
      code: "P00000002",
      firstName: "Rasim",
      surname: "Rasim",
      fatherName: "Rasim",
      position: "Rəhbər",
    },
    {
      id: 3,
      code: "P00000003",
      firstName: "Anar",
      surname: "Anarov",
      fatherName: "Anar",
      position: "Rəhbər",
    },
    {
      id: 4,
      code: "P00000058",
      firstName: "Ali",
      surname: "Qasimzade",
      fatherName: "Ali",
      position: "İşçi",
    },
  ]);

  const [filterData, setFilterData] = useState([
    {
      id: 1,
      code: "P00000001",
      firstName: "Aftandil",
      surname: "Abdiyev",
      fatherName: "Faiq",
      position: "Rəhbər",
    },
    {
      id: 2,
      code: "P00000002",
      firstName: "Rasim",
      surname: "Rasim",
      fatherName: "Rasim",
      position: "Rəhbər",
    },
    {
      id: 3,
      code: "P00000003",
      firstName: "Anar",
      surname: "Anarov",
      fatherName: "Anar",
      position: "Rəhbər",
    },
    {
      id: 4,
      code: "P00000058",
      firstName: "Ali",
      surname: "Qasimzade",
      fatherName: "Ali",
      position: "İşçi",
    },
  ]);
  const [theads] = useState([" ", "Kod", "Ad", "Soyad", "Ata adı", "Pozisya"]);
  const [companies] = useState([
    {
      label: "Company 1",
      value: 1,
    },
    {
      label: "Company 2",
      value: 2,
    },
    {
      label: "Company 3",
      value: 3,
    },
  ]);

  const [users] = useState([
    {
      label: "User 1",
      value: 1,
    },
    {
      label: "User 2",
      value: 2,
    },
    {
      label: "User 3",
      value: 3,
    },
  ]);
  const [positions] = useState([
    {
      label: "Rəhbər",
      value: 1,
    },
    {
      label: "İşçi",
      value: 2,
    },
  ]);

  const [selectUser, setSelectUser] = useState(null);

  const [codeInput, setCodeInput] = useState("");
  const [selectPosition, setSelectPosition] = useState(null);

  const [selectCompany, setSelectCompany] = useState(null);

  const [showTabel, setShowTabel] = useState(false);

  const handleSearchByField = (search) => {
    const filteringData = data.filter((d) => {
      if (
        d.code.includes(search) ||
        d.firstName.includes(search) ||
        d.surname.includes(search) ||
        d.position.includes(search) ||
        d.fatherName.includes(search)
      ) {
        return true;
      }
      return false;
    });
    setFilterData(filteringData);
  };
  return (
    <div className="d-flex flex-column gap-5">
      <Header
        selectCompany={selectCompany}
        handleSearchByField={handleSearchByField}
        setSelectCompany={setSelectCompany}
        selectPosition={selectPosition}
        setSelectPosition={setSelectPosition}
        selectUser={selectUser}
        setSelectUser={setSelectUser}
        users={users}
        companies={companies}
        positions={positions}
        codeInput={codeInput}
        setCodeInput={setCodeInput}
        setShowTabel={setShowTabel}
      />
      {showTabel && <Content data={filterData} theads={theads} />}
    </div>
  );
};

export default ContentComponent;
