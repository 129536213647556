import axios, { AxiosResponse } from "axios";
import { ID, Response } from "../../../../../../_metronic/helpers";
import { Approver, ApproverQueryResponse } from "./_models";

const baseUrl = process.env.REACT_APP_BASE_URL;

const getApprovers = (
  query: string,
  id: string | number
): Promise<ApproverQueryResponse> => {
  return axios
    .get(`${baseUrl}/api/positions/${id}/get-vacancies?${query}`)
    .then((d: AxiosResponse<ApproverQueryResponse>) => {
      return d;
    })
    .catch((err) => {
      return err;
    });
};

const getApproverId = (
  id: ID,
): Promise<Approver | undefined> => {
  return axios
    .get(`${baseUrl}/api/vacancies/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => err?.response?.data?.message);
};

export { getApprovers, getApproverId };
