import { useRef, useState } from 'react'
import { ListViewProvider } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { OfferCandidateListHeader } from './components/header/OfferCandidateListHeader'
import { OfferCandidatesTable } from './table/OfferCandidatesTable.jsx'
import { KTCard } from '../../../../../_metronic/helpers'
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async'

const OfferCandidateList = () => {
  const intl = useIntl();

  const printRef = useRef(null);
  const [show, setShow] = useState(false)
  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: "MENU.RECRUITMENT.APPROVING_CANDIDATES" })}
        </title>
      </Helmet>
      <KTCard>
        <OfferCandidateListHeader printRef={printRef} setShow={setShow} />
        <OfferCandidatesTable printRef={printRef} show={show} setShow={setShow} />
      </KTCard>
    </>
  )
}

const OfferCandidateListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <OfferCandidateList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { OfferCandidateListWrapper }
