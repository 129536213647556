import {useListView} from '../../core/ListViewProvider'
import {VacancyListToolbar} from './VacancyListToolbar'
import {VacancyListGrouping} from './VacancyListGrouping'
import {VacancyListSearchComponent} from './VacancyListSearchComponent'

const VacancyListHeader = ({printRef,setShow}) => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <VacancyListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <VacancyListGrouping /> : <VacancyListToolbar printRef={printRef} setShow={setShow} />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {VacancyListHeader}
