import { ID, Response } from "../../../../../../_metronic/helpers";

export type Permission = {
  id?: ID;
  employee_id?: string | null | number;
  attendance_not_tracked?: string | null | number;
  monthly_norm_tracked?: string | null | number;
  daily_tolerance?: string | null | number;
  start_date?: string | null;
  expire_date?: string | null;
};

export type PermissionQueryResponse = Response<Array<Permission>>;

export const initialPermission: Permission = {
  employee_id: null,
  attendance_not_tracked: null,
  monthly_norm_tracked: null,
  daily_tolerance: null,
  start_date: null,
  expire_date: null,
};
