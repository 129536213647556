import axios, { AxiosResponse } from "axios";
import { ID, Response } from "../../../../../../_metronic/helpers";
import { User, UsersQueryResponse } from "./_models";

// const API_URL = process.env.REACT_APP_THEME_API_URL
const baseUrl = process.env.REACT_APP_BASE_URL;
const USER_URL = `${baseUrl}/api/users`;
const GET_USERS_URL = `${baseUrl}/api/users`;

const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_USERS_URL}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => {
      return d;
    })
    .catch((err) => {
      return err;
    });
};

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios.get(`${USER_URL}/${id}`).then((response) => {
    return response.data;
  })
  .catch(err => err?.response?.data?.message)
};

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(USER_URL, user)
    .then((response: Response<User>) => response.data)
    .catch((err: any) => {
      return err?.response?.data?.message;
    });
};

const updateUser = (user: User): Promise<User | undefined> => {
  return axios
    .put(`${USER_URL}/${user.id}`, user)
    .then((response: Response<User>) => {})
    .catch((err: any) => {
      return err?.response?.data?.message;
    });
};

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {});
};

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`));
  return axios.all(requests).then(() => {});
};

export {
  getUsers,
  deleteUser,
  deleteSelectedUsers,
  getUserById,
  createUser,
  updateUser,
};
