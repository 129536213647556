import { useRef, useState } from "react";
import { ListViewProvider } from "./core/ListViewProvider";
import { QueryRequestProvider } from "./core/QueryRequestProvider";
import { QueryResponseProvider } from "./core/QueryResponseProvider";
import { UsersListHeader } from "./header/UsersListHeader";
import { UsersTable } from "./table/UsersTable.jsx";
import { KTCard } from "../../../../../_metronic/helpers";
import CreateAndUpdatePage from "../CreateAndUpdatePage";
import { useSelector } from 'react-redux';

const UserManagementList = ({ showPage, setShowPage, setShowView, showView, selectedComp }) => {
  const printRef = useRef(null);
  const [show, setShow] = useState(false);

  return (
    <>
      {showPage ? <CreateAndUpdatePage setShowPage={setShowPage} setShowView={setShowView} showView={showView} selectedComp={selectedComp} /> : <KTCard>

        <UsersListHeader printRef={printRef} setShow={setShow} setShowPage={setShowPage} />
        <UsersTable printRef={printRef} show={show} setShow={setShow} />
      </KTCard>}
    </>
  );
};

const UsersManagementAboutWorkplaceListWrapper = ({ showPage, setShowPage, setShowView, showView }) => {
  const selectedCompany = useSelector(state => state.persistedReducer.selectedCompany.selectedCompany)
  return (
    <QueryRequestProvider>
      <QueryResponseProvider showPage={showPage} setShowPage={setShowPage} setShowView={setShowView} showView={showView} selectedComp={selectedCompany}>
        <ListViewProvider>
          <UserManagementList showPage={showPage} setShowPage={setShowPage} setShowView={setShowView} showView={showView} selectedComp={selectedCompany} />
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
}

export { UsersManagementAboutWorkplaceListWrapper };
