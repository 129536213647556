import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { initialUserLanguageSkill } from './components/core/_models'
import { createUserLanguageSkill, updateUserLanguageSkill } from "./components/core/_requests";
import clsx from "clsx";
import { isNotEmpty } from '../../../../_metronic/helpers';
import { UsersListLoading } from "./components/loading/UsersListLoading"
import { getUserLanguageSkillById } from './components/core/_requests';
import { useQueryResponse } from "./components/core/QueryResponseProvider";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useIntl } from 'react-intl';

const CreateAndUpdatePage = ({ setShowPage }) => {

    const intl = useIntl();
    const { id } = useParams();

    const baseUrl = process.env.REACT_APP_BASE_URL;

    const navigate = useNavigate();
    const user = useSelector(state => state.persistedReducer.user.user);
    const languages = useSelector(state => state.persistedReducer.selectedLanguage.languages);

    const { selectId, setSelectId, setShowView, showView } = useQueryResponse();

    const queryClient = useQueryClient()

    const editUserLangugaeSkillSchema = Yup.object().shape({
        employee_id: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

        language_id: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        language_reading_id: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

        language_writing_id: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        language_speaking_id: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        language_understanding_id: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

    });
    const [userLanguageSkillForEdit] = useState({
        employee_id: id ? id : initialUserLanguageSkill.employee_id,
        language_id: initialUserLanguageSkill.language_id,
        language_reading_id: initialUserLanguageSkill.language_reading_id,
        language_writing_id: initialUserLanguageSkill.language_writing_id,
        language_speaking_id: initialUserLanguageSkill.language_speaking_id,
        language_understanding_id: initialUserLanguageSkill.language_understanding_id,
    });

    const [employees, setEmployees] = useState([]);

    const [languageLevels, setLanguageLevels] = useState([])


    const getUsersLanguageSkills = useMutation(() => { }, {
        onSuccess: () => queryClient.invalidateQueries(['users-language-skills'])
    })

    const getAllEmployess = async () => {
        try {
            const request = await axios.get(`${baseUrl}/api/employees`);

            if (request.status === 200) {
                setEmployees(request.data)
            } else {
                throw new Error(request.data)
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')
            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }

    const getEmployeeByIdLanguageKnowLedgeInfos = async (id) => {
        try {
            const request = await axios.get(`${baseUrl}/api/employees/${id}/language-knowledge`);

            if (request.status == 200) {
                const info = request.data?.find(fI => fI?.id == selectId);


                if (info) {

                    formik.setValues({ ...info, employee_id: info?.employee?.id, status: info?.status ? 1 : 0, language_id: info?.language?.id, language_reading_id: info?.language_reading?.id, language_speaking_id: info?.language_speaking?.id, language_understanding_id: info?.language_understanding?.id, language_writing_id: info?.language_writing?.id })
                }
            } else {
                throw new Error(request?.data)
            }
        } catch (error) {

            if (error?.response?.status == 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')
            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }


    const getAllLanguageLevels = async () => {
        try {
            const request = await axios.get(`${baseUrl}/api/language-levels`);

            if (request.status === 200) {
                setLanguageLevels(request.data)
            } else {
                throw new Error(request.data)
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')
            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }

    const formik = useFormik({
        initialValues: userLanguageSkillForEdit,
        validationSchema: editUserLangugaeSkillSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            try {
                if (isNotEmpty(values.id)) {
                    const result = await updateUserLanguageSkill({ ...values, status: values.status ? true : false, update_user_id: user?.id });

                    if (typeof result === "string") {
                        throw new Error(result)
                    }
                    else {
                        toast.success(`${intl.formatMessage({ id: 'SUCCESS.MESSAGE.UPDATE' })}`);
                        setShowPage(false);
                        getUsersLanguageSkills.mutateAsync();
                    }
                } else {
                    const result = await createUserLanguageSkill({ ...values, status: values.status ? true : false, update_user_id: user?.id, insert_user_id: user?.id });
                    if (typeof result === "string") {
                        throw new Error(result)
                    }
                    else {
                        toast.success(`${intl.formatMessage({ id: "SUCCESS.MESSAGE.ADD" })}`);
                        setShowPage(false);
                        getUsersLanguageSkills.mutateAsync();
                    }
                }
            } catch (ex) {
                toast.error(ex?.message, {
                    autoClose: 10000
                })
            } finally {
                setSubmitting(true);
                setSelectId(null);
            }
        },
    });

    const getUserLanguageSkillInfo = async () => {
        const result = await getUserLanguageSkillById(selectId);
        formik.setValues({ ...result, employee_id: result?.employee?.id, status: result?.status ? 1 : 0, language_id: result?.language?.id, language_reading_id: result?.language_reading?.id, language_speaking_id: result?.language_speaking?.id, language_understanding_id: result?.language_understanding?.id, language_writing_id: result?.language_writing?.id })
    }


    useEffect(() => {

        if (id) {
            getEmployeeByIdLanguageKnowLedgeInfos(id)
        }
        else if (selectId) {
            getUserLanguageSkillInfo();
        }
        getAllEmployess();
        getAllLanguageLevels();
    }, [selectId, id])

    return (

        <div className="card mb-5 mb-xl-10">
            <div
                className="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_account_profile_details"
                aria-expanded="true"
                aria-controls="kt_account_profile_details"
            >
                <div className="card-title m-0">
                    <h3 className="fw-bold m-0">{showView ? intl.formatMessage({ id: "VIEW.LANGUAGE" }) : selectId ? intl.formatMessage({ id: 'UPDATE.LANGUAGE' }) : intl.formatMessage({ id: "CREATE.LANGUAGE" })}</h3>
                </div>
            </div>
            <div id="kt_account_settings_profile_details" className="collapse show">
                <form
                    id="kt_account_profile_details_form"
                    className="form fv-plugins-bootstrap5 fv-plugins-framework"
                    onSubmit={formik.handleSubmit}
                    noValidate
                >
                    <div className="card-body border-top p-9">
                        <div className="d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch">
                            {id ? null : <div className="d-flex flex-column gap-2 mb-6 col-lg-3 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'EMPLOYEE' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.employee?.firstname + " " + formik.values?.employee?.lastname}</p> : <select
                                        {...formik.getFieldProps("employee_id")}
                                        className={clsx(
                                            "form-select form-select-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.employee_id && formik.errors.employee_id,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.employee_id && !formik.errors.employee_id,
                                            }
                                        )}
                                        data-kt-select2="true"
                                        data-placeholder="Select "
                                        data-allow-clear="true"
                                        disabled={formik.isSubmitting}
                                    >
                                        <option value="">{intl.formatMessage({ id: "SELECT" })}</option>
                                        {employees.length > 0 && employees.map(employee => (
                                            <option key={employee?.id} value={employee?.id}>{employee?.firstname + " " + employee?.lastname}</option>
                                        ))}
                                    </select>
                                    }
                                    {formik.touched.employee_id && formik.errors.employee_id && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.employee_id}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>}
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-3 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'LANGUAGE' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.language?.name}</p> : <select
                                        {...formik.getFieldProps("language_id")}
                                        className={clsx(
                                            "form-select form-select-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.language_id && formik.errors.language_id,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.language_id && !formik.errors.language_id,
                                            }
                                        )}
                                        data-kt-select2="true"
                                        data-placeholder="Select "
                                        data-allow-clear="true"
                                        disabled={formik.isSubmitting}
                                    >
                                        <option value="">{intl.formatMessage({ id: 'SELECT' })}</option>
                                        {languages.length > 0 && languages.map(lang => (
                                            <option value={lang?.id} key={lang?.id}>{lang?.name}</option>
                                        ))}

                                    </select>}

                                    {formik.touched.language_id && formik.errors.language_id && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.language_id}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-3 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: "READING" })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.language_reading?.label}</p> : <select
                                        {...formik.getFieldProps("language_reading_id")}
                                        className={clsx(
                                            "form-select form-select-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.language_reading_id && formik.errors.language_reading_id,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.language_reading_id && !formik.errors.language_reading_id,
                                            }
                                        )}
                                        data-kt-select2="true"
                                        data-placeholder="Select "
                                        data-allow-clear="true"
                                        disabled={formik.isSubmitting}
                                    >
                                        <option value="">{intl.formatMessage({ id: "SELECT" })}</option>
                                        {languageLevels.length > 0 && languageLevels.map(langLevel => (
                                            <option key={langLevel?.id} value={langLevel?.id}>{langLevel?.label}</option>
                                        ))}
                                    </select>}

                                    {formik.touched.language_reading_id && formik.errors.language_reading_id && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.language_reading_id}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch">
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-3 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: "WRITING" })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values.language_writing?.label}</p> : <select
                                        {...formik.getFieldProps("language_writing_id")}
                                        className={clsx(
                                            "form-select form-select-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.language_writing_id && formik.errors.language_writing_id,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.language_writing_id && !formik.errors.language_writing_id,
                                            }
                                        )}
                                        data-kt-select2="true"
                                        data-placeholder="Select "
                                        data-allow-clear="true"
                                        disabled={formik.isSubmitting}
                                    >
                                        <option value="">{intl.formatMessage({ id: 'SELECT' })}</option>
                                        {languageLevels.length > 0 && languageLevels.map(langLevel => (
                                            <option key={langLevel?.id} value={langLevel?.id}>{langLevel?.label}</option>
                                        ))}

                                    </select>}

                                    {formik.touched.language_writing_id && formik.errors.language_writing_id && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.language_writing_id}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-3 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: "SPEAKING" })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.language_speaking?.label}</p> : <select
                                        {...formik.getFieldProps("language_speaking_id")}
                                        className={clsx(
                                            "form-select form-select-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.language_speaking_id && formik.errors.language_speaking_id,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.language_speaking_id && !formik.errors.language_speaking_id,
                                            }
                                        )}
                                        data-kt-select2="true"
                                        data-placeholder="Select "
                                        data-allow-clear="true"
                                        disabled={formik.isSubmitting}
                                    >
                                        <option value="">{intl.formatMessage({ id: 'SELECT' })}</option>
                                        {languageLevels.length > 0 && languageLevels.map(langLevel => (
                                            <option key={langLevel?.id} value={langLevel?.id}>{langLevel?.label}</option>
                                        ))}

                                    </select>}

                                    {formik.touched.language_speaking_id && formik.errors.language_speaking_id && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.language_speaking_id}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-3 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: "UNDERSTADING" })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.language_understanding?.label}</p> : <select
                                        {...formik.getFieldProps("language_understanding_id")}
                                        className={clsx(
                                            "form-select form-select-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.language_understanding_id && formik.errors.language_understanding_id,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.language_understanding_id && !formik.errors.language_understanding_id,
                                            }
                                        )}
                                        data-kt-select2="true"
                                        data-placeholder="Select "
                                        data-allow-clear="true"
                                        disabled={formik.isSubmitting}
                                    >
                                        <option value="">{intl.formatMessage({ id: "SELECT" })}</option>
                                        {languageLevels.length > 0 && languageLevels.map(langLevel => (
                                            <option key={langLevel?.id} value={langLevel?.id}>{langLevel?.label}</option>
                                        ))}

                                    </select>}

                                    {formik.touched.language_understanding_id && formik.errors.language_understanding_id && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.language_understanding_id}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button
                            type="reset"
                            onClick={() => {
                                setShowPage(false)
                                setSelectId(null)
                                setShowView(false)
                            }}
                            disabled={formik.isSubmitting}
                            className="btn btn-light btn-active-light-primary me-2"
                        >
                            {intl.formatMessage({ id: "DISCARD" })}
                        </button>
                        {showView == false && <button
                            type="submit"
                            disabled={
                                formik.isSubmitting ||
                                !formik.isValid ||
                                !formik.touched
                            }
                            className="btn btn-primary"
                            id="kt_account_profile_details_submit"
                        >
                            <span className="indicator-label">{formik.isSubmitting ? intl.formatMessage({ id: "PLEASE.WAIT" }) : intl.formatMessage({ id: 'AUTH.GENERAL.SUBMIT_BUTTON' })}</span>
                        </button>}
                    </div>
                </form>
                {(formik.isSubmitting) && <UsersListLoading />}
            </div>
        </div>

    )
}

export default CreateAndUpdatePage

