/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
} from '../../../../../../_metronic/helpers'
import { getArmyInfos } from './_requests'
import { useQueryRequest } from './QueryRequestProvider'
import { useParams } from 'react-router-dom';
import { getUserManagementByIdInfos } from '../../../users-management/components/core/_requests'

const QueryResponseContext = createResponseContext(initialQueryResponse)
const QueryResponseProvider = ({ children, setShowPage, showPage, setShowView, showView }) => {
  const { id } = useParams();

  const { state } = useQueryRequest()
  const [query, setQuery] = useState(state)
  const updatedQuery = useMemo(() => state, [state])
  const [filterUsers, setFilterUsers] = useState([])

  const [selectId, setSelectId] = useState(null);
  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const { isFetching, refetch, data } = useQuery(
    'army-info',
    async () => {
      if (id) {
        const result = await getUserManagementByIdInfos(id, 'military-infos');

        return result.length == 0 ? [] : result
      } else {
        return getArmyInfos(query)
      }
    },

    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: (data) => setFilterUsers(data)
    }
  )

  const searchByUsernName = (searchText) => {
    setFilterUsers(
      [...data].filter((user) => {
        if (user.employee?.firstname.toLowerCase().includes(searchText.toLowerCase()) ||
          user.employee?.lastname.toLowerCase().includes(searchText.toLowerCase()) ||
          user.general.toLowerCase().includes(searchText.toLowerCase())) {
          return true;
        }
        return false;
      })
    )


  }

  return (
    <QueryResponseContext.Provider
      value={{ isLoading: isFetching, refetch, selectId, setSelectId, setShowPage, showView, setShowView, showPage, filterUsers, searchByUsernName, query }}
    >
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const { filterUsers } = useQueryResponse()

  if (filterUsers.length === 0) {
    return []
  }

  return filterUsers || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    links: [],
    ...initialQueryState,
  }

  const { filterUsers } = useQueryResponse()
  if (!filterUsers || !filterUsers.payload || !filterUsers.payload.pagination) {
    return defaultPaginationState
  }

  return filterUsers.payload.pagination
}

const useQueryResponseLoading = () => {
  const { isLoading } = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
