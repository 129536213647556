import { useRef, useState } from "react";
import { ListViewProvider } from "./core/ListViewProvider";
import { QueryRequestProvider } from "./core/QueryRequestProvider";
import { QueryResponseProvider } from "./core/QueryResponseProvider";
import { UsersListHeader } from "./header/UsersListHeader";
import { UsersTable } from "./table/UsersTable.jsx";
import { KTCard } from "../../../../../_metronic/helpers";
import CreateAndUpdatePage from "../CreateAndUpdatePage";

const UserManagementList = ({showPage, setShowPage,setShowView,showView}) => {
  const printRef = useRef(null);
  const [show, setShow] = useState(false);

  return (
    <>
     { showPage ? <CreateAndUpdatePage setShowPage={setShowPage} setShowView={setShowView} showView={showView} /> :  <KTCard>
        {/* <UsersSelectListHeader
         
        /> */}
        <UsersListHeader printRef={printRef} setShow={setShow} setShowPage={setShowPage}/>
        <UsersTable printRef={printRef} show={show} setShow={setShow} />
      </KTCard>}
    </>
  );
};

const UsersManagementEmployeementWrapper = ({showPage, setShowPage,setShowView, showView}) => (
  <QueryRequestProvider>
    <QueryResponseProvider showPage={showPage} setShowPage={setShowPage} setShowView={setShowView} showView={showView}>
      <ListViewProvider>
        <UserManagementList showPage={showPage} setShowPage={setShowPage} setShowView={setShowView} showView={showView} />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
);

export { UsersManagementEmployeementWrapper };
