import { PageTitle } from "../../../../_metronic/layout/core";
import { InterviewersListWrapper } from "./interviewers-list/InterviewersList";
import { useIntl } from 'react-intl';

const breadcrumbs = [
    {
        title: "Interviewers",
        path: "/operations/batch-operations-management/interviewers",
        isSeparator: false,
        isActive: false,
    },
];

const InterviewersPage = () => {
    const intl = useIntl();

    return (
            <div>
                <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: "MENU.RECRUITMENT.INTERVIEWERS" })}</PageTitle>
                <InterviewersListWrapper />
            </div>
    );
};

export default InterviewersPage;
