import { UserInfoCell } from "./UserInfoCell";
import { UserActionsCell } from "./UserActionsCell";
import { UserCustomHeader } from "./UserCustomHeader";

const familiInfoColumns = [
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title="ID" className="min-w-125px" />
    ),
    id: 1,
    accessor: "id",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.id} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ASA"
        className="min-w-125px"
      />
    ),
    id: 2,
    accessor: "ASA",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.employee?.firstname + " " + props.data[props.row.index]?.employee?.lastname} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Ailə üzvünün adı"
        className="min-w-125px"
      />
    ),
    id: 3,
    accessor: "user",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.m_firstname + " " + props.data[props.row.index]?.m_lastname} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Ailə üzvünün tipi"
        className="min-w-125px"
      />
    ),
    id: 4,
    accessor: "member_type_id",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index].family_member_type?.label} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Telefon nömrəsi"
        className="min-w-125px"
      />
    ),
    id: 5,
    accessor: "contact_number",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index].contact_number} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Adresi"
        className="min-w-125px"
      />
    ),
    id: 6,
    accessor: "address",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index].address} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Actions"
        className="text-end min-w-100px"
      />
    ),
    id: 7,
    accessor: "actions",
    Cell: ({ ...props }) => (
      <UserActionsCell id={props.data[props.row.index].id} />
    ),
  },
];

export { familiInfoColumns };
