import React from "react";
import ReactApexChart from 'react-apexcharts';

class ApexChartOval2 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            series: [50, 50],
            options: {
                chart: {
                    width: '100%',
                    type: 'pie',
                },
                labels: ["kisi", "qadin"],
                plotOptions: {
                    pie: {
                        dataLabels: {
                            offset: -5
                        }
                    }
                },
                // title: {
                //     text: "Custom Colored Pie"
                // },
                dataLabels: {
                    formatter(val, opts) {
                        const name = opts.w.globals.labels[opts.seriesIndex]
                        return [name, val.toFixed(1) + '%']
                    }
                },
                legend: {
                    show: false
                },
                colors: ['#3498DB','#F64747'], // Add this line for custom colors
            },
        };
    }

    render() {
        return (
            <div id="chart">
                <ReactApexChart options={this.state.options} series={this.state.series} type="pie" />
            </div>
        );
    }
}

export default ApexChartOval2;
