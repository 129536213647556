import { useState, useEffect, forwardRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { initialUserCertification } from './components/core/_models'
import { createUserCertification, updateUserCertification } from "./components/core/_requests";
import clsx from "clsx";
import InputMask from 'react-input-mask';
import { isNotEmpty } from '../../../../_metronic/helpers';
import { UsersListLoading } from "./components/loading/UsersListLoading"
import { getUserCertificationById } from './components/core/_requests';
import { useQueryResponse } from "./components/core/QueryResponseProvider";
import { useMutation, useQueryClient } from "react-query";
import moment from "moment";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

const CreateAndUpdatePage = ({ setShowPage }) => {

    const intl = useIntl();

    const { id } = useParams();
    const baseUrl = process.env.REACT_APP_BASE_URL;

    const navigate = useNavigate();
    const user = useSelector(state => state.persistedReducer.user.user)
    const { selectId, setSelectId, setShowView, showView } = useQueryResponse();

    const MaskedInput = forwardRef(({ value, onChange, onClick }, ref) => (
        <div className="ui input" onClick={onClick}>
            <InputMask ref={ref} mask="99/99/9999" placeholder="dd/mm/yyyy" value={value ? value : 'dd/mm/yyyy'} onChange={onChange} />
        </div>
    ));
    const queryClient = useQueryClient()
    const editUserCertificationSchema = Yup.object().shape({
        employee_id: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        training_center_name: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

        training_name: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

        training_date: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        given_date: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

    });


    const [userCertificationInfoForEdit] = useState({
        employee_id: id ? id : initialUserCertification.employee_id,
        training_center_name: initialUserCertification.training_center_name,
        training_name: initialUserCertification.training_name,
        training_date: initialUserCertification.training_date,
        given_date: initialUserCertification.given_date,
    });

    const [employees, setEmployees] = useState([]);

    const getUsersCertification = useMutation(() => { }, {
        onSuccess: () => queryClient.invalidateQueries(['users-certifications'])
    })
    const formik = useFormik({
        initialValues: userCertificationInfoForEdit,
        validationSchema: editUserCertificationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            try {
                if (isNotEmpty(values.id)) {

                    const copyValues = { ...values };
                    delete copyValues.insert_user
                    delete copyValues.update_user
                    delete copyValues.employee
                    const result = await updateUserCertification({ ...copyValues, status: values?.status ? true : false, update_user_id: user?.id, training_date: moment(copyValues.training_date).format('YYYY-MM-DD'), given_date: moment(copyValues.given_date).format('YYYY-MM-DD') });
                    if (typeof result == "string") {
                        throw new Error(result)
                    } else {
                        toast.success(`${intl.formatMessage({ id: 'SUCCESS.MESSAGE.UPDATE' })}`);
                        setShowPage(false);
                        getUsersCertification.mutateAsync();
                    }
                } else {
                    const result = await createUserCertification({ ...values, training_date: moment(values.training_date).format('YYYY-MM-DD'), given_date: moment(values.given_date).format('YYYY-MM-DD'), status: values?.status ? true : false, update_user_id: user?.id, insert_user_id: user?.id });
                    if (typeof result == "string") {
                        throw new Error(result)
                    } else {
                        toast.success(`${intl.formatMessage({ id: 'SUCCESS.MESSAGE.ADD' })}`);
                        setShowPage(false);
                        getUsersCertification.mutateAsync();
                    }
                }
            } catch (ex) {
                toast.error(ex?.message, {
                    autoClose: 10000
                })
            } finally {
                setSubmitting(true);
                setSelectId(null);

            }
        },
    });

    const getUserCertificationInfo = async () => {
        const result = await getUserCertificationById(selectId);
        formik.setValues({ ...result, employee_id: result?.employee?.id, status: result?.status ? 1 : 0 })
    }

    const getEmployeeByIdCertificationInfos = async (id) => {
        try {
            const request = await axios.get(`${baseUrl}/api/employees/${id}/certificates`);

            if (request.status == 200) {
                const info = request.data?.find(fI => fI?.id == selectId);


                if (info) {

                    formik.setValues({ ...info, employee_id: info?.employee?.id, status: info?.status ? 1 : 0 })
                }
            } else {
                throw new Error(request?.data)
            }
        } catch (error) {

            if (error?.response?.status == 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')
            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }

    const getAllEmployess = async () => {
        try {
            const request = await axios.get(`${baseUrl}/api/employees`);

            if (request.status === 200) {
                setEmployees(request.data)
            } else {
                throw new Error(request.data)
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')
            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }

    useEffect(() => {

        if (id) {
            getEmployeeByIdCertificationInfos(id)
        }
        else if (selectId) {
            getUserCertificationInfo();
        }
        getAllEmployess()
    }, [selectId, id])

    return (

        <div className="card mb-5 mb-xl-10">
            <div
                className="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_account_profile_details"
                aria-expanded="true"
                aria-controls="kt_account_profile_details"
            >
                <div className="card-title m-0">
                    <h3 className="fw-bold m-0">{showView ? intl.formatMessage({ id: 'VIEW.CERTIFICATION' }) : selectId ? intl.formatMessage({ id: 'UPDATE.CERTIFICATION' }) : intl.formatMessage({ id: 'CREATE.CERTIFICATION' })}</h3>
                </div>
            </div>
            <div id="kt_account_settings_profile_details" className="collapse show">
                <form
                    id="kt_account_profile_details_form"
                    className="form fv-plugins-bootstrap5 fv-plugins-framework"
                    onSubmit={formik.handleSubmit}
                    noValidate
                >
                    <div className="card-body border-top p-9">
                        <div className="d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch">
                            {id ? null : <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'EMPLOYEE' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.employee?.firstname + " " + formik.values?.employee?.lastname}</p> : <select
                                        {...formik.getFieldProps("employee_id")}
                                        className={clsx(
                                            "form-select form-select-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.employee_id && formik.errors.employee_id,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.employee_id && !formik.errors.employee_id,
                                            }
                                        )}
                                        data-kt-select2="true"
                                        data-placeholder="Select "
                                        data-allow-clear="true"
                                        disabled={formik.isSubmitting}
                                    >
                                        <option value="">{intl.formatMessage({ id: "SELECT" })}</option>
                                        {employees.length > 0 && employees.map(emp => (
                                            <option key={emp?.id} value={emp?.id}>{emp?.firstname + " " + emp?.lastname}</option>
                                        ))}
                                    </select>}

                                    {formik.touched.employee_id && formik.errors.employee_id && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.employee_id}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>}
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'EDUCATION.CENTER' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.training_center_name}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'EDUCATION.CENTER' })}
                                        {...formik.getFieldProps("training_center_name")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.training_center_name && formik.errors.training_center_name,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.training_center_name && !formik.errors.training_center_name,
                                            }
                                        )}
                                    />}
                                    {formik.touched.training_center_name && formik.errors.training_center_name && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.training_center_name}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'STUDY.TITLE.SUBJECT' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.training_name}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'STUDY.TITLE.SUBJECT' })}
                                        {...formik.getFieldProps("training_name")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.training_name && formik.errors.training_name,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.training_name && !formik.errors.training_name,
                                            }
                                        )}
                                    />}
                                    {formik.touched.training_name && formik.errors.training_name && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.training_name}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'HISTORY.TEACHING' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{moment(formik.values.training_date).format('DD-MM-YYYY')}</p> : <div className="w-100">
                                        <DatePicker
                                            wrapperClassName="datePicker"
                                            dateFormat="dd/MM/yyyy"
                                            placeholder="-- -- --"
                                            customInput={<MaskedInput />}
                                            name="training_date"
                                            selected={formik.values.training_date && new Date(formik.values.training_date)}
                                            onChange={(opt) => formik.setFieldValue("training_date", opt)}
                                            showDayDropdown={true}
                                            showMonthDropdown={true}
                                            showYearDropdown={true}
                                            shouldCloseOnSelect={true}
                                            calendarStartDay={1}
                                            scrollableYearDropdown={false}
                                        />
                                    </div>}

                                    {formik.touched.training_date && formik.errors.training_date && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.training_date}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'CERTIFICATED.DATE' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{moment(formik.values.given_date).format('DD-MM-YYYY')}</p> : <div className="w-100">
                                        <DatePicker
                                            wrapperClassName="datePicker"
                                            dateFormat="dd/MM/yyyy"
                                            placeholder="-- -- --"
                                            name="given_date"
                                            customInput={<MaskedInput />}
                                            selected={formik.values.given_date && new Date(formik.values.given_date)}
                                            onChange={(opt) => formik.setFieldValue("given_date", opt)}
                                            showDayDropdown={true}
                                            showMonthDropdown={true}
                                            showYearDropdown={true}
                                            shouldCloseOnSelect={true}
                                            calendarStartDay={1}
                                            scrollableYearDropdown={false}
                                        />
                                    </div>}

                                    {formik.touched.given_date && formik.errors.given_date && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.given_date}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button
                            type="reset"
                            onClick={() => {
                                setShowPage(false)
                                setSelectId(null);
                                setShowView(false)
                            }}
                            disabled={formik.isSubmitting}
                            className="btn btn-light btn-active-light-primary me-2"
                        >
                            {intl.formatMessage({ id: 'DISCARD' })}
                        </button>

                        {showView == false && <button
                            type="submit"
                            disabled={
                                formik.isSubmitting ||
                                !formik.isValid ||
                                !formik.touched
                            }
                            className="btn btn-primary"
                            id="kt_account_profile_details_submit"
                        >
                            <span className="indicator-label">{formik.isSubmitting ? intl.formatMessage({ id: "PLEASE.WAIT" }) : intl.formatMessage({ id: "AUTH.GENERAL.SUBMIT_BUTTON" })}</span>
                        </button>}
                    </div>
                </form>
                {(formik.isSubmitting) && <UsersListLoading />}
            </div>
        </div>

    )
}

export default CreateAndUpdatePage

