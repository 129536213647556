import { useRef } from "react"
import { KTIcon } from "../../../../../../../_metronic/helpers";
import { InOutFilter } from "./InOutFilter";
import { ToastContainer } from 'react-toastify';
import { Link } from "react-router-dom";
import { useIntl } from 'react-intl';

const InOutToolbar = ({ printRef, setShow }) => {

  const intl = useIntl();
  const dropdownRef = useRef(null);

  const handleDropdown = () => {
    if (dropdownRef.current.className.includes("show")) {
      dropdownRef.current.classList.remove('show');
      dropdownRef.current.classList.remove("active_menu")
    } else {
      dropdownRef.current.style.position = "absolute";
      dropdownRef.current.classList.add('show');
      dropdownRef.current.classList.add("active_menu")
    }
  }

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
      <div
        className="d-flex justify-content-end flex-wrap position-relative"
        data-kt-user-table-toolbar="base"
      >
        <button
          type='button'
          className='btn btn-light-primary me-3'
          onClick={handleDropdown}
          data-kt-menu-placement='bottom-end'
        >
          <KTIcon iconName='filter' className='fs-2' />
          {intl.formatMessage({ id: "COLLAPSE" })}
        </button>
        <div ref={dropdownRef} className='menu menu-sub menu-sub-dropdown w-300px w-md-325px pb-2 scroll-y mh-200px mh-lg-250px' data-kt-menu='true'>
          {/* begin::Header */}
          <div className='px-7 py-5'>
            <div className='fs-5 text-dark fw-bolder'>{intl.formatMessage({ id: "COLLAPSE" })} {intl.formatMessage({ id: "OPTIONS" })}</div>
          </div>
          {/* end::Header */}

          {/* begin::Separator */}
          <div className='separator border-gray-200'></div>
          <InOutFilter printRef={printRef} setShow={setShow} />

        </div>
        <Link to="create"
          type="button"
          className="btn btn-primary"

        >
          <KTIcon iconName="plus" className="fs-2" />
          {intl.formatMessage({ id: "ADD_INOUT" })}
        </Link>
      </div>
    </>
  );
};

export { InOutToolbar };
