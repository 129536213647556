// @ts-nocheck
import { UserInfoCell } from "./UserInfoCell";
import { UserActionsCell } from "./UserActionsCell";
import { UserCustomHeader } from "./UserCustomHeader";
import moment from "moment";

const usersColumns = [
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title="ID" className="min-w-125px" />
    ),
    id: 1,
    accessor: "id",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.id} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ASA"
        className="min-w-125px"
      />
    ),
    id: 2,
    accessor: "user",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.employee?.firstname + " " + props.data[props.row.index]?.employee?.lastname} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Təhsili"
        className="min-w-125px"
      />
    ),
    id: 3,
    accessor: "education",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.qualification?.label} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Təhsil müəssisəsinin adı"
        className="min-w-125px"
      />
    ),
    id: 4,
    accessor: "theNameOfTheEducationalInstitution",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index].institution?.name} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Fakültə"
        className="min-w-125px"
      />
    ),
    id: 5,
    accessor: "faculty",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index].faculty} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="İxtisas"
        className="min-w-125px"
      />
    ),
    id: 6,
    accessor: "specialization",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index].profession} />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Diplomun seriya və nömrəsi"
        className="min-w-125px"
      />
    ),
    id: 7,
    accessor: "seriesAndNumberOfTheDiploma",
    Cell: ({ ...props }) => (
      <UserInfoCell
        user={props.data[props.row.index].diplom_seria_num}
      />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Bitirdiyi il"
        className="min-w-125px"
      />
    ),
    id: 8,
    accessor: "theYearGraduated",
    Cell: ({ ...props }) => (
      <UserInfoCell
        user={moment(props.data[props.row.index].end_date).format('DD-MM-YYYY')}
      />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Diplomun verildiyi tarix"
        className="min-w-125px"
      />
    ),
    id: 9,
    accessor: "dateOfAwardOfDiploma",
    Cell: ({ ...props }) => (
      <UserInfoCell
        user={moment(props.data[props.row.index].diplom_issue_date).format('DD-MM-YYYY')}
      />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Actions"
        className="text-end min-w-100px"
      />
    ),
    id: 10,
    accessor: "actions",
    Cell: ({ ...props }) => (
      <UserActionsCell id={props.data[props.row.index].id} />
    ),
  },
];

export { usersColumns };
