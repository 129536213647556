import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { initialUserSkill } from './components/core/_models'
import { createUserSkill, updateUserSkill } from "./components/core/_requests";
import clsx from "clsx";
import { isNotEmpty } from '../../../../_metronic/helpers';
import { UsersListLoading } from "./components/loading/UsersListLoading"
import { getUserSkillById } from './components/core/_requests';
import { useQueryResponse } from "./components/core/QueryResponseProvider";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useIntl } from 'react-intl';

const CreateAndUpdatePage = ({ setShowPage, setShowView, showView }) => {

    const intl = useIntl();
    const { id } = useParams()
    const { selectId, setSelectId } = useQueryResponse();

    const user = useSelector(state => state.persistedReducer.user.user)
    const navigate = useNavigate();

    const baseUrl = process.env.REACT_APP_BASE_URL;

    const queryClient = useQueryClient()

    const [employees, setEmployees] = useState([])
    const editUserSkillSchema = Yup.object().shape({
        employee_id: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

        skill_name: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

        skill_description: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),
    });
    const [userSkillForEdit] = useState({
        employee_id: id ? id : initialUserSkill.employee_id,
        skill_name: initialUserSkill.skill_name,
        skill_description: initialUserSkill.skill_description,

    });

    const getUsersSkills = useMutation(() => { }, {
        onSuccess: () => queryClient.invalidateQueries(['users-skills'])
    })
    const formik = useFormik({
        initialValues: userSkillForEdit,
        validationSchema: editUserSkillSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            try {
                if (isNotEmpty(values.id)) {
                    await updateUserSkill({ ...values, update_user_id: user?.id, insert_user_id: null });
                    toast.success(`${intl.formatMessage({ id: "SUCCESS.MESSAGE.UPDATE" })}`);
                    setShowPage(false);
                    getUsersSkills.mutateAsync();
                } else {
                    await createUserSkill({ ...values, update_user_id: user?.id, insert_user_id: user?.id });
                    toast.success(`${intl.formatMessage({ id: "SUCCESS.MESSAGE.ADD" })}`);
                    setShowPage(false);
                    getUsersSkills.mutateAsync();
                }
            } catch (ex) {
                toast.error(ex?.message, {
                    autoClose: 10000
                })
            } finally {
                setSubmitting(true);
                setSelectId(null);
            }
        },
    });

    const getUserSkillInfo = async () => {
        const result = await getUserSkillById(selectId);
        formik.setValues({ ...result, employee_id: result?.employee?.id })
    }


    const getEmployeeByIdSkillsInfos = async (id) => {
        try {
            const request = await axios.get(`${baseUrl}/api/employees/${id}/skills`);

            if (request.status == 200) {
                const info = request?.data?.find(d => d?.id == selectId);

                if (info) {
                    formik.setValues({ ...info, employee_id: info?.employee?.id })
                }
            } else {
                throw new Error(request?.data);
            }
        } catch (error) {
            if (error?.response?.status == 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')
            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }

    const getAllEmployees = async () => {
        try {
            const request = await axios.get(`${baseUrl}/api/employees`);

            if (request.status === 200) {
                setEmployees(request.data)
            } else {
                throw new Error(request.data)
            }
        } catch (error) {

            if (error?.response?.status == 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')
            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }


    useEffect(() => {

        if (id) {
            getEmployeeByIdSkillsInfos(id)
        }
        else if (selectId) {
            getUserSkillInfo();
        }
        getAllEmployees()
    }, [selectId, id])

    return (

        <div className="card mb-5 mb-xl-10">
            <div
                className="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_account_profile_details"
                aria-expanded="true"
                aria-controls="kt_account_profile_details"
            >
                <div className="card-title m-0">
                    <h3 className="fw-bold m-0">{showView ? intl.formatMessage({ id: "VIEW.SKILL" }) : selectId ? intl.formatMessage({ id: 'UPDATE.SKILL' }) : intl.formatMessage({ id: 'CREATE.SKILL' })}</h3>
                </div>
            </div>
            <div id="kt_account_settings_profile_details" className="collapse show">
                <form
                    id="kt_account_profile_details_form"
                    className="form fv-plugins-bootstrap5 fv-plugins-framework"
                    onSubmit={formik.handleSubmit}
                    noValidate
                >
                    <div className="card-body border-top p-9">
                        {id ? null : <div className="row mb-6">
                            <label className={`col-lg-4 col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                {intl.formatMessage({ id: "EMPLOYEE" })}
                            </label>
                            <div className="col-lg-8 fv-row fv-plugins-icon-container">
                                {showView ? <p>{formik.values?.employee?.firstname + " " + formik.values?.employee?.lastname}</p> : <select
                                    {...formik.getFieldProps("employee_id")}
                                    className={clsx(
                                        "form-select form-select-solid mb-3 mb-lg-0",
                                        {
                                            "is-invalid":
                                                formik.touched.employee_id && formik.errors.employee_id,
                                        },
                                        {
                                            "is-valid":
                                                formik.touched.employee_id && !formik.errors.employee_id,
                                        }
                                    )}
                                    data-kt-select2="true"
                                    data-placeholder="Select "
                                    data-allow-clear="true"
                                    disabled={formik.isSubmitting}
                                >
                                    <option value="">{intl.formatMessage({ id: "SELECT" })}</option>
                                    {employees.length > 0 && employees.map(employee => (
                                        <option key={employee?.id} value={employee?.id}>{employee?.firstname + " " + employee?.lastname}</option>
                                    ))}
                                </select>
                                }
                                {formik.touched.employee_id && formik.errors.employee_id && (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            <span role="alert">{formik.errors.employee_id}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>}
                        <div className="row mb-6">
                            <label className={`col-lg-4 col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                {intl.formatMessage({ id: 'SKILL.NAME' })}
                            </label>
                            <div className="col-lg-8 fv-row fv-plugins-icon-container">
                                {showView ? <p>{formik.values?.skill_name}</p> : <input
                                    type="text"
                                    placeholder={intl.formatMessage({ id: "SKILL.NAME" })}
                                    {...formik.getFieldProps("skill_name")}
                                    className={clsx(
                                        "form-control form-control-solid mb-3 mb-lg-0",
                                        {
                                            "is-invalid":
                                                formik.touched.skill_name && formik.errors.skill_name,
                                        },
                                        {
                                            "is-valid":
                                                formik.touched.skill_name && !formik.errors.skill_name,
                                        }
                                    )}
                                />}
                                {formik.touched.skill_name && formik.errors.skill_name && (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            <span role="alert">{formik.errors.skill_name}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="row mb-6">
                            <label className={`col-lg-4 col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                {intl.formatMessage({ id: "SKILL.DESCRIPTION" })}
                            </label>
                            <div className="col-lg-8 fv-row fv-plugins-icon-container">
                                {showView ? <p>{formik.values?.skill_description}</p> : <textarea
                                    rows={5}
                                    placeholder={intl.formatMessage({ id: 'SKILL.DESCRIPTION' })}
                                    {...formik.getFieldProps("skill_description")}
                                    className={clsx(
                                        "form-control form-control-solid mb-3 mb-lg-0",
                                        {
                                            "is-invalid":
                                                formik.touched.skill_description && formik.errors.skill_description,
                                        },
                                        {
                                            "is-valid":
                                                formik.touched.skill_description && !formik.errors.skill_description,
                                        }
                                    )}>

                                </textarea>}
                                {formik.touched.skill_description && formik.errors.skill_description && (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            <span role="alert">{formik.errors.skill_description}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button
                            type="reset"
                            onClick={() => {
                                setShowPage(false)
                                setSelectId(null);
                                setShowView(false)
                            }}
                            disabled={formik.isSubmitting}
                            className="btn btn-light btn-active-light-primary me-2"
                        >
                            {intl.formatMessage({ id: 'DISCARD' })}
                        </button>
                        {showView == false && <button
                            type="submit"
                            disabled={
                                formik.isSubmitting ||
                                !formik.isValid ||
                                !formik.touched
                            }
                            className="btn btn-primary"
                            id="kt_account_profile_details_submit"
                        >
                            <span className="indicator-label">{formik.isSubmitting ? intl.formatMessage({ id: "PLEASE.WAIT" }) : intl.formatMessage({ id: 'AUTH.GENERAL.SUBMIT_BUTTON' })}</span>
                        </button>}
                    </div>
                </form>
                {(formik.isSubmitting) && <UsersListLoading />}
            </div>
        </div>

    )
}

export default CreateAndUpdatePage

