import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import Select from "react-select";
import { customStyles } from "../../../../../utilities/customStyles";
import WhichDocumentPrint from "./WhichDocumentPrint";
import DateRange from "./DateRange";
import ResultTable from "./ResultTable";

const ViewDocumentModal = ({ handleClose }) => {

  const [check, setCheck] = useState(false);
  const [selectWhich, setSelectWhich] = useState(1);
  const [contracts] = useState([
    {
      label: "Əmək müqaviləsi",
      value: 1
    },
    {
      label: "Əmək müqaviləsinə əlavə",
      value: 2
    },
    {
      label: "Hərbi uçot vərəqəsi",
      value: 3
    },
  ]);

  const [select, setSelect] = useState(null);

  const [orders] = useState([
    {
      label: 'Avans əmri',
      value: 1
    },
    {
      label: 'Əvəzgün verilmesi hq emr',
      value: 2
    },
    {
      label: 'Ezamiyyət əmri',
      value: 3
    },
    {
      label: 'İş vaxtından artıq iş emri',
      value: 4
    },
    {
      label: 'İşə qəbul əmri',
      value: 5
    }, {
      label: 'Maaş dəyişikliyi əmri',
      value: 6
    },
    {
      label: 'Məzuniyyət (qismən ödənişli)',
      value: 7
    },
    {
      label: 'Məzuniyyət əmri - ödənişsiz',
      value: 8
    },
    {
      label: 'Məzuniyyət əmri -əmək',
      value: 9
    },
    {
      label: 'Məzuniyyətdən geri çağrılma əmrii',
      value: 10
    },
    {
      label: 'Mükafat əmri',
      value: 11
    },
    {
      label: 'qısaldılmış iş vaxti əmri',
      value: 12
    },
    {
      label: 'qrafik deyişikliyi əmri',
      value: 13
    },
    {
      label: 'Sosial məzuniyyət əmri',
      value: 14
    },
    {
      label: 'Ştat əmri -əlavə',
      value: 15
    },
    {
      label: 'Ştat əmri -ləğv',
      value: 16
    },
    {
      label: 'Ştat əmri- ştat cədvəlinin təsdiqi',
      value: 17
    },
    {
      label: 'Vəzifə dəyişikliyi əmri',
      value: 18
    },
    {
      label: 'Xitam əmri',
      value: 19
    },


  ])

  const [selectPrintDocumentType, setSelectPrintDocumentType] = useState(null);
  const [selectWhichDatePrint, setSelectWhichDatePrint] = useState(null);


  const [beginDate, setBeginDate] = useState(null)
  const [expiryDate, setExpiryDate] = useState(null)

  const handleSelect = (i) => {
    setCheck(true);
    setSelectWhich(i);
  }

  useEffect(() => {
    setSelect(null)
    setSelectPrintDocumentType(null);
    setSelectWhichDatePrint(null);
    console.log("rendered");
  }, [selectWhich])
  return (
    <div className="modal_test_box">
      <div
        className="modal"
        style={{ display: "block", position: "initial", maxWidth: "1440px", margin: '0 auto' }}
      >
        <Modal.Dialog>
          <Modal.Header className="text-white bg-success fs-2">
            Müqavilələr və Əmrlər
          </Modal.Header>
          <Modal.Body className="d-flex flex-column gap-5">
            <div className="labels d-flex gap-2">
              <label htmlFor="contracts" className="d-flex gap-2 cursor-pointer">
                <Form.Check type="radio" onClick={() => handleSelect(1)} name="check" id="contracts" />
                <p>Müqavilə</p>
              </label>
              <label htmlFor="orders" className="d-flex gap-2 cursor-pointer">
                <Form.Check type="radio" onClick={() => handleSelect(2)} name="check" id="orders" />
                <p>Əmr</p>
              </label>
            </div>
            {
              check && <Select options={selectWhich === 1 ? contracts : orders} value={select} onChange={(opt) => setSelect(opt)} styles={customStyles} />
            }
            {
              check && selectWhich && select !== null && <div className="d-flex gap-4">
                <WhichDocumentPrint selectWhich={selectWhich} setSelectPrintDocumentType={setSelectPrintDocumentType} setSelectWhichDatePrint={setSelectWhichDatePrint} />
                {selectWhich === 2 && <DateRange beginDate={beginDate} setBeginDate={setBeginDate} expiryDate={expiryDate} setExpiryDate={setExpiryDate} />}
              </div>
            }
            {check && selectWhich && select && (selectPrintDocumentType || selectWhichDatePrint) && <ResultTable />}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary w-25" onClick={handleClose}>Close</button>
          </Modal.Footer>
        </Modal.Dialog>
      </div>
    </div>
  );
};

export default ViewDocumentModal;
