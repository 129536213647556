import clsx from 'clsx'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { HeaderNotificationsMenu, HeaderUserMenu } from '../../../partials'
import { useSelector } from "react-redux";
import { useIntl } from 'react-intl';

const btnClass =
  'btn btn-icon d-flex btn-custom btn-icon-muted btn-active-light btn-active-color-primary'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'

const Navbar = () => {

  const intl = useIntl();

  const companies = useSelector(state => state.persistedReducer.companies.companies);
  const selectedCompany = useSelector(state => state.persistedReducer.selectedCompany.selectedCompany)

  const currentUser = useSelector(state => state.persistedReducer.user.user);

  return (
    <div className='app-navbar flex-shrink-0 gap-5'>
      <div className={clsx('app-navbar-item align-items-stretch')}>
        {/* <Search /> */}
      </div>

      <div className={clsx('app-navbar-item p-2')}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={clsx(btnClass, 'gap-2')}
        >
          <KTIcon iconName='tree' className={btnIconClass} />
          <p className='m-0 pe-2'>
            {selectedCompany ? companies?.find(c => c?.id == selectedCompany)?.name : intl.formatMessage({ id: 'COMPANY.NAME' })}
          </p>
        </div>
        <HeaderNotificationsMenu />
      </div>
      {/* 
      <div className={clsx('app-navbar-item p-2')}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom p-2')} />
      </div> */}

      <div className={clsx('app-navbar-item p-2')}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={currentUser?.employee?.image_name && currentUser?.employee?.image_name.length > 0 ? currentUser?.employee?.image_name : toAbsoluteUrl('/media/avatars/blank.png')} alt='user' />
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  )
}

export { Navbar }
