import { ID, Response } from "../../../../../../_metronic/helpers";
export type UserMigrationInformation = {
  id?: ID;
  employee_id?: string | null;
  trp_serial_number?: string;
  trp_permit_reason?: string;
  trp_permit_date?: string | null;
  trp_valid_date?: string | null;
  trp_issuer?: string;
  prp_serial_number?: string;
  prp_permit_date?: string | null;
  prp_valid_date?: string | null;
  prp_issuer?: string;
  wp_serial_number?: string;
  wp_permit_date?: string | null;
  wp_valid_date?: string | null;
};

export type UserMigrationInformationQueryResponse = Response<
  Array<UserMigrationInformation>
>;

export const initialUserMigrationInformation: UserMigrationInformation = {
  employee_id: null,
  trp_serial_number: "",
  trp_permit_reason: "",
  trp_permit_date: null,
  trp_valid_date: null,
  trp_issuer: "",
  prp_serial_number: "",
  prp_permit_date: null,
  prp_valid_date: null,
  prp_issuer: "",
  wp_serial_number: "",
  wp_permit_date: null,
  wp_valid_date: null,
};
