import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as  Yup from 'yup';
import { initialCandidate } from './core/_models';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import clsx from 'clsx';
import axios from 'axios';
import { toast } from 'react-toastify';
import { CandidateistLoading } from './components/loading/CandidateistLoading';
import { createCandidate, getCandidateId, updateCandidate } from './core/_requests';
import { isNotEmpty } from '../../../../../_metronic/helpers';
import { FileUploader } from "react-drag-drop-files";
import { convertToBase64 } from '../../../../../utilities/convertToBase64';
import { MultiSelect } from "react-multi-select-component";
import { useIntl } from 'react-intl';
import { customStyles } from '../../../../../utilities/customStyles';
import InputMask from 'react-input-mask';

const fileTypes = ["PDF"];

const CreateCandidateOrUpdatePage = () => {

    const intl = useIntl();
    const [checkSelect, setCheckSelect] = useState(false);
    const phoneRegExp = /^\(0\d{2}\)-\d{3}-\d{2}-\d{2}$/;

    const candidateSchema = Yup.object().shape({
        name: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),
        email: Yup.string()
            .email(`${intl.formatMessage({ id: 'ERROR.WRONG.EMAIL.FORMAT' })}`)
            .min(6, `${intl.formatMessage({ id: 'AUTH.VALIDATION.MIN_LENGTH_FIELD' })} 6`)
            .max(50, `${intl.formatMessage({ id: 'AUTH.VALIDATION.MAX_LENGTH_FIELD' })} 50`)
            .required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        phone: Yup.string().matches(phoneRegExp, `${intl.formatMessage({ id: "PHONE_NOT_VALID" })}`).required(`${intl.formatMessage({ id: "REQUIRED" })}`),
        currentCandidate: checkSelect ? Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`) : Yup.string().nullable(),
        vacancy_ids: Yup.array().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),
        cv: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`)
    })

    const baseUrl = process.env.REACT_APP_BASE_URL;

    const navigate = useNavigate();

    const { id } = useParams();
    const { pathname } = useLocation();

    const [vacancies, setVacancies] = useState([]);
    const [file, setFile] = useState(null);

    const [candidates, setCandidates] = useState([]);
    const [candidateorEditAndCreate] = useState({

        name: initialCandidate.name,
        email: initialCandidate.email,
        phone: initialCandidate.phone,
        vacancy_ids: initialCandidate.vacancy_ids,
        cv: initialCandidate.cv,
        currentCandidate: initialCandidate.currentCandidate
    });


    const formik = useFormik({
        initialValues: candidateorEditAndCreate,
        validationSchema: candidateSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);

            try {
                if (isNotEmpty(values.id)) {
                    const result = await updateCandidate({ ...values, vacancy_ids: values.vacancy_ids?.map(v => v?.value) });

                    if (typeof result === "string") {
                        throw new Error(result)
                    } else {
                        toast.success(`${intl.formatMessage({ id: 'SUCCESS.MESSAGE.UPDATE' })}`);
                        navigate(-1);
                    }
                } else {

                    const result = await createCandidate({ ...values, vacancy_ids: values.vacancy_ids?.map(v => v?.value) });
                    if (typeof result === "string") {
                        throw new Error(result)
                    } else {
                        toast.success(`${intl.formatMessage({ id: "SUCCESS.MESSAGE.ADD" })}`);
                        navigate(-1);
                    }
                }
            } catch (error) {
                toast.error(error?.message, {
                    autoClose: 10000
                })
            } finally {
                setSubmitting(false);
            }
        }
    });


    const getAllCandidates = async () => {
        try {
            const request = await axios.get(`${baseUrl}/api/candidates`);
            if (request.status == 200) {
                setCandidates(request.data);

            } else {
                throw new Error(request.data);
            }
        } catch (error) {
            if (error?.response?.status == 401) {
                localStorage.removeItem("token");
                navigate("/auth/login");
            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                });
            }
        }
    }
    const handleChange = async (file) => {
        const result = await convertToBase64(file);
        formik.setFieldValue('cv', result.split(',')[1])
        setFile(file);
    };

    const getCandidate = async () => {

        const result = await getCandidateId(id);

        formik.setFieldValue('name', result.data.name);
        formik.setFieldValue('email', result.data.email);
        formik.setFieldValue('phone', result.data.phone);
        formik.setFieldValue('vacancy_ids', result.data.connections ? result.data.connections?.map(v => {
            return {
                label: v?.title,
                value: v?.id
            }
        }) : null)

        formik.setFieldValue('cv', result?.data?.cv)
    }

    const getAllVacancies = async () => {
        try {
            const request = await axios.get(`${baseUrl}/api/vacancies`);

            if (request.status == 200) {

                setVacancies(request.data?.filter(f => f?.status != 1)?.map(d => {
                    return {
                        value: d?.id,
                        label: d?.title
                    }
                }));
            } else {
                throw new Error(request.data);
            }
        } catch (error) {
            if (error?.response?.status == 401) {
                localStorage.removeItem('token');
                navigate('/auth/login');

            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                });
            }
        }
    }


    useEffect(() => {
        getAllVacancies();
        getAllCandidates();
        if (id) {
            getCandidate();
        }
    }, [id])


    return (
        <div className="card mb-5 mb-xl-10">
            <div
                className="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_account_profile_details"
                aria-expanded="true"
                aria-controls="kt_account_profile_details"
            >
                <div className="card-title m-0">
                    <h3 className="fw-bold m-0">{pathname.includes('view') ? intl.formatMessage({ id: 'VIEW.CANDIDATE' }) : id ? intl.formatMessage({ id: "UPDATE.CANDIDATE" }) : intl.formatMessage({ id: "CREATE.CANDIDATE" })}</h3>
                </div>
            </div>
            <div id="kt_account_settings_profile_details" className="collapse show">
                <form
                    id="kt_account_profile_details_form"
                    className="form fv-plugins-bootstrap5 fv-plugins-framework"
                    onSubmit={formik.handleSubmit}
                    noValidate
                >
                    <div className="card-body border-top p-9">
                        <div className='d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch'>
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2">
                                <label className={`col-form-label fw-semibold fs-6 ${pathname.includes('view') == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'VACANCY' })}
                                </label>

                                <div className="fv-row fv-plugins-icon-container">

                                    {pathname.includes('view') ? <p>{formik.values.vacancy_ids?.map(v => v?.label).join(',')}</p> : <MultiSelect hasSelectAll={false} placeholder={intl.formatMessage({ id: 'SELECT' })} options={vacancies} styles={customStyles} value={formik.values.vacancy_ids} onChange={opt => {
                                        formik.setFieldValue('vacancy_ids', opt);
                                    }} />}
                                    {formik.touched.vacancy_ids && formik.errors.vacancy_ids && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.vacancy_ids}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2 ">
                                <label className={`col-form-label fw-semibold fs-6 ${pathname.includes('view') == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'NAMEE' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {pathname.includes('view') ? <p>{formik.values.name}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'NAMEE' })}
                                        disabled={checkSelect}
                                        {...formik.getFieldProps("name")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.name && formik.errors.name,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.name && !formik.errors.name,
                                            }
                                        )}
                                    />}

                                    {formik.touched.name && formik.errors.name && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.name}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2">
                                <label className={`col-form-label fw-semibold fs-6`}>
                                    {intl.formatMessage({ id: "AVAILABLE.CANDIDATES" })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {pathname.includes('view') ? <p>{formik.values.name}</p> : <select
                                        {...formik.getFieldProps("currentCandidate")}

                                        onChange={(e) => {
                                            if (e.target.value == "") {
                                                formik.setFieldValue('currentCandidate', null);
                                                formik.setFieldValue('name', '');
                                                formik.setFieldValue('email', '');
                                                formik.setFieldValue('phone', '');
                                                setCheckSelect(false);

                                            } else {
                                                formik.setFieldValue('currentCandidate', e.target.value);
                                                const findCandidate = candidates?.find(c => c?.id == e.target.value);
                                                formik.setFieldValue('name', findCandidate?.name);
                                                formik.setFieldValue('email', findCandidate?.email);
                                                formik.setFieldValue('phone', findCandidate?.phone);
                                                setCheckSelect(true);
                                            }

                                        }}
                                        className={clsx(
                                            "form-select form-select-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.currentCandidate && formik.errors.currentCandidate,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.currentCandidate && !formik.errors.currentCandidate,
                                            }
                                        )}
                                        data-kt-select2="true"
                                        data-placeholder="Select "
                                        data-allow-clear="true"
                                        disabled={formik.isSubmitting}
                                    >
                                        <option value="">{intl.formatMessage({ id: "SELECT" })}</option>

                                        {candidates.length > 0 && candidates.map(candidate => (
                                            <option key={candidate?.id} value={candidate?.id}>{candidate?.name}</option>
                                        ))}

                                    </select>}

                                    {formik.touched.currentCandidate && formik.errors.currentCandidate && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.currentCandidate}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2">
                                <label className={`col-form-label fw-semibold fs-6 ${pathname.includes('view') == false && 'required'}`}>
                                    {intl.formatMessage({ id: "EMAILL" })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {pathname.includes('view') ? <p>{formik.values.email}</p> : <input
                                        type="email"
                                        placeholder={intl.formatMessage({ id: "EMAILL" })}
                                        disabled={checkSelect}
                                        {...formik.getFieldProps("email")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.email && formik.errors.email,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.email && !formik.errors.email,
                                            }
                                        )}
                                    />}


                                    {formik.touched.email && formik.errors.email && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.email}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2">
                                <label className={`col-form-label fw-semibold fs-6 ${pathname.includes('view') == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'NUMBER' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {pathname.includes('view') ? <p>{formik.values.phone}</p> : <InputMask mask="(099)-999-99-99"
                                        type="text"
                                        placeholder={intl.formatMessage({ id: "NUMBER" })}
                                        disabled={checkSelect}
                                        {...formik.getFieldProps("phone")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.phone && formik.errors.phone,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.phone && !formik.errors.phone,
                                            }
                                        )}
                                    />}


                                    {formik.touched.phone && formik.errors.phone && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.phone}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>
                        <div className='d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch'>

                            <div className="d-flex flex-column gap-2 mb-6 col-lg-5 col-12 col-md-5">
                                <label className={`col-form-label fw-semibold fs-6 ${pathname.includes('view') == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'CV' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">

                                    {pathname.includes('view') == false ? <FileUploader handleChange={handleChange} name="file" types={fileTypes} /> : <embed src={formik.values.cv} width="150px" height="150px" />}

                                    {formik.touched.cv && formik.errors.cv && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.cv}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <Link to=""
                            type="reset"
                            onClick={() => navigate(-1)}
                            disabled={formik.isSubmitting}
                            className="btn btn-light btn-active-light-primary me-2"
                        >
                            {intl.formatMessage({ id: "DISCARD" })}
                        </Link>
                        {pathname.includes('view') == false && <button
                            type="submit"
                            disabled={
                                formik.isSubmitting ||
                                !formik.isValid ||
                                !formik.touched
                            }
                            className="btn btn-primary"
                            id="kt_account_profile_details_submit"
                        >
                            <span className="indicator-label">{formik.isSubmitting ? intl.formatMessage({ id: "PLEASE.WAIT" }) : intl.formatMessage({ id: 'AUTH.GENERAL.SUBMIT_BUTTON' })}</span>

                        </button>}
                    </div>
                </form>
                {(formik.isSubmitting) && <CandidateistLoading />}
            </div>
        </div>
    )
}

export default CreateCandidateOrUpdatePage
