import { useState, useEffect, forwardRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputMask from 'react-input-mask';
import { initialArmyInfo } from './components/core/_models'
import { createArmyInfo, updateArmyInfo } from "./components/core/_requests";
import clsx from "clsx";
import { isNotEmpty } from '../../../../_metronic/helpers';
import { UsersListLoading } from "./components/loading/UsersListLoading"
import { getArmyInfoById } from './components/core/_requests';
import DatePicker from "react-datepicker";
import moment from "moment";
import { useQueryResponse } from "./components/core/QueryResponseProvider";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";


const CreateAndUpdatePage = ({ setShowPage, setShowView, showView }) => {

    const intl = useIntl();

    const [options, setOptions] = useState([])
    const { id } = useParams();
    const MaskedInput = forwardRef(({ value, onChange, onClick }, ref) => (
        <div className="ui input" onClick={onClick}>
            <InputMask ref={ref} mask="99/99/9999" placeholder="dd/mm/yyyy" value={value ? value : 'dd/mm/yyyy'} onChange={onChange} />
        </div>
    ));
    const baseUrl = process.env.REACT_APP_BASE_URL;

    const selectedLanguage = useSelector(state => state.persistedReducer.selectedLanguage.selectedLanguage)
    const user = useSelector(state => state.persistedReducer.user.user)
    const { selectId, setSelectId } = useQueryResponse();
    const editArmyInfoSchema = Yup.object().shape({
        employee_id: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

        group_id: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        category_id: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        staff_id: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        rank_id: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        specialty_acc: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

        fitness_service: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),


        registration_service: Yup.string()
            .required(`${intl.formatMessage({ id: "REQUIRED" })}`),


        registration_date: Yup.string()
            .required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        general: Yup.string()
            .required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

        special: Yup.string()
            .required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

        no_official: Yup.string()
            .required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        additional_information: Yup.string()
            .required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        completion_date: Yup.string()
            .required(`${intl.formatMessage({ id: "REQUIRED" })}`),

    });

    const queryClient = useQueryClient()
    const navigate = useNavigate();

    useEffect(() => {
        setOptions([
            {
                value: 1,
                label: intl.formatMessage({ id: 'USEFUL' })
            },
            {
                value: 2,
                label: intl.formatMessage({ id: 'USELESS' })
            },
            {
                value: 3,
                label: intl.formatMessage({ id: 'LIMITED.USEFUL' })
            }
        ])
    }, [selectedLanguage])

    const [employees, setEmployees] = useState([]);
    const [militaryGroups, setMilitaryGroups] = useState([]);
    const [ranks, setRanks] = useState([]);

    const [staffs, setStaffs] = useState([]);
    const [categories, setCategories] = useState([{ value: 1, label: `${intl.formatMessage({ id: 'CATEGORY' })} 1` }, { value: 2, label: `${intl.formatMessage({ id: 'CATEGORY' })} 2` }])
    const [armyForEdit] = useState({
        employee_id: id ? id : initialArmyInfo.employee_id,
        group_id: initialArmyInfo.group_id,
        category_id: initialArmyInfo.category_id,
        staff_id: initialArmyInfo.staff_id,
        rank_id: initialArmyInfo.rank_id,
        specialty_acc: initialArmyInfo.specialty_acc,
        fitness_service: initialArmyInfo.fitness_service,
        registration_service: initialArmyInfo.registration_service,
        registration_date: initialArmyInfo.registration_date,
        general: initialArmyInfo.registration_date,
        special: initialArmyInfo.special,
        no_official: initialArmyInfo.no_official,
        additional_information: initialArmyInfo.additional_information,
        completion_date: initialArmyInfo.completion_date,

    });

    const getArmy = useMutation(() => { }, {
        onSuccess: () => queryClient.invalidateQueries(['army-info'])
    })
    const formik = useFormik({

        initialValues: armyForEdit,
        validationSchema: editArmyInfoSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            try {
                if (isNotEmpty(values.id)) {
                    const result = await updateArmyInfo({ ...values, update_user_id: user?.id, registration_date: moment(values.registration_date).format('YYYY-MM-DD'), completion_date: moment(values.completion_date).format('YYYY-MM-DD') });

                    if (typeof result === "string") {
                        throw new Error(result)
                    } else {
                        toast.success(`${intl.formatMessage({ id: 'SUCCESS.MESSAGE.UPDATE' })}`);
                        setShowPage(false);
                        getArmy.mutateAsync();
                    }
                } else {
                    const result = await createArmyInfo({ ...values, update_user_id: user?.id, insert_user_id: user?.id, registration_date: moment(values.registration_date).format('YYYY-MM-DD'), completion_date: moment(values.completion_date).format('YYYY-MM-DD') });

                    if (typeof result === "string") {
                        throw new Error(result)
                    } else {
                        toast.success(`${intl.formatMessage({ id: 'SUCCESS.MESSAGE.ADD' })}`);
                        setShowPage(false);
                        getArmy.mutateAsync();
                    }
                }
            } catch (ex) {
                toast.error(ex?.message, {
                    autoClose: 10000
                })
            } finally {
                setSubmitting(true);
                setSelectId(null);
            }
        },
    });


    const getArmyInfo = async () => {
        const result = await getArmyInfoById(selectId);
        formik.setValues({ ...result, group_id: result?.group?.id, rank_id: result?.rank?.id, staff_id: result?.staff?.id, category_id: result?.category?.id, employee_id: result?.employee?.id })
    }

    const getEmployeeByIdMilitaryInfos = async (id) => {
        try {
            const request = await axios.get(`${baseUrl}/api/employees/${id}/military-infos`);

            if (request.status == 200) {
                const familyInfo = request.data?.find(fI => fI?.id == selectId);

                if (familyInfo) {

                    formik.setValues({ ...familyInfo, group_id: familyInfo?.group?.id, rank_id: familyInfo?.rank?.id, staff_id: familyInfo?.staff?.id, category_id: familyInfo?.category?.id, employee_id: familyInfo?.employee?.id })
                }
            } else {
                throw new Error(request?.data)
            }
        } catch (error) {

            if (error?.response?.status == 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')
            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }

    const getAllEmployess = async () => {
        try {
            const request = await axios.get(`${baseUrl}/api/employees`);

            if (request.status === 200) {
                setEmployees(request.data)
            } else {
                throw new Error(request.data)
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')
            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }

    const getAllRegisterGroups = async () => {
        try {
            const request = await axios.get(`${baseUrl}/api/military-groups`);

            if (request.status === 200) {
                setMilitaryGroups(request.data)
            } else {
                throw new Error(request.data)
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')
            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }

    const getAllRanks = async () => {
        try {
            const request = await axios.get(`${baseUrl}/api/military-ranks`);

            if (request.status === 200) {
                setRanks(request.data)
            } else {
                throw new Error(request.data)
            }
        } catch (error) {
            if (error?.response?.status == 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')
            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }

    const getAllStaffs = async () => {
        try {
            const request = await axios.get(`${baseUrl}/api/military-staffs`);

            if (request.status === 200) {
                setStaffs(request.data)
            } else {
                throw new Error(request.data)
            }
        } catch (error) {

            if (error?.response?.status == 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')
            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }


    useEffect(() => {
        if (id) {
            getEmployeeByIdMilitaryInfos(id);
        } else if (selectId) {
            getArmyInfo()

        }
        getAllEmployess();

        getAllRegisterGroups();
        getAllRanks();
        getAllStaffs();
    }, [selectId, id])


    return (

        <div className="card mb-5 mb-xl-10">
            <div
                className="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_account_profile_details"
                aria-expanded="true"
                aria-controls="kt_account_profile_details"
            >
                <div className="card-title m-0">
                    <h3 className="fw-bold m-0">{showView ? intl.formatMessage({ id: "VIEW.ARMY.INFO" }) : selectId ? intl.formatMessage({ id: 'UPDATE.ARMY.INFO' }) : intl.formatMessage({ id: 'CREATE.ARMY.INFO' })}</h3>
                </div>
            </div>
            <div id="kt_account_settings_profile_details" className="collapse show">
                <form
                    id="kt_account_profile_details_form"
                    className="form fv-plugins-bootstrap5 fv-plugins-framework"
                    onSubmit={formik.handleSubmit}
                    noValidate
                >
                    <div className="card-body border-top p-9">

                        <div className="d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch">
                            {id ? null : <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: "EMPLOYEE" })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.employee?.firstname + " " + formik.values?.employee?.lastname}</p> : <select
                                        {...formik.getFieldProps("employee_id")}
                                        className={clsx(
                                            "form-select form-select-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.employee_id && formik.errors.employee_id,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.employee_id && !formik.errors.employee_id,
                                            }
                                        )}
                                        data-kt-select2="true"
                                        data-allow-clear="true"
                                        disabled={formik.isSubmitting}
                                    >
                                        <option value="">{intl.formatMessage({ id: 'SELECT' })}</option>
                                        {employees.length > 0 && employees.map(employee => (
                                            <option key={employee?.id} value={employee?.id}>{employee?.firstname + " " + employee?.lastname}</option>
                                        ))}
                                    </select>}

                                    {formik.touched.employee_id && formik.errors.employee_id && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.employee_id}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>}
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'REGISTRATION.GROUP' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values.group?.label}</p> : <select
                                        {...formik.getFieldProps("group_id")}
                                        className={clsx(
                                            "form-select form-select-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.group_id && formik.errors.group_id,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.group_id && !formik.errors.group_id,
                                            }
                                        )}
                                        data-kt-select2="true"
                                        data-placeholder="Select "
                                        data-allow-clear="true"
                                        disabled={formik.isSubmitting}
                                    >
                                        <option value="">{intl.formatMessage({ id: 'SELECT' })}</option>
                                        {militaryGroups.length > 0 && militaryGroups.map(gr => (
                                            <option key={gr?.id} value={gr?.id}>{gr?.label}</option>
                                        ))}


                                    </select>}

                                    {formik.touched.group_id && formik.errors.group_id && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.group_id}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'REGISTRATION.CATEGORY' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.category?.label}</p> : <select
                                        {...formik.getFieldProps("category_id")}
                                        className={clsx(
                                            "form-select form-select-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.category_id && formik.errors.category_id,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.category_id && !formik.errors.category_id,
                                            }
                                        )}
                                        data-kt-select2="true"
                                        data-placeholder="Select "
                                        data-allow-clear="true"
                                        disabled={formik.isSubmitting}
                                    >
                                        <option value="">{intl.formatMessage({ id: 'SELECT' })}</option>
                                        {categories.length > 0 && categories.map(category => (
                                            <option key={category?.value} value={category?.value}>{category?.label}</option>
                                        ))}


                                    </select>}

                                    {formik.touched.category_id && formik.errors.category_id && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.category_id}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'STAFF' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.staff?.label}</p> : <select
                                        {...formik.getFieldProps("staff_id")}
                                        className={clsx(
                                            "form-select form-select-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.staff_id && formik.errors.staff_id,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.staff_id && !formik.errors.staff_id,
                                            }
                                        )}
                                        data-kt-select2="true"
                                        data-placeholder="Select "
                                        data-allow-clear="true"
                                        disabled={formik.isSubmitting}
                                    >
                                        <option value="">{intl.formatMessage({ id: 'SELECT' })}</option>
                                        {staffs.length > 0 && staffs.map(staff => (
                                            <option key={staff?.id} value={staff?.id}>{staff?.label}</option>
                                        ))}
                                    </select>}

                                    {formik.touched.staff_id && formik.errors.staff_id && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.staff_id}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>


                        <div className="d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch">

                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'MILITARY.ACCOUNTING.MAJOR' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.specialty_acc}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: "MILITARY.ACCOUNTING.MAJOR" })}
                                        {...formik.getFieldProps("specialty_acc")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.specialty_acc && formik.errors.specialty_acc,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.specialty_acc && !formik.errors.specialty_acc,
                                            }
                                        )}
                                    />}
                                    {formik.touched.specialty_acc && formik.errors.specialty_acc && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.specialty_acc}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'MILITARY.FITNESS.FOR.SERVICE' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.fitness_service == 1 ? intl.formatMessage({ id: 'USEFUl' }) : formik.values?.fitness_service == 2 ? intl.formatMessage({ id: 'USELESS' }) : intl.formatMessage({ id: "LIMITED.USEFUL" })}</p> : <select
                                        {...formik.getFieldProps("fitness_service")}
                                        className={clsx(
                                            "form-select form-select-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.fitness_service && formik.errors.fitness_service,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.fitness_service && !formik.errors.fitness_service,
                                            }
                                        )}
                                        data-kt-select2="true"
                                        data-placeholder="Select "
                                        data-allow-clear="true"
                                        disabled={formik.isSubmitting}
                                    >
                                        <option value="">{intl.formatMessage({ id: 'SELECT' })}</option>

                                        {options.length > 0 && options.map(opt => (
                                            <option key={opt?.value} value={opt?.value}>{opt?.label}</option>
                                        ))}

                                    </select>}
                                    {formik.touched.fitness_service && formik.errors.fitness_service && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.fitness_service}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'PLACE.OF.MILITARY.REGISTRATION.SERVICE' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.registration_service}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'PLACE.OF.MILITARY.REGISTRATION.SERVICE' })}
                                        {...formik.getFieldProps("registration_service")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.registration_service && formik.errors.registration_service,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.registration_service && !formik.errors.registration_service,
                                            }
                                        )}
                                    />}
                                    {formik.touched.registration_service && formik.errors.registration_service && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.registration_service}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'DATE.OF.MILITARY.REGISTRATION' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{moment(formik.values.registration_date).format('DD-MM-YYYY')}</p> : <div className="w-100">
                                        <DatePicker
                                            wrapperClassName="datePicker"
                                            dateFormat="dd/MM/yyyy"
                                            placeholder="-- -- --"
                                            name="registration_date"
                                            customInput={<MaskedInput />}
                                            selected={formik.values.registration_date && new Date(formik.values.registration_date)}
                                            onChange={(opt) => formik.setFieldValue("registration_date", opt)}
                                            showDayDropdown={true}
                                            showMonthDropdown={true}
                                            showYearDropdown={true}
                                            shouldCloseOnSelect={true}
                                            calendarStartDay={1}
                                            scrollableYearDropdown={false}
                                        />
                                    </div>}

                                    {formik.touched.registration_date && formik.errors.registration_date && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.registration_date}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>
                        <div className="d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch">
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: "SPECIAL" })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values.special}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'SPECIAL' })}
                                        {...formik.getFieldProps("special")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.special && formik.errors.special,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.special && !formik.errors.special,
                                            }
                                        )}
                                    />}
                                    {formik.touched.special && formik.errors.special && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.special}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'RECORD.ABSENCE' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values.no_official}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: "RECORD.ABSENCE" })}
                                        {...formik.getFieldProps("no_official")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.no_official && formik.errors.no_official,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.no_official && !formik.errors.no_official,
                                            }
                                        )}
                                    />}
                                    {formik.touched.no_official && formik.errors.no_official && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.no_official}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: "ADDITIONAL.INFORMATION" })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values.additional_information}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: "ADDITIONAL.INFORMATION" })}
                                        {...formik.getFieldProps("additional_information")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.additional_information && formik.errors.additional_information,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.additional_information && !formik.errors.additional_information,
                                            }
                                        )}
                                    />}
                                    {formik.touched.additional_information && formik.errors.additional_information && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.additional_information}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'FILLING.DATE' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{moment(formik.values.completion_date).format('DD-MM-YYYY')}</p> : <div className="w-100">
                                        <DatePicker
                                            wrapperClassName="datePicker"
                                            dateFormat="dd/MM/yyyy"
                                            placeholder="-- -- --"
                                            name="completion_date"
                                            selected={formik.values.completion_date && new Date(formik.values.completion_date)}
                                            onChange={(opt) => formik.setFieldValue("completion_date", opt)}
                                            showDayDropdown={true}
                                            showMonthDropdown={true}
                                            customInput={<MaskedInput />}
                                            showYearDropdown={true}
                                            shouldCloseOnSelect={true}
                                            calendarStartDay={1}
                                            scrollableYearDropdown={false}
                                        />
                                    </div>}

                                    {formik.touched.completion_date && formik.errors.completion_date && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.completion_date}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch">
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'MILITARY.RANK' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values.rank?.label}</p> : <select
                                        {...formik.getFieldProps("rank_id")}
                                        className={clsx(
                                            "form-select form-select-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.rank_id && formik.errors.rank_id,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.rank_id && !formik.errors.rank_id,
                                            }
                                        )}
                                        data-kt-select2="true"
                                        data-placeholder="Select "
                                        data-allow-clear="true"
                                        disabled={formik.isSubmitting}
                                    >
                                        <option value="">{intl.formatMessage({ id: 'SELECT' })}</option>
                                        {ranks.length > 0 && ranks.map(rank => (
                                            <option key={rank?.id} value={rank?.id}>{rank?.label}</option>
                                        ))}

                                    </select>}

                                    {formik.touched.rank_id && formik.errors.rank_id && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.rank_id}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>


                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-3">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'TOTAL.TEAM.NO' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values.general}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'TOTAL.TEAM.NO' })}
                                        {...formik.getFieldProps("general")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.general && formik.errors.general,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.general && !formik.errors.general,
                                            }
                                        )}
                                    />}
                                    {formik.touched.general && formik.errors.general && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.general}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button
                            type="reset"
                            onClick={() => {
                                setShowPage(false)
                                setSelectId(null);
                                setShowView(false)
                            }}
                            disabled={formik.isSubmitting}
                            className="btn btn-light btn-active-light-primary me-2"
                        >
                            {intl.formatMessage({ id: "DISCARD" })}
                        </button>
                        {showView == false && <button
                            type="submit"
                            disabled={
                                formik.isSubmitting ||
                                !formik.isValid ||
                                !formik.touched
                            }
                            className="btn btn-primary"
                            id="kt_account_profile_details_submit"
                        >
                            <span className="indicator-label">{formik.isSubmitting ? intl.formatMessage({ id: "PLEASE.WAIT" }) : intl.formatMessage({ id: 'AUTH.GENERAL.SUBMIT_BUTTON' })}</span>
                        </button>}
                    </div>
                </form>
                {(formik.isSubmitting) && <UsersListLoading />}
            </div>
        </div>


    )
}

export default CreateAndUpdatePage

