import { PermissionInfoCell } from "./PermissionInfoCell";
import { PermissionActionsCell } from './PermissionActionsCell'
import { PermissionCustomHeader } from "./PermissionCustomHeader";
import moment from 'moment';

const permissionColumns = [
  {
    Header: (props) => (
      <PermissionCustomHeader
        tableProps={props}
        title="ID"
        className="min-w-125px"
      />
    ),
    id: "id",
    Cell: ({ ...props }) => (
      <PermissionInfoCell permission={props.data[props.row.index]?.id} />
    ),
  },

  {
    Header: (props) => (
      <PermissionCustomHeader
        tableProps={props}
        title="ASA"
        className="min-w-125px"
      />
    ),
    id: "A.S.A",
    Cell: ({ ...props }) => (
      <PermissionInfoCell permission={`${props.data[props.row.index]?.employee_id?.firstname} ${props.data[props.row.index]?.employee_id?.lastname}`} />
    ),
  },
  {
    Header: (props) => (
      <PermissionCustomHeader
        tableProps={props}
        title="Tipi"
        className="min-w-125px"
      />
    ),
    id: "type",
    Cell: ({ ...props }) => (
      <PermissionInfoCell permission={props.data[props.row.index]?.action_type_id?.label} />
    ),
  },
  {
    Header: (props) => (
      <PermissionCustomHeader
        tableProps={props}
        title="Tarix"
        className="min-w-125px"
      />
    ),
    id: "date",
    Cell: ({ ...props }) => (
      <PermissionInfoCell permission={moment(props.data[props.row.index]?.action_date).format('DD-MM-YYYY')} />
    ),
  },
  {
    Header: (props) => (
      <PermissionCustomHeader
        tableProps={props}
        title="Saat"
        className="min-w-125px"
      />
    ),
    id: "Time",
    Cell: ({ ...props }) => (
      <PermissionInfoCell permission={props.data[props.row.index]?.action_time} />
    ),
  },

  {
    Header: (props) => (
      <PermissionCustomHeader
        tableProps={props}
        title="Actions"
        className="text-end min-w-100px"
      />
    ),
    id: "actions",
    Cell: ({ ...props }) => (
      <PermissionActionsCell id={props.data[props.row.index].id} />
    ),
  },
];

export { permissionColumns };
