import { toAbsoluteUrl } from "../../../../../../../_metronic/helpers";
import { useIntl } from 'react-intl';

const UserInfoCell = ({
  user,
  status = null,
  type = null
}) => {

  const intl = useIntl();

  return (
    <div className="d-flex align-items-center">
      <div className="d-flex flex-column">
        {type && type === "image" ? (
          <img src={user.length > 0 ? user : toAbsoluteUrl('/media/avatars/blank.png')} width={40} height={40} className="object-fit-cover rounded-circle" alt="alt" />
        ) : (
          <p className={` mb-1  ${status !== null ? status === true ? 'badge badge-light-success fw-bold p-2' : 'badge badge-light-danger fw-bold p-2' : 'text-gray-600'}`}>{status !== null ? status === true ? intl.formatMessage({ id: 'ECOMMERCE.COMMON.ACTIVE' }) : intl.formatMessage({ id: 'NO.ACTIVE' }) : user}</p>
        )}
      </div>
    </div>
  );
};

export { UserInfoCell };
