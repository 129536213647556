// @ts-nocheck
import { UserInfoCell } from "./UserInfoCell";
import { UserActionsCell } from "./UserActionsCell";
import { UserCustomHeader } from "./UserCustomHeader";

const usersColumns = [
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title="ID" className="min-w-125px" />
    ),
    id: 1,
    accessor: "id",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.id} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Şəkil"
        type="image"
        className="min-w-125px"
      />
    ),
    id: 2,
    accessor: "image_name",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.image_name} type="image" />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Adı"
        className="min-w-125px"
      />
    ),
    id: 3,
    accessor: "firstname",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index]?.firstname} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Soyadı"
        className="min-w-125px"
      />
    ),
    id: 4,
    accessor: "lastname",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index].lastname} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Ata adı"
        className="min-w-125px"
      />
    ),
    id: 5,
    accessor: "father_name",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index].father_name} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="İşçi nömrəsi"
        className="min-w-125px"
      />
    ),
    id: 6,
    accessor: "mob_tel",
    Cell: ({ ...props }) => (
      <UserInfoCell user={props.data[props.row.index].mob_tel} />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Statusu"
        className="min-w-125px"
      />
    ),
    id: 7,
    accessor: "status",
    Cell: ({ ...props }) => (
      <UserInfoCell
        status={props.data[props.row.index].status}
      />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Actions"
        className="text-end min-w-100px"
      />
    ),
    id: 8,
    accessor: "actions",
    Cell: ({ ...props }) => (
      <UserActionsCell id={props.data[props.row.index].id} />
    ),
  },
];

export { usersColumns };
