import { useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import ReactToPrint from 'react-to-print'
import 'react-toastify/dist/ReactToastify.css'
import { useDownloadExcel } from 'react-export-table-to-excel'
import { KTIcon } from '../../../../../../../_metronic/helpers'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { CSVLink } from 'react-csv'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable';
import { useIntl } from 'react-intl';

const InterviewerToolButons = ({ printRef, setShow }) => {
  const intl = useIntl();
  const { filterUsers } = useQueryResponse()

  const [copy, setCopy] = useState({
    text: '',
    copied: false,
  })
  useEffect(() => {
    setCopy(copy => ({ ...copy, text: [...filterUsers.map((item) => Object.values(item))].join('->') }))
  }, [filterUsers])

  const { onDownload } = useDownloadExcel({
    currentTableRef: printRef.current,
    filename: 'Users table',
    sheet: 'Users',
  })

  const handleColumnVisibilityModal = () => {
    setShow(true)
  }

  const handleExportPDFTable = () => {
    const doc = new jsPDF();

    autoTable(doc, {
      head: [['ID', 'Adi', 'Email', 'Telefon']],
      body: [...filterUsers?.map((f, idx) => {
        return [idx + 1, f?.name, f?.email, f?.phone]
      })]
    });
    doc.save('candidate.pdf')
  }

  return (
    <div className='d-flex flex-column justify-content-center align-items-center gap-2 mt-2'>

      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <CopyToClipboard
          text={copy.text} style={{display:'flex',width:'100%', justifyContent:'center'}}
          onCopy={() => {
            setCopy({ ...copy, copied: true })
            toast.success('Copied')
          }}
        >
          <span>{intl.formatMessage({ id: 'COPY' })}</span>
        </CopyToClipboard>
      </button>

      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <ReactToPrint
          trigger={() => {
            return <span>{intl.formatMessage({ id: 'PRINT' })}</span>
          }}
          content={() => printRef.current}
        />
      </button>

      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        onClick={onDownload}
      >
        <KTIcon iconName='exit-up' className='fs-2' />
        {intl.formatMessage({ id: 'EXCEL' })}
      </button>
      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTIcon iconName='exit-up' className='fs-2' />
        <CSVLink data={filterUsers}>CSV</CSVLink>
      </button>
      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        onClick={handleExportPDFTable}

      >
        <KTIcon iconName='exit-up' className='fs-2' />
        PDF
      </button>

      <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        onClick={handleColumnVisibilityModal}
      >
        {intl.formatMessage({ id: 'COLUMN.VISIBILITY' })}
      </button>
    </div>
  )
}

export default InterviewerToolButons
