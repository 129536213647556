import axios, { AxiosResponse } from "axios";
import { ID, Response } from "../../../../../../_metronic/helpers";
import { UserCertification, UserCertificationQueryResponse } from "./_models";

const API_URL = process.env.REACT_APP_BASE_URL;

const USERS_CERTIFICATIONS_INFO_URL = `${API_URL}/api/certificates`;
const GET_USERS_CERTIFICATIONS_INFO_URL = `${API_URL}/api/certificates`;

const getUsersCertifications = (
  query: string
): Promise<UserCertificationQueryResponse> => {
  return axios
    .get(`${GET_USERS_CERTIFICATIONS_INFO_URL}?${query}`)
    .then((d: AxiosResponse<UserCertificationQueryResponse>) => {
      return d;
    })
    .catch(err => err)
};

const getUserCertificationById = (
  id: ID
): Promise<UserCertification | undefined> => {
  return axios
    .get(`${USERS_CERTIFICATIONS_INFO_URL}/${id}`)
    .then((response) => {
      return response.data;
    });
};

const createUserCertification = (
  user: UserCertification
): Promise<UserCertification | undefined> => {
  return axios
    .post(USERS_CERTIFICATIONS_INFO_URL, user)
    .then(
      (response: AxiosResponse<Response<UserCertification>>) => response.data
    )
    .catch(err => err?.response?.data?.message)
};

const updateUserCertification = (
  user: UserCertification
): Promise<UserCertification | undefined> => {
  return axios
    .put(`${USERS_CERTIFICATIONS_INFO_URL}/${user.id}`, user)
    .then(
      (response: AxiosResponse<Response<UserCertification>>) => { }
    )
    .catch(err => err?.response?.data?.message)
};

const deleteUserCertification = (userId: ID): Promise<void> => {
  return axios
    .delete(`${USERS_CERTIFICATIONS_INFO_URL}/${userId}`)
    .then(() => { });
};

const deleteUsersCertifications = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) =>
    axios.delete(`${USERS_CERTIFICATIONS_INFO_URL}/${id}`)
  );
  return axios.all(requests).then(() => { });
};

export {
  getUsersCertifications,
  getUserCertificationById,
  createUserCertification,
  updateUserCertification,
  deleteUserCertification,
  deleteUsersCertifications,
};
