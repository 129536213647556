import axios, { AxiosResponse } from "axios";
import { ID, Response } from "../../../../../../_metronic/helpers";
import { Vacancy, VacancyQueryResponse } from "./_models";

const baseUrl = process.env.REACT_APP_BASE_URL;
const VACANCY_URL = `${baseUrl}/api/vacancies`;
const GET_VACANCIES_URL = `${baseUrl}/api/vacancies`;

const getVacancies = (query: string): Promise<VacancyQueryResponse> => {
  return axios
    .get(`${GET_VACANCIES_URL}?${query}`)
    .then((d: AxiosResponse<VacancyQueryResponse>) => {
      return d;
    })
    .catch((err) => {
      return err;
    });
};

const getVacancyById = (id: ID): Promise<Vacancy | undefined> => {
  return axios
    .get(`${VACANCY_URL}/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => err?.response?.data?.message);
};

const createVacancy = (vacancy: Vacancy): Promise<Vacancy | undefined> => {
  return axios
    .post(VACANCY_URL, vacancy)
    .then((response: Response<Vacancy>) => response.data)
    .catch((err: any) => {
      return err?.response?.data?.message;
    });
};

const updateVacancy = (vacancy: Vacancy): Promise<Vacancy | undefined> => {
  return axios
    .put(`${VACANCY_URL}/${vacancy.id}`, vacancy)
    .then((response: Response<Vacancy>) => {})
    .catch((err: any) => {
      return err?.response?.data?.message;
    });
};

const deleteVacancy = (vacancyId: ID): Promise<void> => {
  return axios.delete(`${VACANCY_URL}/${vacancyId}`).then(() => {});
};

const deleteSelectedVacancies = (vacancyIds: Array<ID>): Promise<void> => {
  const requests = vacancyIds.map((id) => axios.delete(`${VACANCY_URL}/${id}`));
  return axios.all(requests).then(() => {});
};

export {
  getVacancies,
  getVacancyById,
  createVacancy,
  updateVacancy,
  deleteVacancy,
  deleteSelectedVacancies,
};
