import clsx from 'clsx'
import { useIntl } from 'react-intl';

const UserCustomHeader = ({ className, title, tableProps }) => {

  const intl = useIntl();
  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className
      )}
      style={{ cursor: 'pointer' }}
    >
      {title == "Actions" ? intl.formatMessage({ id: 'ACTIONS' }) :
        title == "ASA" ? intl.formatMessage({ id: "NSF" }) :
          title == "Direktorluq" ? intl.formatMessage({ id: 'DIRECTORATE' }) :
            title == "Departament" ? intl.formatMessage({ id: 'DEPARTMENT' }) :
              title == "Şöbə" ? intl.formatMessage({ id: "BRANCHH" }) :
                title == "Sahə / Bölmə" ? intl.formatMessage({ id: 'AREA.SECTION' }) :
                  title == "Vəzifəsi" ? intl.formatMessage({ id: 'POSITION' }) :
                    title}
    </th>
  )
}

export { UserCustomHeader } 
