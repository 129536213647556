import axios, { AxiosResponse } from "axios";
import { ID, Response } from "../../../../../../_metronic/helpers";
import { ArmyInfo, ArmyInfoQueryResponse } from "./_models";

const API_URL = process.env.REACT_APP_BASE_URL
const ARMY_INFO_URL = ` ${API_URL}/api/military-infos`;
const GET_ARMY_INFO_URL = ` ${API_URL}/api/military-infos`;

const getArmyInfos = (query: string): Promise<ArmyInfoQueryResponse> => {
  return axios
    .get(`${GET_ARMY_INFO_URL}?${query}`)
    .then((d: AxiosResponse<ArmyInfoQueryResponse>) => {
      return d.data;
    });
};

const getArmyInfoById = (id: ID): Promise<ArmyInfo | undefined> => {
  return axios.get(`${ARMY_INFO_URL}/${id}`).then((response) => {
    return response.data;
  });
};

const createArmyInfo = (user: ArmyInfo): Promise<ArmyInfo | undefined> => {
  return axios
    .post(ARMY_INFO_URL, user)
    .then((response: AxiosResponse<Response<ArmyInfo>>) => response.data)
    .then((response: Response<ArmyInfo>) => response.data)
    .catch(err => {
       return err?.response?.data?.message
    })
};

const updateArmyInfo = (user: ArmyInfo): Promise<ArmyInfo | undefined> => {
  return axios
    .put(`${ARMY_INFO_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<ArmyInfo>>) => response.data)
    .then((response: Response<ArmyInfo>) => response.data)
    .catch(err => {
      return err?.response?.data?.message
    })
};

const deleteArmyInfo = (userId: ID): Promise<void> => {
  return axios.delete(`${ARMY_INFO_URL}/${userId}`).then(() => {});
};

const deleteSelectedArmyInfoes = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${ARMY_INFO_URL}/${id}`));
  return axios.all(requests).then(() => {});
};

export {
  getArmyInfoById,
  getArmyInfos,
  createArmyInfo,
  updateArmyInfo,
  deleteArmyInfo,
  deleteSelectedArmyInfoes,
};
