import { ID, Response } from "../../../../../../_metronic/helpers";
export type User = {
  id?: ID;
  empOne?: string | null;
  position?: string | null;
  work_family: null | null;
  risk: string | null;
  plan_status_con: string | null;
  testimonial: string | null;
  tempEmp: string | null;
  oneZero: string | null;
  oneTwo: string | null;
  twoFour: string | null;
  leader: string | null;
  teamStatus: string | null;
};

export type UsersQueryResponse = Response<Array<User>>;

export const initialUser: User = {
  empOne: null,
  position: null,
  work_family: null,
  risk: "",
  plan_status_con: "",
  testimonial: "",
  tempEmp: null,
  oneZero: null,
  oneTwo: null,
  twoFour: null,
  leader: null,
  teamStatus: null,
};
