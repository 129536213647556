import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageTitle } from '../../../../_metronic/layout/core'
import { UsersListWrapper } from './users-list/UsersList'
import UserCreatePage from './users-list/UserCreatePage';
import { useIntl } from 'react-intl';

const poolBreadCrumb = [
  {
    title: 'Career planning',
    path: '/careers/career-planing-pool',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PoolPage = () => {
  const intl = useIntl();

  return (

    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='pool'
          element={
            <>
              <PageTitle breadcrumbs={poolBreadCrumb}>Hovuz</PageTitle>
              <UsersListWrapper />
            </>
          }
        />

        <Route
          path='pool/create'
          element={<UserCreatePage />}
        />

        <Route
          path='pool/update/:id'
          element={<UserCreatePage />}
        />


        <Route
          path='pool/view/:id'
          element={<UserCreatePage />}
        />
      </Route>
      <Route index element={<Navigate to='/careers/career-planing/pool' />} />
    </Routes>
  )
}

export default PoolPage
