import { useState, useMemo, useCallback, forwardRef, useRef } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import CaretUp from "../../../../../_metronic/assets/images/CaretUp.svg";
import moment from "moment";
import { useDownloadExcel } from 'react-export-table-to-excel'
import { customStyles } from "../../../../../utilities/customStyles";
import { KTIcon } from "../../../../../_metronic/helpers";

const TabelHeader = ({
  setShow,
  selectDate,
  setSelectDate,
  selectCompany,
  setSelectCompany,
  printRef
}) => {
  const [companies] = useState([
    {
      label: "Company 1",
      value: 1,
    },
    {
      label: "Company 2",
      value: 2,
    },
    {
      label: "Company 3",
      value: 3,
    },
  ]);

  const datePickerRef = useRef(null);

  const MyCustomCalendarIcon = useCallback(
    ({ onClick }) => (
      <button
        onClick={onClick}
        style={{
          outline: "none",
          background: "none",
          border: "none",
          cursor: "pointer",
        }}
        className="d-flex justify-content-center align-items-center"
      >
        <img src={CaretUp} alt="arrow down" />
      </button>
    ),
    []
  );

  const CustomInput = useMemo(
    () =>
      forwardRef(({ value, onClick }, ref) => (
        <div onClick={onClick} className="new-custom-input">
          <p onClick={onClick} style={{ color: "#98A2B3" }}>
            {selectDate != null
              ? moment(selectDate).format("DD-MM-YYYY")
              : "Select Date"}
          </p>
          <MyCustomCalendarIcon onClick={onClick} />
        </div>
      )),
    [selectDate]
  );

  const handleSearch = () => {
    if (selectCompany) {
      setShow(true);
    }
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: printRef.current,
    filename: 'Table',
    sheet: 'Table',
  })
  return (
    <div className="card card-primary p-5 gap-5">
      <div className="d-flex align-items-center justify-content-between">

        <div className="d-flex flex-column gap-2 w-75 justify-content-between">
          <label style={{ fontWeight: "600" }}>İş yeri</label>
          <div className="w-50">
            <Select
              options={companies}
              onChange={(opt) => setSelectCompany(opt)}
              placeholder="Select"
              value={selectCompany}
              styles={customStyles}
            />
          </div>
        </div>

        <div className="d-flex flex-column gap-2 w-75 justify-content-between">
          <label style={{ fontWeight: "600" }}>Tarix</label>
          <div className="w-50">
            <DatePicker
              id="datepicker"
              style={{ padding: "5px" }}
              wrapperClassName="datePicker"
              dateFormat="yyy-mm-dd"
              placeholder="-- -- --"
              className="fs-12"
              selected={selectDate}
              onChange={(date) => setSelectDate(date)}
              customInput={<CustomInput />}
              showYearDropdown
              ref={datePickerRef}
              yearDropdownItemNumber={50}
              calendarStartDay={1}
              scrollableYearDropdown

              shouldCloseOnSelect={true}
            />
          </div>
        </div>

      </div>
      <div className="tools d-flex gap-2 align-items-center">
        <button className="btn btn-primary" onClick={handleSearch} disabled={(selectCompany === null ? true : false)}>

          <KTIcon iconName="search-list" className="fs-2" />
          Axtar
        </button>
        <button className="btn btn-primary" onClick={onDownload}>
          <KTIcon iconName='exit-up' className='fs-2' />
          Excel
        </button>
      </div>
    </div>
  );
};

export default TabelHeader;
