import { useIntl } from "react-intl";

const UserInfoCell = ({ user }) => {
  const intl = useIntl();

  return (
    <div className='d-flex align-items-center '>
      <div className={`d-flex ${user == "Qırmızı" ? 'text-danger' : user == "Narıncı" ? 'text-warning' : user == "Yaşıl" ? "text-success" : ''}`}>
        {user}
      </div>
    </div>
  )
}

export { UserInfoCell }
