import { useIntl } from "react-intl";
const UserInfoCell = ({
  user,
}) => {
  const intl = useIntl();

  return (
    <div className="d-flex align-items-center">
      <div className="d-flex flex-column">
        {user ? user : intl.formatMessage({ id: "NOT.AVAIABLE" })}
      </div>
    </div>
  );
};

export { UserInfoCell };
