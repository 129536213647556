import clsx from 'clsx'
import { useIntl } from 'react-intl';

const ApproverCustomHeader = ({ className, title, tableProps }) => {
  const intl = useIntl();

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className
      )}
      style={{ cursor: 'pointer' }}
    >
      {title == "Actions" ? intl.formatMessage({ id: "ACTIONS" }) :
        title == "Adı" ? intl.formatMessage({ id: "NAMEE" }) :
          title == "Vakant vəzifə sayı" ? intl.formatMessage({ id: "NUMBER.OF.VACANCIES" }) :
            title == "Minimum yaş" ? intl.formatMessage({ id: "MINIMUM.AGE" }) :
              title == "Maksimum yaş" ? intl.formatMessage({ id: "MAXIMUM.AGE" }) :
                title == "İş yeri" ? intl.formatMessage({ id: "WORKPLACE" }) :
                  title == "Status" ? intl.formatMessage({ id: "STATUS" }) :
                    title}
    </th>
  )
}

export { ApproverCustomHeader }
