import { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import ReactToPrint from "react-to-print";
import { downloadExcel } from "react-export-table-to-excel";
import { KTIcon } from "../../../../../../_metronic/helpers";
import { useQueryResponse } from "../core/QueryResponseProvider";
import { CSVDownload } from "react-csv";
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import moment from "moment";
import { useIntl } from 'react-intl';

const UsersToolButtons = ({ printRef, setShow }) => {
  const intl = useIntl();

  const { filterUsers } = useQueryResponse();

  const [copy, setCopy] = useState({
    text: "",
    copied: false,
  });
  const [check, setCheck] = useState(false)

  useEffect(() => {
    setCopy((copy) => ({
      ...copy,
      text: [...filterUsers.map((fU) => {
        return [fU?.id, `${fU?.employee?.firstname} ${fU?.employee?.lastname} ${fU?.employee?.father_name}`, fU?.qualification?.label, fU?.institution?.name, fU?.faculty, fU?.profession, fU?.diplom_seria_num, moment(fU?.end_date).format('DD-MM-YYYY'), moment(fU?.diplom_issue_date).format('DD-MM-YYYY')]
      })].flat(Infinity).join("->"),
    }));
  }, [filterUsers]);


  const handleColumnVisibilityModal = () => {
    setShow(true);
  };

  const csvData = [
    ["ID", intl.formatMessage({ id: "NSF" }), intl.formatMessage({ id: 'EDUCATION' }), intl.formatMessage({ id: "EDUCATION.INSTITUTION" }),
      intl.formatMessage({ id: 'FACULTY' }), intl.formatMessage({ id: "SPECIALIZATION" }), intl.formatMessage({ id: "VOUCHER.NUMBER.SERIES" }),
      intl.formatMessage({ id: "YEAR.GRADUATED" }), intl.formatMessage({ id: "DATE.OF.ISSUEE" })
    ],
    ...filterUsers?.map(fU => {
      return [fU?.id, `${fU?.employee?.firstname} ${fU?.employee?.lastname} ${fU?.employee?.father_name}`, fU?.qualification?.label, fU?.institution?.name, fU?.faculty, fU?.profession, fU?.diplom_seria_num, moment(fU?.end_date).format('DD-MM-YYYY'), moment(fU?.diplom_issue_date).format('DD-MM-YYYY')]
    })
  ]
  const header = ["ID", intl.formatMessage({ id: "NSF" }), intl.formatMessage({ id: 'EDUCATION' }), intl.formatMessage({ id: "EDUCATION.INSTITUTION" }),
    intl.formatMessage({ id: 'FACULTY' }), intl.formatMessage({ id: "SPECIALIZATION" }), intl.formatMessage({ id: "VOUCHER.NUMBER.SERIES" }),
    intl.formatMessage({ id: "YEAR.GRADUATED" }), intl.formatMessage({ id: "DATE.OF.ISSUEE" })
  ];

  const body = [...filterUsers?.map(fU => {
    return [fU?.id, `${fU?.employee?.firstname} ${fU?.employee?.lastname} ${fU?.employee?.father_name}`, fU?.qualification?.label, fU?.institution?.name, fU?.faculty, fU?.profession, fU?.diplom_seria_num, moment(fU?.end_date).format('DD-MM-YYYY'), moment(fU?.diplom_issue_date).format('DD-MM-YYYY')]
  })];

  const handleExportExcel = () => {
    downloadExcel({
      fileName: "Education infos",
      sheet: "Education infos",
      tablePayload: {
        header,
        body
      }
    })
  }
  const handleExportPDFTable = () => {
    const doc = new jsPDF({
      format: 'a3'
    });

    autoTable(doc, {
      head: [["ID", intl.formatMessage({ id: "NSF" }), intl.formatMessage({ id: 'EDUCATION' }), intl.formatMessage({ id: "EDUCATION.INSTITUTION" }),
        intl.formatMessage({ id: 'FACULTY' }), intl.formatMessage({ id: "SPECIALIZATION" }), intl.formatMessage({ id: "VOUCHER.NUMBER.SERIES" }),
        intl.formatMessage({ id: "YEAR.GRADUATED" }), intl.formatMessage({ id: "DATE.OF.ISSUEE" })
      ]],
      body: [...filterUsers?.map(fU => {
        return [fU?.id, `${fU?.employee?.firstname} ${fU?.employee?.lastname} ${fU?.employee?.father_name}`, fU?.qualification?.label, fU?.institution?.name, fU?.faculty, fU?.profession, fU?.diplom_seria_num, moment(fU?.end_date).format('DD-MM-YYYY'), moment(fU?.diplom_issue_date).format('DD-MM-YYYY')]
      })]
    });

    doc.save('education-info.pdf')
  }

  return (
    <div className="d-flex flex-column justify-content-center align-items-center gap-2 mt-2">
      <button
        type="button"
        className="btn btn-light-primary mx-auto w-75"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"

      >
        <CopyToClipboard
          text={copy.text}
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          onCopy={() => {
            setCopy({ ...copy, copied: true });
            toast.success("Copied");
          }}
        >
          <span>{intl.formatMessage({ id: 'COPY' })}</span>
        </CopyToClipboard>
      </button>

      <ReactToPrint
        trigger={() => {
          return <button
            type="button"
            className="btn btn-light-primary mx-auto w-75"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
          >{intl.formatMessage({ id: 'PRINT' })}</button>;
        }}
        content={() => printRef.current}
      />

      <button
        type="button"
        className="btn btn-light-primary mx-auto w-75"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        onClick={handleExportExcel}
      >
        <KTIcon iconName="exit-up" className="fs-2" />
        {intl.formatMessage({ id: "EXCEL" })}
      </button>
      <button
        type="button"
        className="btn btn-light-primary mx-auto w-75"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        onClick={() => {
          setCheck(true);
          setTimeout(() => {
            setCheck(false);
          }, 1000)
        }}
      >
        <KTIcon iconName="exit-up" className="fs-2" />
        CSV
        {check && <CSVDownload data={csvData} filename={"educations.csv"} separator={";"} enclosingCharacter={``} />}
      </button>
      <button
        type="button"
        className="btn btn-light-primary mx-auto w-75"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        onClick={handleExportPDFTable}
      >
        <KTIcon iconName="exit-up" className="fs-2" />
        PDF
      </button>

      <button
        type="button"
        className="btn btn-light-primary mx-auto w-75"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        onClick={handleColumnVisibilityModal}
      >
        {intl.formatMessage({ id: "COLUMN.VISIBILITY" })}
      </button>
    </div>
  );
};

export default UsersToolButtons;
