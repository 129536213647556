import { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import ReactToPrint from "react-to-print";
import "react-toastify/dist/ReactToastify.css";
import { downloadExcel } from "react-export-table-to-excel";
import { KTIcon } from "../../../../../../_metronic/helpers";
import { useQueryResponse } from "../core/QueryResponseProvider";
import { CSVDownload } from "react-csv";
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import moment from "moment";
import { useIntl } from 'react-intl';

const UsersToolButtons = ({ printRef, setShow }) => {
  const intl = useIntl();
  const { filterUsers } = useQueryResponse();

  const [copy, setCopy] = useState({
    text: "",
    copied: false,
  });

  const [check, setCheck] = useState(false);

  useEffect(() => {
    setCopy((copy) => ({
      ...copy,
      text: [...filterUsers.map((fu) => {
        return [fu?.id, `${fu?.employee?.firstname} ${fu?.employee?.lastname} ${fu?.employee?.father_name}`, fu?.medical_app?.label, `${fu?.renew_interval} ay`, moment(fu?.last_renew_date).format('DD-MM-YYYY'), fu?.physical_deficiency?.label]
      })].flat(Infinity).join("->"),
    }));
  }, [filterUsers]);

  const handleColumnVisibilityModal = () => {
    setShow(true);
  };

  const csvData = [
    ["ID", intl.formatMessage({ id: "NSF" }), intl.formatMessage({ id: "MEDICAL.CERTIFICATE" }), intl.formatMessage({ id: 'REFRESH.RATE' }), intl.formatMessage({ id: 'LAST.UPDATE' }), intl.formatMessage({ id: 'PHYSICAL.DEFIENCY' })],
    ...filterUsers?.map(fu => {
      return [fu?.id, `${fu?.employee?.firstname} ${fu?.employee?.lastname} ${fu?.employee?.father_name}`, fu?.medical_app?.label, `${fu?.renew_interval} ay`, moment(fu?.last_renew_date).format('DD-MM-YYYY'), fu?.physical_deficiency?.label]
    })
  ];

  const header = ["ID", intl.formatMessage({ id: "NSF" }), intl.formatMessage({ id: "MEDICAL.CERTIFICATE" }), intl.formatMessage({ id: 'REFRESH.RATE' }), intl.formatMessage({ id: 'LAST.UPDATE' }), intl.formatMessage({ id: 'PHYSICAL.DEFIENCY' })];

  const body = [...filterUsers?.map(fu => {
    return [fu?.id, `${fu?.employee?.firstname} ${fu?.employee?.lastname} ${fu?.employee?.father_name}`, fu?.medical_app?.label, `${fu?.renew_interval} ay`, moment(fu?.last_renew_date).format('DD-MM-YYYY'), fu?.physical_deficiency?.label]
  })];

  const handleExportExcel = () => {
    downloadExcel({
      fileName: "Medical Informations",
      sheet: "Medical Information",
      tablePayload: {
        header,
        body
      }
    })
  }
  const handleExportPDFTable = () => {
    const doc = new jsPDF();

    autoTable(doc, {
      head: [["ID", intl.formatMessage({ id: "NSF" }), intl.formatMessage({ id: "MEDICAL.CERTIFICATE" }), intl.formatMessage({ id: 'REFRESH.RATE' }), intl.formatMessage({ id: 'LAST.UPDATE' }), intl.formatMessage({ id: 'PHYSICAL.DEFIENCY' })]],
      body: [...filterUsers?.map(fu => {
        return [fu?.id, `${fu?.employee?.firstname} ${fu?.employee?.lastname} ${fu?.employee?.father_name}`, fu?.medical_app?.label, `${fu?.renew_interval} ay`, moment(fu?.last_renew_date).format('DD-MM-YYYY'), fu?.physical_deficiency?.label]
      })]
    })

    doc.save('medical-infos.pdf');
  }

  return (
    <div className="d-flex flex-column justify-content-center align-items-center gap-2 mt-2">
       <button
        type='button'
        className='btn btn-light-primary mx-auto w-75'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <CopyToClipboard
          text={copy.text}
          style={{display:'flex', justifyContent:'center', width:'100%'}}
          onCopy={() => {
            setCopy({ ...copy, copied: true })
            toast.success('Copied')
          }}
        >
          <span>{intl.formatMessage({ id: "COPY" })}</span>
        </CopyToClipboard>
      </button>

      <button
        type="button"
        className="btn btn-light-primary mx-auto w-75"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
      >
        <ReactToPrint
          trigger={() => {
            return <span>{intl.formatMessage({ id: 'PRINT' })}</span>;
          }}
          content={() => printRef.current}
        />
      </button>

      <button
        type="button"
        className="btn btn-light-primary mx-auto w-75"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        onClick={handleExportExcel}
      >
        <KTIcon iconName="exit-up" className="fs-2" />
        {intl.formatMessage({ id: "EXCEL" })}
      </button>
      <button
        type="button"
        className="btn btn-light-primary mx-auto w-75"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        onClick={() => {
          setCheck(true);
          setTimeout(() => {
            setCheck(false);
          }, 1000)
        }}
      >
        <KTIcon iconName="exit-up" className="fs-2" />
        CSV
        {check && <CSVDownload data={csvData} separator={";"} enclosingCharacter={``} />}
      </button>
      <button
        type="button"
        className="btn btn-light-primary mx-auto w-75"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        onClick={handleExportPDFTable}

      >
        <KTIcon iconName="exit-up" className="fs-2" />
        PDF
      </button>

      <button
        type="button"
        className="btn btn-light-primary mx-auto w-75"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        onClick={handleColumnVisibilityModal}
      >
        {intl.formatMessage({ id: "COLUMN.VISIBILITY" })}
      </button>
    </div>
  );
};

export default UsersToolButtons;
