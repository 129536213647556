import { PageTitle } from "../../../../_metronic/layout/core";
import { VacanciesListWrapper } from "./vacancies-list/VacanciesList";
import { useIntl } from 'react-intl';

const breadcrumbs = [
    {
        title: "Vacancies",
        path: "/operations/batch-operations-management/vacancies",
        isSeparator: false,
        isActive: false,
    },
];

const VacanciesPage = () => {
    const intl = useIntl();

    return (

        <div>
            <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'MENU.RECRUIMENT.VACANCIES' })}</PageTitle>
            <VacanciesListWrapper />
        </div>
    );
};

export default VacanciesPage;
