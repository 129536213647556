import { useState,useRef } from "react";
import TabelContent from "./components/TabelContent";
import TabelHeader from "./components/TabelHeader";
import { PageTitle } from "../../../../_metronic/layout/core";

const TabelPage = () => {
  const printRef = useRef(null);
  const breadcrumbs = [
    {
      title: 'Tabel',
      path: '/workhours/tabel-management/tabel',
      isSeparator: false,
      isActive: false,
    }
  ]
  const [show, setShow] = useState(false);
  const [selectDate, setSelectDate] = useState(null);
  const [selectCompany, setSelectCompany] = useState(null);

  return (
    <div className="d-flex flex-column gap-2">
      <PageTitle breadcrumbs={breadcrumbs}>Tabel</PageTitle>
      <TabelHeader setShow={setShow} printRef={printRef} selectDate={selectDate} setSelectDate={setSelectDate} selectCompany={selectCompany} setSelectCompany={setSelectCompany} />
      {show && <TabelContent printRef={printRef} />}
    </div>
  );
};

export default TabelPage;
