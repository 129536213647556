import { useState, useEffect, forwardRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { initialUserMigrationInformation } from './components/core/_models'
import { createUserMigrationInformation, updateUserMigrationInformation } from "./components/core/_requests";
import clsx from "clsx";
import InputMask from 'react-input-mask';
import { isNotEmpty } from '../../../../_metronic/helpers';
import { UsersListLoading } from "./components/loading/UsersListLoading"
import { getUserMigrationInformationById } from './components/core/_requests';
import { useQueryResponse } from "./components/core/QueryResponseProvider";
import { useMutation, useQueryClient } from "react-query";
import moment from "moment";
import DatePicker from "react-datepicker";
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { useIntl } from 'react-intl';

const CreateAndUpdatePage = ({ setShowPage, setShowView, showView }) => {

    const intl = useIntl();

    const { id } = useParams();

    const baseUrl = process.env.REACT_APP_BASE_URL;

    const navigate = useNavigate();

    const user = useSelector(state => state.persistedReducer.user.user);

    const MaskedInput = forwardRef(({ value, onChange, onClick }, ref) => (
        <div className="ui input" onClick={onClick}>
            <InputMask ref={ref} mask="99/99/9999" placeholder="dd/mm/yyyy" value={value ? value : 'dd/mm/yyyy'} onChange={onChange} />
        </div>
    ));

    const { selectId, setSelectId } = useQueryResponse();

    const queryClient = useQueryClient()

    const editUserMigrationInformationSchema = Yup.object().shape({
        employee_id: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        trp_serial_number: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        trp_permit_reason: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

        trp_permit_date: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        trp_valid_date: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        trp_issuer: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

        prp_serial_number: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),


        prp_permit_date: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

        prp_valid_date: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        prp_issuer: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

        wp_serial_number: Yup.string().required(`${intl.formatMessage({ id: "REQUIRED" })}`),

        wp_permit_date: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),

        wp_valid_date: Yup.string().required(`${intl.formatMessage({ id: 'REQUIRED' })}`),
    });
    const [userMigrationInformationForEdit] = useState({
        employee_id: id ? id : initialUserMigrationInformation.employee_id,
        trp_serial_number: initialUserMigrationInformation.trp_serial_number,
        trp_permit_reason: initialUserMigrationInformation.trp_permit_reason,
        trp_permit_date: initialUserMigrationInformation.trp_permit_date,
        trp_valid_date: initialUserMigrationInformation.trp_valid_date,
        trp_issuer: initialUserMigrationInformation.trp_issuer,
        prp_serial_number: initialUserMigrationInformation.prp_serial_number,
        prp_permit_date: initialUserMigrationInformation.prp_permit_date,
        prp_valid_date: initialUserMigrationInformation.prp_valid_date,
        prp_issuer: initialUserMigrationInformation.prp_issuer,
        wp_serial_number: initialUserMigrationInformation.wp_serial_number,
        wp_permit_date: initialUserMigrationInformation.wp_permit_date,
        wp_valid_date: initialUserMigrationInformation.wp_valid_date,

    });

    const [employees, setEmployees] = useState([])
    const getUserMigrationInformation = useMutation(() => { }, {
        onSuccess: () => queryClient.invalidateQueries(['users-migration-information'])
    })
    const formik = useFormik({
        initialValues: userMigrationInformationForEdit,
        validationSchema: editUserMigrationInformationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            try {
                if (isNotEmpty(values.id)) {
                    const result = await updateUserMigrationInformation({
                        ...values, update_user_id: user?.id,
                        trp_permit_date: moment(values.trp_permit_date).format('YYYY-MM-DD'),
                        trp_valid_date: moment(values.trp_valid_date).format('YYYY-MM-DD'),
                        prp_permit_date: moment(values.prp_permit_date).format('YYYY-MM-DD'),
                        prp_valid_date: moment(values.prp_valid_date).format('YYYY-MM-DD'),
                        wp_permit_date: moment(values.wp_permit_date).format('YYYY-MM-DD'),
                        wp_valid_date: moment(values.wp_valid_date).format('YYYY-MM-DD'),
                    });

                    if (typeof result === "string") {
                        throw new Error(result);
                    } else {
                        toast.success(`${intl.formatMessage({ id: "SUCCESS.MESSAGE.UPDATE" })}`);
                        setShowPage(false);
                        getUserMigrationInformation.mutateAsync();
                    }
                } else {
                    const result = await createUserMigrationInformation({
                        ...values, update_user_id: user?.id, insert_user_id: user?.id, trp_permit_date: moment(values.trp_permit_date).format('YYYY-MM-DD'),
                        trp_valid_date: moment(values.trp_valid_date).format('YYYY-MM-DD'),
                        prp_permit_date: moment(values.prp_permit_date).format('YYYY-MM-DD'),
                        prp_valid_date: moment(values.prp_valid_date).format('YYYY-MM-DD'),
                        wp_permit_date: moment(values.wp_permit_date).format('YYYY-MM-DD'),
                        wp_valid_date: moment(values.wp_valid_date).format('YYYY-MM-DD'),
                    });
                    if (typeof result === "string") {
                        throw new Error(result)
                    } else {
                        toast.success(`${intl.formatMessage({ id: 'SUCCESS.MESSAGE.ADD' })}`);
                        setShowPage(false);
                        getUserMigrationInformation.mutateAsync();
                    }
                }
            } catch (ex) {
                toast.error(ex?.messsage)
            } finally {
                setSubmitting(true);
                setSelectId(null);
            }
        },
    });

    const getUserMigrationInformationInfo = async () => {
        const result = await getUserMigrationInformationById(selectId);
        formik.setValues({ ...result, employee_id: result?.employee?.id })
    }

    const getAllEmployees = async () => {
        try {
            const request = await axios.get(`${baseUrl}/api/employees`);

            if (request.status === 200) {
                setEmployees(request.data);
            } else {
                throw new Error(request.data);
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')
            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                });
            }
        }
    }

    const getEmployeeByIdMigrationInfos = async (id) => {
        try {
            const request = await axios.get(`${baseUrl}/api/employees/${id}/migration-infos`);

            if (request.status == 200) {
                const info = request.data?.find(fI => fI?.id == selectId);
                if (info) {

                    formik.setValues({ ...info, employee_id: info?.employee?.id })
                }
            } else {
                throw new Error(request?.data)
            }
        } catch (error) {
            if (error?.response?.status == 401) {
                localStorage.removeItem('token');
                navigate('/auth/login')
            } else {
                toast.error(error?.response?.data?.message, {
                    autoClose: 10000
                })
            }
        }
    }

    useEffect(() => {

        if (id) {
            getEmployeeByIdMigrationInfos(id)
        }
        else if (selectId) {
            getUserMigrationInformationInfo();
        }
        getAllEmployees();
    }, [selectId, id]);

    return (

        <div className="card mb-5 mb-xl-10">
            <div
                className="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_account_profile_details"
                aria-expanded="true"
                aria-controls="kt_account_profile_details"
            >
                <div className="card-title m-0">
                    <h3 className="fw-bold m-0">{showView ? intl.formatMessage({ id: "VIEW.MIGRATION" }) : selectId ? intl.formatMessage({ id: 'UPDATE.MIGRATION' }) : intl.formatMessage({ id: 'CREATE.MIGRATION' })}</h3>
                </div>
            </div>
            <div id="kt_account_settings_profile_details" className="collapse show">
                <form
                    id="kt_account_profile_details_form"
                    className="form fv-plugins-bootstrap5 fv-plugins-framework"
                    onSubmit={formik.handleSubmit}
                    noValidate
                >
                    <div className="card-body border-top p-9">
                        {id ? null : <div className="row mb-6">
                            <label className={`col-lg-4 col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                {intl.formatMessage({ id: "EMPLOYEE" })}
                            </label>
                            <div className="col-lg-8 fv-row fv-plugins-icon-container">
                                {showView ? <p>{formik.values?.employee?.firstname + " " + formik.values?.employee?.lastname}</p> : <select
                                    {...formik.getFieldProps("employee_id")}
                                    className={clsx(
                                        "form-select form-select-solid mb-3 mb-lg-0",
                                        {
                                            "is-invalid":
                                                formik.touched.employee_id && formik.errors.employee_id,
                                        },
                                        {
                                            "is-valid":
                                                formik.touched.employee_id && !formik.errors.employee_id,
                                        }
                                    )}
                                    data-kt-select2="true"
                                    data-placeholder="Select "
                                    data-allow-clear="true"
                                    disabled={formik.isSubmitting}
                                >
                                    <option value="">{intl.formatMessage({ id: "SELECT" })}</option>
                                    {employees.length > 0 && employees.map(employee => (
                                        <option key={employee?.id} value={employee?.id}>{employee?.firstname + " " + employee?.lastname}</option>
                                    ))}
                                </select>}

                                {formik.touched.employee_id && formik.errors.employee_id && (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            <span role="alert">{formik.errors.employee_id}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>}

                        <h3 className="text-primary mb-4">{intl.formatMessage({ id: "TEMPORARY.RESIDENCE.CARD" })}</h3>
                        <div className="d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch">

                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: "VOUCHER.NUMBER.SERIESS" })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.trp_serial_number}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'VOUCHER.NUMBER.SERIESS' })}
                                        {...formik.getFieldProps("trp_serial_number")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.trp_serial_number && formik.errors.trp_serial_number,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.trp_serial_number && !formik.errors.trp_serial_number,
                                            }
                                        )}
                                    />}
                                    {formik.touched.trp_serial_number && formik.errors.trp_serial_number && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.trp_serial_number}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>



                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'BASIS.OF.DELIVERY' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.trp_permit_reason}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'BASIS.OF.DELIVERY' })}
                                        {...formik.getFieldProps("trp_permit_reason")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.trp_permit_reason && formik.errors.trp_permit_reason,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.trp_permit_reason && !formik.errors.trp_permit_reason,
                                            }
                                        )}
                                    />}
                                    {formik.touched.trp_permit_reason && formik.errors.trp_permit_reason && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.trp_permit_reason}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>


                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'DATE.OF.ISSUE' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{moment(formik.values.trp_permit_date).format('DD-MM-YYYY')}</p> : <div className="w-100">
                                        <DatePicker
                                            wrapperClassName="datePicker"
                                            dateFormat="dd/MM/yyyy"
                                            placeholder="-- -- --"
                                            customInput={<MaskedInput />}
                                            name="trp_permit_date"
                                            selected={formik.values.trp_permit_date && new Date(formik.values.trp_permit_date)}
                                            onChange={(opt) => formik.setFieldValue("trp_permit_date", opt)}
                                            showDayDropdown={true}
                                            showMonthDropdown={true}
                                            showYearDropdown={true}
                                            shouldCloseOnSelect={true}
                                            calendarStartDay={1}
                                            scrollableYearDropdown={false}
                                        />
                                    </div>}

                                    {formik.touched.trp_permit_date && formik.errors.trp_permit_date && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.trp_permit_date}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'PERIOD.VALIDITY' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">

                                    {showView ? <p>{moment(formik.values.trp_valid_date).format('DD-MM-YYYY')}</p> : <div className="w-100"> <DatePicker
                                        wrapperClassName="datePicker"
                                        dateFormat="dd/MM/yyyy"
                                        placeholder="-- -- --"
                                        customInput={<MaskedInput />}
                                        name="trp_valid_date"
                                        selected={formik.values.trp_valid_date && new Date(formik.values.trp_valid_date)}
                                        onChange={(opt) => formik.setFieldValue("trp_valid_date", opt)}
                                        showYearDropdown
                                        yearDropdownItemNumber={50}
                                        calendarStartDay={1}
                                        scrollableYearDropdown
                                        shouldCloseOnSelect={true}
                                    />
                                    </div>}

                                    {formik.touched.trp_valid_date && formik.errors.trp_valid_date && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.trp_valid_date}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>


                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'NAME.OF.ISSUING.AUTHORITY' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.trp_issuer}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'NAME.OF.ISSUING.AUTHORITY' })}
                                        {...formik.getFieldProps("trp_issuer")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.trp_issuer && formik.errors.trp_issuer,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.trp_issuer && !formik.errors.trp_issuer,
                                            }
                                        )}
                                    />}
                                    {formik.touched.trp_issuer && formik.errors.trp_issuer && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.trp_issuer}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>
                        <h3 className="text-primary mb-4">{intl.formatMessage({ id: 'PERMANENT.RESIDENCEE.PERMIT' })}</h3>

                        <div className="d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch">
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'VOUCHER.NUMBER.SERIESS' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.prp_serial_number}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'VOUCHER.NUMBER.SERIESS' })}
                                        {...formik.getFieldProps("prp_serial_number")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.prp_serial_number && formik.errors.prp_serial_number,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.prp_serial_number && !formik.errors.prp_serial_number,
                                            }
                                        )}
                                    />}
                                    {formik.touched.prp_serial_number && formik.errors.prp_serial_number && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.prp_serial_number}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>



                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: "DATE.OF.ISSUE" })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{moment(formik.values.prp_permit_date).format('DD-MM-YYYY')}</p> : <div className="w-100">
                                        <DatePicker
                                            wrapperClassName="datePicker"
                                            dateFormat="dd/MM/yyyy"
                                            placeholder="-- -- --"
                                            customInput={<MaskedInput />}
                                            name="prp_permit_date"
                                            selected={formik.values.prp_permit_date && new Date(formik.values.prp_permit_date)}
                                            onChange={(opt) => formik.setFieldValue("prp_permit_date", opt)}
                                            showDayDropdown={true}
                                            showMonthDropdown={true}
                                            showYearDropdown={true}
                                            shouldCloseOnSelect={true}
                                            calendarStartDay={1}
                                            scrollableYearDropdown={false}
                                        />
                                    </div>}

                                    {formik.touched.prp_permit_date && formik.errors.prp_permit_date && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.prp_permit_date}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>


                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'PERIOD.VALIDITY' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{moment(formik.values.prp_valid_date).format('DD-MM-YYYY')}</p> : <div className="w-100">
                                        <DatePicker
                                            wrapperClassName="datePicker"
                                            dateFormat="dd/MM/yyyy"
                                            placeholder="-- -- --"
                                            customInput={<MaskedInput />}
                                            name="prp_valid_date"
                                            selected={formik.values.prp_valid_date && new Date(formik.values.prp_valid_date)}
                                            onChange={(opt) => formik.setFieldValue("prp_valid_date", opt)}
                                            showDayDropdown={true}
                                            showMonthDropdown={true}
                                            showYearDropdown={true}
                                            shouldCloseOnSelect={true}
                                            calendarStartDay={1}
                                            scrollableYearDropdown={false}
                                        />
                                    </div>}

                                    {formik.touched.prp_valid_date && formik.errors.prp_valid_date && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.prp_valid_date}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>


                            <div className="d-flex flex-column gap-2 mb-6 col-lg-2 col-12 col-md-2">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'NAME.OF.ISSUING.AUTHORITY' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.prp_issuer}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: 'NAME.OF.ISSUING.AUTHORITY' })}
                                        {...formik.getFieldProps("prp_issuer")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.prp_issuer && formik.errors.prp_issuer,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.prp_issuer && !formik.errors.prp_issuer,
                                            }
                                        )}
                                    />}
                                    {formik.touched.prp_issuer && formik.errors.prp_issuer && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.prp_issuer}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <h3 className="text-primary mb-5">{intl.formatMessage({ id: 'WORK.PERMIT.OF.ENGAGE' })}</h3>


                        <div className="d-flex justify-space-between flex-wrap justify-content-between gap-2 align-items-stretch">
                            <div className="d-flex flex-column gap-2 mb-6 col-lg-3 col-12 col-md-5">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'VOUCHER.NUMBER.SERIESS' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{formik.values?.wp_serial_number}</p> : <input
                                        type="text"
                                        placeholder={intl.formatMessage({ id: "VOUCHER.NUMBER.SERIESS" })}
                                        {...formik.getFieldProps("wp_serial_number")}
                                        className={clsx(
                                            "form-control form-control-solid mb-3 mb-lg-0",
                                            {
                                                "is-invalid":
                                                    formik.touched.wp_serial_number && formik.errors.wp_serial_number,
                                            },
                                            {
                                                "is-valid":
                                                    formik.touched.wp_serial_number && !formik.errors.wp_serial_number,
                                            }
                                        )}
                                    />}
                                    {formik.touched.wp_serial_number && formik.errors.wp_serial_number && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.wp_serial_number}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>



                            <div className="d-flex flex-column gap-2 mb-6 col-lg-3 col-12 col-md-5">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: "DATE.OF.ISSUE" })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{moment(formik.values.wp_permit_date).format('DD-MM-YYYY')}</p> : <div className="w-100">
                                        <DatePicker
                                            wrapperClassName="datePicker"
                                            dateFormat="dd/MM/yyyy"
                                            placeholder="-- -- --"
                                            customInput={<MaskedInput />}
                                            name="wp_permit_date"
                                            selected={formik.values.wp_permit_date && new Date(formik.values.wp_permit_date)}
                                            onChange={(opt) => formik.setFieldValue("wp_permit_date", opt)}
                                            showDayDropdown={true}
                                            showMonthDropdown={true}
                                            showYearDropdown={true}
                                            shouldCloseOnSelect={true}
                                            calendarStartDay={1}
                                            scrollableYearDropdown={false}
                                        />
                                    </div>}

                                    {formik.touched.wp_permit_date && formik.errors.wp_permit_date && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.wp_permit_date}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>




                            <div className="d-flex flex-column gap-2 mb-6 col-lg-3 col-12 col-md-5">
                                <label className={`col-form-label fw-semibold fs-6 ${showView == false && 'required'}`}>
                                    {intl.formatMessage({ id: 'PERIOD.VALIDITY' })}
                                </label>
                                <div className="fv-row fv-plugins-icon-container">
                                    {showView ? <p>{moment(formik.values.wp_valid_date).format('DD-MM-YYYY')}</p> : <div className="w-100">
                                        <DatePicker
                                            wrapperClassName="datePicker"
                                            dateFormat="dd/MM/yyyy"
                                            placeholder="-- -- --"
                                            customInput={<MaskedInput />}
                                            name="wp_valid_date"
                                            selected={formik.values.wp_valid_date && new Date(formik.values.wp_valid_date)}
                                            onChange={(opt) => formik.setFieldValue("wp_valid_date", opt)}
                                            showDayDropdown={true}
                                            showMonthDropdown={true}
                                            showYearDropdown={true}
                                            shouldCloseOnSelect={true}
                                            calendarStartDay={1}
                                            scrollableYearDropdown={false}
                                        />
                                    </div>}

                                    {formik.touched.wp_valid_date && formik.errors.wp_valid_date && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.wp_valid_date}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button
                            type="reset"
                            onClick={() => {
                                setShowPage(false)
                                setSelectId(null);
                                setShowView(false)
                            }}
                            disabled={formik.isSubmitting}
                            className="btn btn-light btn-active-light-primary me-2"
                        >
                            {intl.formatMessage({ id: 'DISCARD' })}
                        </button>
                        {showView == false && <button
                            type="submit"
                            disabled={
                                formik.isSubmitting ||
                                !formik.isValid ||
                                !formik.touched
                            }
                            className="btn btn-primary"
                            id="kt_account_profile_details_submit"
                        >
                            <span className="indicator-label">{formik.isSubmitting ? intl.formatMessage({ id: "PLEASE.WAIT" }) : intl.formatMessage({ id: 'AUTH.GENERAL.SUBMIT_BUTTON' })}</span>
                        </button>}
                    </div>
                </form>
                {(formik.isSubmitting) && <UsersListLoading />}
            </div>
        </div>

    )
}

export default CreateAndUpdatePage

